import React from "react";

import { i18n } from '@hooks';
import { Languages } from '@types';

interface ContextProps {
    changeLang: (language: Languages) => void
}

interface TranslateProps {
    children: React.ReactNode;
}

const TranslateContext = React.createContext<ContextProps>({} as ContextProps);

export const TranslateProvider = ({ children }: TranslateProps) => {

    const [lang, setLang] = React.useState(() => {

        const item = window.localStorage.getItem('i18nextLng');

        if (item !== null) {
            return item;
        }

    });

    const changeLang = (language: Languages) => {

        i18n.changeLanguage(Languages[language]);
        setLang(Languages[language])
    }

    console.log("__ITEM: ", lang);

    return (
        <TranslateContext.Provider value={{ changeLang }}>
            {children}
        </TranslateContext.Provider>
    )
}

export const useTranslate = () => {
    const context = React.useContext(TranslateContext);

    return context;
}