import { styled } from "@mui/material";

export const Main = styled('div')(({ theme }) => ``);

export const Section = styled('div')(({ theme }) => `

    &.point2 {
        margin-top: 2.5rem; 
        position: relative;

            &::before {
                display: block;
                content: '';
                position: absolute;
                z-index: -1;

                width: 811px;
                height: 390px;
                left: -34rem;
                top: 0rem;
                bottom: 0;
                margin: auto;
                
                background: linear-gradient(180deg, rgba(134, 40, 226, 0.8) -13.16%, rgba(134, 40, 226, 0.8) 86.84%);
                filter: blur(500px);
                transform: matrix(0.85, 0.53, 0.52, -0.85, 0, 0);
                opacity: 0.3;
                
                ${[theme.breakpoints.down("sm")]} {
                    width: 611px;
                    height: 190px;
                    top: 0;
                    left: -27rem;
                }
            
        }
        
    }
`);

export const GridWrapperCols = styled('div')(({ theme }) => `
    padding-top: 60px;
    
    ${[theme.breakpoints.down("sm")]} {
        padding-top: 40px;
    }
`);

export const ImageOverlay = styled('div')(({ theme }) => `
    display: flex;
    align-items: center;
    justify-content: center;    
    width: 100%;
    & > img {
        max-width: 210px;
        
        ${[theme.breakpoints.down("md")]} {
            max-width: 164px;
        }
        
        ${[theme.breakpoints.down("sm")]} {
            max-width: 114px;
        }
    }
`);

export const ImageManagement = styled('div')(({ theme }) => `

`);

export const Image = styled('img')(({ theme }) => `
    width: 100%;
`);

export const GetInTouch = styled('div')(({ theme }) => `
    margin: 0 auto;
    
    ${[theme.breakpoints.down("sm")]} {
        padding: 15px 0;
    }
`);

export const GetInTouchBox = styled('div')(({ theme }) => `
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
    padding: 2.5rem;
    color: ${theme.palette.common.white};
    background: #000;
    background-clip: padding-box; /* !importanté */
    border: solid .125rem transparent; /* !importanté */
    border-radius: 2.375rem;
    text-align: center;
    font-weight: ${theme.typography.fontWeightRegular};
    font-size: 32px;
    padding: 55px 30px 55px 30px;

    ${[theme.breakpoints.down("md")]} {
        padding: 36px 15px 36px 15px;
        border-radius: .9375rem;
        font-size: 28px;
    }
    
    ${[theme.breakpoints.down("sm")]} {
        padding: 20px 15px 20px 15px;
        border: solid .0625rem transparent; /* !importanté */
        font-size: 12px;
        border-radius: .5625rem;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        z-index: -1;
        margin: -.125rem; /* !importanté */
        border-radius: inherit; /* !importanté */
        background: linear-gradient(89.67deg, #A74CFF -5.83%, #BD00FF 51.64%, #9188D1 111.55%); 
        
        ${[theme.breakpoints.down("sm")]} {
            margin: -.0625rem; /* !importanté */
        }
    }
`);

export const WrapperButton = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginTop: theme.typography.pxToRem(80),
    [theme.breakpoints.down("md")]: {
        marginTop: theme.typography.pxToRem(40),
    },
    [theme.breakpoints.down("sm")]: {
        marginTop: theme.typography.pxToRem(15),
    }
}));