import React from 'react';
import { ReactSVG } from 'react-svg';
import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import {
    Main,
    Section,
    OurStory,
    Overlay,
    OurStoryWrapper,
    TextBorder,
    WrapperTypography,
    PointValue,
    Dot,
    OurValues,
    OurValuesColumn,
    List,
    ListItem,
    Icon,
    GetInTouch,
    GetInTouchBox,
    WrapperButton,
    Customers,
    CustomersImage,
} from './styles';

import { Experience, Solutions, Teams, Company, Alliances } from '@icons';

import OutStoryBottom from '@img/our-story-bottom.svg';
import { Button, Headline } from '@components/design-system';

import CustomersLogos from '@img/customers.png';

import { tawkToChat } from '@utils';

import { i18n } from '@hooks';

const About: React.FC = () => {

    const theme = useTheme();
    const matches = useMediaQuery('(min-width: 600px)');

    const { t } = i18n;

    const listTexts = [
        {
            id: 'e3751ffc',
            title: t('About.Point7.Block1.Title') as string,
            highlight: t('About.Point7.Block1.Tag') as string,
        },
        {
            id: '8969545c',
            title: t('About.Point7.Block2.Title') as string,
            highlight: t('About.Point7.Block2.Tag') as string,
        },
        {
            id: '2f017548',
            title: t('About.Point7.Block3.Title') as string,
            highlight: t('About.Point7.Block3.Tag') as string,
        },
        {
            id: 'f26d1971',
            title: t('About.Point7.Block4.Title') as string,
            highlight: t('About.Point7.Block4.Tag') as string,
        },
        {
            id: 'b67e60cf',
            title: t('About.Point7.Block5.Title') as string,
            highlight: t('About.Point7.Block5.Tag') as string,
        },
        {
            id: '9e10c6c8',
            title: t('About.Point7.Block6.Title') as string,
            highlight: t('About.Point7.Block6.Tag') as string,
        },
    ];

    const list = [
        {
            id: '7f2014e8',
            title: t('About.Point5.Block1.Title') as string,
            icon: Experience
        },
        {
            id: 'e995daa6',
            title: t('About.Point5.Block2.Title') as string,
            icon: Solutions
        },
        {
            id: '57df75ae',
            title: t('About.Point5.Block3.Title') as string,
            icon: Teams
        },
        {
            id: '98876fb7',
            title: t('About.Point5.Block4.Title') as string,
            icon: Company
        },
        {
            id: '28baecbf',
            title: t('About.Point5.Block5.Title') as string,
            icon: Alliances
        },
    ]

    return (
        <Main>
            <Section className='point1'>
                <Container maxWidth='lg'>
                    <OurStoryWrapper>
                        <OurStory>
                            <Typography
                                variant="body1"
                                component="h1"
                                align='left'
                                sx={{
                                    fontSize: '32px',
                                    color: theme.palette.common.black,
                                    fontWeight: theme.typography.fontWeightBold,
                                    marginBottom: theme.typography.pxToRem(10),
                                    [theme.breakpoints.down("md")]: {
                                        fontSize: '26px',
                                        marginBottom: theme.typography.pxToRem(8),
                                    },
                                    [theme.breakpoints.down("sm")]: {
                                        fontSize: '16px',
                                        marginBottom: theme.typography.pxToRem(5),
                                    }
                                }}
                            >
                                {t('About.Point1.Headline') as string}
                            </Typography>

                            <Typography
                                variant="body1"
                                component="div"
                                align='left'
                                gutterBottom
                                sx={{
                                    fontSize: '28px',
                                    fontWeight: theme.typography.fontWeightLight,
                                    color: theme.palette.common.black,
                                    [theme.breakpoints.down("md")]: {
                                        fontSize: '16px',
                                    },
                                    [theme.breakpoints.down("sm")]: {
                                        fontSize: '12px',
                                    }
                                }}
                            >
                                {t('About.Point1.HistoryText') as string}
                            </Typography>
                        </OurStory>
                        <Overlay>
                            <img src={OutStoryBottom} alt="overlay footer" />
                        </Overlay>
                    </OurStoryWrapper>
                </Container>
            </Section>

            <Section className='point8'>
                <Container maxWidth="lg">
                    <Headline
                        size={matches ? 'medium' : 'small'}
                        fontWeight='regular'
                        textColor={theme.palette.common.white}
                        textAlign='center'
                    >
                        {t('About.Headline') as string}
                    </Headline>
                </Container>

                <Customers className='infinity-carousel'>
                    <CustomersImage
                        className='infinity-carousel--image'
                        style={{ background: `url(${CustomersLogos})` }}
                    />
                </Customers>

            </Section>

            <Section className='point2'>
                <Container maxWidth='lg'>

                    <TextBorder className='mission'>
                        {t('About.Point2.Title') as string}
                    </TextBorder>
                    <Typography
                        variant="body1"
                        component="div"
                        align='left'
                        gutterBottom
                        sx={{
                            fontSize: '28px',
                            fontWeight: theme.typography.fontWeightLight,
                            color: theme.palette.common.white,
                            textAlign: 'left',
                            marginTop: theme.typography.pxToRem(-122),
                            paddingRight: theme.typography.pxToRem(160),
                            position: 'relative',
                            zIndex: 1,
                            [theme.breakpoints.down("md")]: {
                                fontSize: '20px',
                                paddingRight: theme.typography.pxToRem(46),
                                marginTop: theme.typography.pxToRem(-54),
                            },
                            [theme.breakpoints.down("sm")]: {
                                paddingRight: theme.typography.pxToRem(46),
                                marginTop: theme.typography.pxToRem(-32),
                                fontSize: '.75rem',
                            }
                        }}
                    >
                        {t('About.Point2.Text') as string}
                    </Typography>
                </Container>

            </Section>

            <Section className='point3'>
                <Container maxWidth='lg'>

                    <WrapperTypography>
                        <TextBorder className='vision'>
                            {t('About.Point3.Title') as string}
                        </TextBorder>
                        <Typography
                            variant="body1"
                            component="div"
                            align='left'
                            gutterBottom
                            sx={{
                                fontSize: '28px',
                                fontWeight: theme.typography.fontWeightLight,
                                color: theme.palette.common.white,
                                textAlign: 'left',
                                marginTop: theme.typography.pxToRem(-122),
                                // paddingRight: theme.typography.pxToRem(160),
                                position: 'relative',
                                zIndex: 1,
                                [theme.breakpoints.down("md")]: {
                                    fontSize: '20px',
                                    // paddingRight: theme.typography.pxToRem(46),
                                    marginTop: theme.typography.pxToRem(-54),
                                },
                                [theme.breakpoints.down("sm")]: {
                                    // paddingRight: theme.typography.pxToRem(46),
                                    marginTop: theme.typography.pxToRem(-32),
                                    fontSize: '.75rem',
                                }
                            }}
                        >
                            {t('About.Point3.Text') as string}
                        </Typography>
                    </WrapperTypography>
                </Container>
            </Section>

            <Section className='point4'>
                <Container maxWidth='lg'>

                    <TextBorder className='values'>
                        {t('About.Point4.Title') as string}
                    </TextBorder>

                    <Typography
                        variant="body1"
                        component="div"
                        align='left'
                        gutterBottom
                        sx={{
                            fontSize: '28px',
                            fontWeight: theme.typography.fontWeightLight,
                            color: theme.palette.common.white,
                            textAlign: 'left',
                            marginTop: theme.typography.pxToRem(-122),
                            // paddingRight: theme.typography.pxToRem(160),
                            position: 'relative',
                            zIndex: 1,
                            [theme.breakpoints.down("md")]: {
                                fontSize: '20px',
                                // paddingRight: theme.typography.pxToRem(46),
                                marginTop: theme.typography.pxToRem(-54),
                            },
                            [theme.breakpoints.down("sm")]: {
                                // paddingRight: theme.typography.pxToRem(46),
                                marginTop: theme.typography.pxToRem(-32),
                                fontSize: '.75rem',
                            }
                        }}
                    >
                        {t('About.Point4.Text') as string}
                    </Typography>

                    <OurValues>
                        <OurValuesColumn>
                            <PointValue className='value'>
                                <Dot />
                                {t('About.Point4.Block1.Title') as string}
                            </PointValue>

                            <PointValue className='value'>
                                <Dot />
                                {t('About.Point4.Block2.Title') as string}
                            </PointValue>

                        </OurValuesColumn>
                        <OurValuesColumn>

                            <PointValue className='value'>
                                <Dot />
                                {t('About.Point4.Block3.Title') as string}
                            </PointValue>

                            <PointValue className='value'>
                                <Dot />
                                {t('About.Point4.Block4.Title') as string}
                            </PointValue>

                        </OurValuesColumn>
                    </OurValues>
                </Container>
            </Section>

            <Section className='point5'>
                <Container maxWidth='lg'>

                    <List>
                        {list.map(({ id, title, icon }) => (
                            <ListItem key={`${id}`}>
                                <Icon><ReactSVG src={icon} className='svg' /></Icon>
                                <Typography
                                    variant="body1"
                                    component="h1"
                                    align='left'
                                    sx={{
                                        fontSize: '22px',
                                        color: theme.palette.common.white,
                                        fontWeight: theme.typography.fontWeightLight,
                                        [theme.breakpoints.down("md")]: {
                                            fontSize: '18px',
                                        },
                                        [theme.breakpoints.down("sm")]: {
                                            fontSize: '10px',
                                        }
                                    }}
                                >
                                    {title}
                                </Typography>
                            </ListItem>

                        ))}

                    </List>

                </Container>

            </Section>

            <Section className='point6'>
                <Container maxWidth='lg'>

                    <GetInTouch>
                        <GetInTouchBox>

                            {t('About.Point6.Title') as string}
                            <Typography
                                variant="body1"
                                component="h1"
                                align='left'
                                sx={{
                                    fontSize: '32px',
                                    color: theme.palette.common.white,
                                    fontWeight: theme.typography.fontWeightLight,
                                    textAlign: 'center',
                                    paddingTop: theme.typography.pxToRem(40),
                                    [theme.breakpoints.down("md")]: {
                                        paddingTop: theme.typography.pxToRem(30),
                                        fontSize: '28px',
                                    },
                                    [theme.breakpoints.down("sm")]: {
                                        paddingTop: theme.typography.pxToRem(15),
                                        fontSize: '12px',
                                    }
                                }}
                            >
                                {t('About.Point6.Text') as string}
                            </Typography>


                        </GetInTouchBox>
                    </GetInTouch>
                </Container>
            </Section>

            <Section className="point7">
                <Container maxWidth='lg'>

                    <Grid container spacing={2} alignItems={'stretch'}>


                        <Grid item xs={12} sm={5} md={5} lg={5}>

                            <Typography
                                variant="body1"
                                component="h1"
                                align='left'
                                sx={{
                                    fontSize: '40px',
                                    color: theme.palette.common.white,
                                    fontWeight: theme.typography.fontWeightBold,
                                    marginBottom: theme.typography.pxToRem(54),
                                    [theme.breakpoints.down("md")]: {
                                        fontSize: '30px',
                                        marginBottom: theme.typography.pxToRem(20),
                                    },
                                    [theme.breakpoints.down("sm")]: {
                                        fontSize: '20px',
                                        marginBottom: theme.typography.pxToRem(16),
                                    }
                                }}
                            >
                                {t('About.Point7.Title') as string}
                            </Typography>

                            <Typography
                                variant="body1"
                                component="div"
                                align='left'
                                gutterBottom
                                sx={{
                                    fontSize: '28px',
                                    fontWeight: theme.typography.fontWeightLight,
                                    color: theme.palette.common.white,
                                    marginBottom: theme.typography.pxToRem(76),
                                    [theme.breakpoints.down("md")]: {
                                        fontSize: '16px',
                                        marginBottom: theme.typography.pxToRem(40),
                                    },
                                    [theme.breakpoints.down("sm")]: {
                                        fontSize: '14px',
                                        marginBottom: theme.typography.pxToRem(30),
                                    }
                                }}
                            >
                                {t('About.Point7.Text') as string}
                            </Typography>

                            <List className='list-texts left'>
                                {listTexts.map(({ id, title, highlight }) => (
                                    <ListItem key={`${id}`}>
                                        <Typography
                                            variant="body1"
                                            component="h1"
                                            align='left'
                                            sx={{
                                                fontSize: '22px',
                                                color: theme.palette.common.white,
                                                fontWeight: theme.typography.fontWeightLight,
                                                '& > span': {
                                                    backgroundColor: '#3CFF95',
                                                    display: 'inline-block',
                                                    color: theme.palette.common.black,
                                                    fontWeight: theme.typography.fontWeightBold,
                                                    padding: '0 4px'
                                                },
                                                [theme.breakpoints.down("md")]: {
                                                    fontSize: '16px',
                                                },
                                                [theme.breakpoints.down("sm")]: {
                                                    fontSize: '10px',
                                                }
                                            }}
                                        >
                                            <span>{highlight}</span> - {title}
                                        </Typography>
                                    </ListItem>
                                ))}
                            </List>

                            <WrapperButton>
                                <Button
                                    backgroundColor='#000'
                                    color='white'
                                    buttonBorderColor='linear-gradient(89.67deg, #3CFF95 -5.83%, #63FF1B 51.64%, #3CFF95 111.55%)'
                                    buttonBorderHoverColor='linear-gradient(89.67deg, #3CFF95 -5.83%, #63FF1B 51.64%, #3CFF95 111.55%)'
                                    buttonSize='small'
                                    onClick={tawkToChat}
                                >
                                    {t('About.Point7.ButtonText') as string}
                                </Button>
                            </WrapperButton>
                        </Grid>

                        <Grid item xs={12} sm={7} md={7} lg={7}>
                            <List className='list-texts right'>
                                {listTexts.map(({ id, title, highlight }) => (
                                    <ListItem key={`${id}`}>
                                        <Typography
                                            variant="body1"
                                            component="h1"
                                            align='left'
                                            sx={{
                                                fontSize: '18px',
                                                color: theme.palette.common.white,
                                                fontWeight: theme.typography.fontWeightLight,
                                                '& > span': {
                                                    backgroundColor: '#3CFF95',
                                                    display: 'inline-block',
                                                    color: theme.palette.common.black,
                                                    fontWeight: theme.typography.fontWeightBold,
                                                    padding: '0 4px'
                                                },
                                                [theme.breakpoints.down("md")]: {
                                                    fontSize: '14px',
                                                },
                                                [theme.breakpoints.down("sm")]: {
                                                    fontSize: '10px',
                                                }
                                            }}
                                        >
                                            <span>{highlight}</span> - {title}
                                        </Typography>
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>

                    </Grid>

                </Container>
            </Section>

        </Main>
    )
}

export { About };