import React from 'react';

import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';

import { Button } from '@components/design-system';

import {
    Main,
    Image,
    WrapperButton,
    WrapperImage,
    Section,
} from './styles';

import BussinesAnalytics from '@img/business-analytics-medium.png';

import { tawkToChat } from '@utils';
import { i18n } from '@hooks';

const HeroOnDemand: React.FC = () => {

    const theme = useTheme();
    const mobile = useMediaQuery('(min-width: 600px)');
    const { t } = i18n;

    return (
        <Main className='on-demand'>
            <Container maxWidth="lg" className='container'>

                <Section className="point1">
                    <Grid container spacing={2} alignItems={'center'} flexDirection={!mobile ? 'column-reverse' : 'row'}>
                        <Grid item xs={12} sm={5} md={5} lg={5}>

                            <Typography
                                variant="body1"
                                component="h1"
                                align={!mobile ? 'center' : 'left'}
                                sx={{
                                    fontSize: '40px',
                                    color: theme.palette.common.white,
                                    fontWeight: theme.typography.fontWeightBold,
                                    marginBottom: theme.typography.pxToRem(64),
                                    [theme.breakpoints.down("md")]: {
                                        fontSize: '30px',
                                        marginBottom: theme.typography.pxToRem(30),
                                    },
                                    [theme.breakpoints.down("sm")]: {
                                        fontSize: '20px',
                                        marginBottom: theme.typography.pxToRem(22),
                                    }
                                }}
                            >
                                {t('OnDemand.Point1.Headline') as string}
                            </Typography>

                            <Typography
                                variant="body1"
                                component="div"
                                align={!mobile ? 'center' : 'left'}
                                gutterBottom
                                sx={{
                                    fontSize: '18px',
                                    fontWeight: theme.typography.fontWeightLight,
                                    color: theme.palette.common.white,
                                    marginBottom: theme.typography.pxToRem(76),
                                    [theme.breakpoints.down("md")]: {
                                        fontSize: '14px',
                                        marginBottom: theme.typography.pxToRem(30),
                                    },
                                    [theme.breakpoints.down("sm")]: {
                                        fontSize: '12px',
                                        marginBottom: theme.typography.pxToRem(30),
                                    }
                                }}
                            >
                                {t('OnDemand.Point1.Text_1') as string}
                                <br />
                                <br />
                                {t('OnDemand.Point1.Text_2') as string}
                                <br />
                                <br />
                                {t('OnDemand.Point1.Text_3') as string}
                            </Typography>

                            <WrapperButton>
                                <Button
                                    backgroundColor='#000'
                                    color='white'
                                    buttonBorderColor='linear-gradient(89.67deg, #03C3FF -5.83%, #BD00FF 51.64%, #E10000 111.55%)'
                                    buttonBorderHoverColor='linear-gradient(89.67deg, #03C3FF -5.83%, #BD00FF 51.64%, #E10000 111.55%)'
                                    buttonSize='small'
                                    onClick={tawkToChat}
                                >
                                    {t('OnDemand.Point1.ButtonText') as string}
                                </Button>
                            </WrapperButton>
                        </Grid>
                        <Grid item xs={12} sm={7} md={7} lg={7}>
                            <WrapperImage className='right'>
                                <Image src={BussinesAnalytics} alt="hero" />
                            </WrapperImage>
                        </Grid>
                    </Grid>
                </Section>

            </Container>
        </Main>
    )
}

export { HeroOnDemand };