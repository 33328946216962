import { alpha, Container, styled } from "@mui/material";
import { motion } from "framer-motion";

export const Hero = styled('div')(({ theme }) => `
    position: relative;
    overflow: hidden;
    height: 100%;

    & > .active-header {
        background: ${theme.palette.common.black};
    }
`);

export const MuiContainer = styled(Container)(({ theme }) => ({
    paddingTop: '12px',
    height: '100%',
}));

export const ImageLogo = styled('div')(({ theme }) => ({

    '& > a': {
        display: 'flex',
        alignItems: 'center',

        '& > img': {
            width: 'auto',
            [theme.breakpoints.down("lg")]: {
                height: '1.875rem',
            },
            [theme.breakpoints.down("sm")]: {
                height: '2.25rem',
            },
        }
    }
}));

export const Button = styled('button')(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    border: 0,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    borderRadius: '6.25rem',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    '& > a': {
        display: 'block',
        padding: '0 .3125rem',
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.common.black,
        backgroundColor: theme.palette.common.white,
        fontWeight: 500,
        textDecoration: 'none'

    },
    [theme.breakpoints.down("sm")]: {
        padding: '.375rem .625rem',
    },
}));

export const Columns = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    [theme.breakpoints.down("sm")]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
}));

export const Column = styled(motion.div)(({ theme }) => ({
    '&.column': {
        '&:nth-child(1), &:nth-child(2), , &:nth-child(2)': {
            [theme.breakpoints.down("sm")]: {
                width: '100%',
            },
        },
        '&:nth-child(1), &:nth-child(3)': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        '&:nth-child(2)': {
            width: '100%',
            height: 'auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            [theme.breakpoints.down("sm")]: {
                paddingTop: '.875rem',
                flexDirection: 'column',
                alignItems: 'space-between',
                width: '100%',
            },
        },
        '&:nth-child(3)': {
            paddingRight: '0',
            width: '100%',
            justifyContent: 'flex-start',
            [theme.breakpoints.down("sm")]: {
                justifyContent: 'space-between',
                padding: '.875rem 0',
            },
        }
    }
}));

export const ActionColumn = styled(motion.div)(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    padding: '1.25rem 0',
    position: 'relative',

    [theme.breakpoints.down("sm")]: {
        width: '100%',
        justifyContent: 'space-between',
    }
}));

export const MenuList = styled(motion.div)(({ theme }) => ({
    backgroundColor: 'transparent',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: '3.125rem',
    listStyle: 'none',
    [theme.breakpoints.down("sm")]: {
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        paddingTop: '.9375rem',
        height: '100%',
        paddingRight: '0',
        width: '100%',
    },
    [theme.breakpoints.down("md")]: {
        // paddingRight: '0',
        backgroundColor: theme.palette.common.black,
    },
    '& > li': {
        width: '100%',
    },
    '& > li > a': {
        display: 'block',
        color: theme.palette.common.white,
        textDecoration: 'none',
        fontSize: '.875rem',
        fontWeight: 500,
        padding: '0 1.25rem',
        whiteSpace: 'nowrap',
        width: '100%',
        '&:last-child': {
            [theme.breakpoints.down("sm")]: {
                borderBottom: `.0625rem solid ${alpha(theme.palette.common.white, 0.1)}`,
            }
        },
        [theme.breakpoints.down("md")]: {
            fontSize: '.8125rem'
        },
        [theme.breakpoints.down("sm")]: {
            fontWeight: 400,
            fontSize: '1rem',
            padding: '.625rem 0',
        },

    }
}));

export const SocialList = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '100%',
    marginTop: '.9375rem',

    // [theme.breakpoints.down("md")]: {
    //     marginLeft: '.9375rem',
    // },

    '& > a': {
        display: 'block',
        padding: '0.3125rem',
        backgroundColor: 'transparent',
        marginLeft: '.625rem',

        '&:first-child': {
            marginLeft: 0,
        },

        '& > .svg > span > svg': {
            [theme.breakpoints.down("sm")]: {
                width: '.75rem',
            },
            [theme.breakpoints.down("md")]: {
                width: '1rem',
            },
            '& > path': {
                fill: theme.palette.common.white,
            }
        },
    }
}));

export const SocialLink = styled('a')({
    lineHeight: 1,

    '& > .svg > span': {
        lineHeight: 0
    }
});

export const ButtonIconTimes = styled('button')({
    lineHeight: 1,
    border: 0,
    padding: '.625rem',
    backgroundColor: 'transparent',
    borderRadius: '6.25rem',
    cursor: 'pointer',

    '& > .svg': {
        lineHeight: 0,
        position: 'relative',
        top: '.0625rem'
    }
});

export const WrapperIc = styled('div')({
    height: '1.625rem',
    width: '1.625rem',
    backgroundColor: 'rgba(255,255,255,0.1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '6.25rem',

});


// Translate

export const SelectLang = styled('div')(({ theme }) => `
    position: relative;
    margin-top: .9375rem;
`);

export const Icons = styled('div')(({ theme }) => ({
    background: 'rgba(255,255,255,0.1)',
    padding: '6px',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    cursor: 'pointer',
    '&.active': {
        borderBottomLeftRadius: '0',
        borderBottomRightRadius: '0',
    },
    '&.lang-mob': {
        justifyContent: 'flex-start',
        marginTop: '.9375rem',
    },
    '& > .svg:last-child': {
        display: 'block',
        marginLeft: '.375rem',
        [theme.breakpoints.down("md")]: {
            marginLeft: '.375rem',
        },
        '& > span': {
            '& > svg': {
                width: '10px',
                [theme.breakpoints.down("md")]: {
                    height: '.375rem',
                    width: 'auto'
                },
                '& > path': {
                    fill: theme.palette.common.white
                }
            }
        }
    },
    '& > .svg': {
        lineHeight: 0,
        '& > span': {
            '& > svg': {
                height: '16px',
                width: 'auto',
                '& > path': {
                    fill: theme.palette.common.white
                }
            }
        }
    }
}));

export const Langs = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: theme.typography.pxToRem(28),
    left: theme.typography.pxToRem(0),
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    background: 'rgba(255,255,255,0.1)',
    padding: '.1875rem',
    borderTopLeftRadius: '.25rem',
    borderBottomLeftRadius: '.25rem',
    borderBottomRightRadius: '.25rem',
    '&.lang-mob': {
        right: 'auto',
        left: 0,
        marginTop: '.3125rem',

        '& > .select-lang': {
            '&:first-child': {
                marginRight: '.625rem',
            }
        }
    },
}));

export const LangButton = styled('button')(({ theme }) => ({
    border: 0,
    backgroundColor: 'transparent',
    cursor: 'pointer',
    margin: '0 5px',
    '& > h1': {
        margin: 0,
        lineHeight: 1
    },

    '&.flag-1 > div': {
        '& > img': {
            height: 'auto',
            width: theme.typography.pxToRem(20),
        },
    },
    '&.flag-2 > div': {
        '& > img': {
            height: 'auto',
            width: theme.typography.pxToRem(26),
        },
    },
    '&.flag-3 > div': {
        '& > img': {
            height: 'auto',
            width: theme.typography.pxToRem(22),
        },
    },

}));

export const ImageFlag = styled('div')(({ theme }) => ({
    height: theme.typography.pxToRem(16),
    [theme.breakpoints.down("md")]: {
        height: theme.typography.pxToRem(18),

    },
    '& > img': {
        height: 'auto',
        width: theme.typography.pxToRem(24),
        [theme.breakpoints.down("md")]: {
            width: theme.typography.pxToRem(22),
        }
    },
    '& > .svg': {
        '& > span': {
            '& > svg': {
                position: 'relative',
                top: theme.typography.pxToRem(1),
                [theme.breakpoints.down("md")]: {
                    height: theme.typography.pxToRem(14),
                    width: 'auto'
                },
            }
        }
    }
}));
