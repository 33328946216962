import { toast } from 'react-toastify';

interface NotifyProps {
    type: "success" | "error" | "info" | "warning";
    message: string;
    onClose?: () => void;
}

export const notify = ({type, message, onClose}: NotifyProps) => toast[type](message, {
    position: "bottom-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    onClose: onClose
});