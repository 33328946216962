import { styled } from "@mui/material";

export const Main = styled('div')(({ theme }) => `
    position: relative;
    overflow: hidden;
    padding-bottom: 5rem;

    &::before {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        top: -6rem;
        bottom: 0;

        background: linear-gradient(180deg, #33CAFF 0%, #87E1FF 100%);
        filter: blur(189.415px);
        height: 570px;
        width: 460px;
        
        ${[theme.breakpoints.down("sm")]} {
            display: none;
        }
    }

    ${[theme.breakpoints.down("sm")]} {
        padding-bottom: 0;
    }
`);


export const OurStoryWrapper = styled('div')(({ theme }) => ``);

export const Icon = styled('div')(({ theme }) => `
    
    & > .svg {

        & > div {

            & > svg {
                width: auto;
                
                ${[theme.breakpoints.down("md")]} {
                    height: 10px;
                }
            }
        }
    }

    ${[theme.breakpoints.down("sm")]} {
        display: none;
    }
`);

export const OurStory = styled('div')(({ theme }) => `
    background-color: #8628E2;
    padding: 34px 30px 34px 30px;
    border-radius: 1rem;
    
    ${[theme.breakpoints.down("sm")]} {
        padding: 15px 30px 15px 30px;
    }
`);

export const Overlay = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    position: 'relative',
    top: '-4px',
    '& > img': {
        width: '98%',
        [theme.breakpoints.down("sm")]: {
            width: '95%',
        }
    }
}));

export const GridWrapper = styled('div')(({ theme }) => `

    &.only-d {
        ${[theme.breakpoints.down("sm")]} {
            display: none;
        }
    }

    &.only-m {
        margin-bottom: 30px;
        ${[theme.breakpoints.up("sm")]} {
            display: none;
        }
    }

    & > .MuiGrid-container {

        & > .grid-item {

            & > .block {

                & > .head {

                    & > .icon {

                        & > .svg {

                            & > span {

                                & > svg {

                                    height: 70px;
                                    width: auto;
                                    
                                    ${[theme.breakpoints.down("md")]} {
                                        height: 33px;
                                    }
                                }
                            }
                        }
                    }
                }

                & > .bottom {

                    & > .title,
                    & > .tag {
                        font-size: 24px;
                        ${[theme.breakpoints.down("md")]} {
                            font-size: 20px;
                        }

                        ${[theme.breakpoints.down("sm")]} {
                            font-size: 12px;
                        }
                    }
                }
            }
        }

    }
`);