import React from 'react';
import { Link } from 'react-router-dom';

import { Main } from './styles';

interface ButtonProps {
    children: React.ReactNode
    backgroundColor: string;
    buttonBorderColor: string;
    buttonBorderHoverColor: string;
    color: string;
    buttonSize: "small" | "medium"
    link?: string;
    className?: string;
    onClick?: any;
}
const Button: React.FC<ButtonProps & React.HTMLProps<HTMLButtonElement>> = (props: ButtonProps) => {

    const { backgroundColor, buttonBorderColor, buttonBorderHoverColor, buttonSize, color, className, onClick, link, children } = props;

    return (
        <Main
            bgColor={backgroundColor}
            textColor={color}
            borderColor={buttonBorderColor}
            borderHoverColor={buttonBorderHoverColor}
            size={buttonSize}
            className={className}
            onClick={onClick}
            {...props}
        >
            {!link
                ? (

                    <span>
                        {children}
                    </span>
                )
                : (
                    <Link to={`${link}`}>
                        {children}
                    </Link>
                )}
        </Main>
    )
}

export { Button };