import { typography } from './typography';
import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
    interface Palette {
        darkBlue: string;
        blue: string;
        blue300: string;
        blue500: string;
        red: string;
        light: string;
        pink: string;
        white: string;
        gray800: string;
        lightBlue: string;
        grayLight: string;
        gray: string;
        neonBlue: string;
        purple: string;
    }

    interface PaletteOptions {
        darkBlue?: string;
        blue?: string;
        blue300: string;
        blue500: string;
        red?: string;
        light?: string;
        pink?: string;
        white?: string;
        gray800?: string;
        lightBlue?: string;
        grayLight?: string;
        gray?: string;
        neonBlue: string;
        purple: string;
    }
}

const theme = createTheme({

    components: {
        MuiAvatar: {
            styleOverrides: {
                root: {
                    border: '.0625rem solid #FFFFFF',
                }
            }
        },
        MuiButtonGroup: {
            defaultProps: {
                size: 'large',
            }
        },
        MuiButton: {
            defaultProps: {
                size: 'large',
                disableRipple: true,
                sx: {
                    fontSize: 16,
                    textTransform: 'initial',
                    borderRadius: '18px !important',
                },
                style: {
                    boxShadow: 'none',
                    paddingLeft: 30,
                    paddingRight: 30,
                    fontWeight: 'normal',
                    borderRadius: '18px',
                    textTransform: 'initial',
                }
            }
        },
        MuiButtonBase: {
            defaultProps: {

                sx: {
                    "&:hover": {
                        backgroundColor: '#FFF !important'
                    }
                }
            },
            styleOverrides: {
                root: {
                    fontSize: '4rem',
                    "&.mui-btn": {
                        padding: '12px 22px',
                        "& > .svg": {
                            lineHeight: 0
                        },
                        "& > div": {
                            "& > .svg": {
                                lineHeight: 0
                            }
                        }
                    }
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: "#04243e",
                    backgroundColor: "#FFFFFF",
                    paddingRight: "0.3125rem",
                    fontSize: '0.875rem',

                    "&.Mui-focused": {
                        fontSize: '0.875rem',
                        color: '#04243e',
                    },

                    "&.Mui-error": {
                        color: `rgb(244, 67, 54) !important`,
                    },
                }
            }
        },
        MuiInputBase: {

            styleOverrides: {

                root: {

                    fontSize: '1rem',
                    "&.MuiInputBase-root": {
                        borderRadius: `18px`,
                        color: `#FFFFFF !important`,
                    },
                    "&.MuiInputBase-root input.MuiInputBase-input::placeholder": {
                        color: `#FFFFFF !important`,
                        opacity: 0.5
                    },
                    "&.MuiInputBase-root fieldset": {
                        borderColor: `#FFFFFF !important`,
                    },
                    "&.MuiInputBase-root input.MuiInputBase-input": {
                        padding: `12.5px 14px`,
                        paddingLeft: `.9375rem`,
                    },
                    "&.Mui-focused fieldset": {
                        border: `0.0625rem solid #FFFFFF !important`,
                    },
                    "&.Mui-error fieldset": {
                        border: `0.0625rem solid #FFFFFF !important`,
                    },

                }
            }
        },
        MuiOutlinedInput: {
            defaultProps: {
                size: 'medium'
            }
        },
        MuiTextField: {
            defaultProps: {
                disabled: false,
                size: 'small'
            },
            styleOverrides: {
                root: {
                    border: '4px',
                }
            }
        }
    },
    ...typography,
    palette: {
        darkBlue: "#011A31",
        blue: "#295b92",
        blue500: "#173652",
        blue300: "#445974",
        lightBlue: "#007bff",
        red: "#E8291A",
        light: "#F7F7F7",
        pink: "#DF114E",
        white: "#FFFFFF",
        gray800: "#212529",
        grayLight: "#cbcfd4",
        gray: "#aeb3b8",
        neonBlue: "#00FFFF",
        purple: "#8628E2"
    },
});

export { theme }