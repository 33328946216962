import { styled } from "@mui/material";

export const Main = styled('div')`
    overflow: hidden;
`;

export const Section = styled('div')(({ theme }) => `
    width: 100%;
    padding: 100px 0;
    
    ${[theme.breakpoints.down("sm")]} {
        padding: 60px 0;
    }

    &.point2,
    &.point3,
    &.point4 {
        position: relative;
        &::before,
        &::after {
            display: block;
            content: '';
            position: absolute;
            z-index: -1;
        }
    }

    &.point2 {
        &::before {
            width: 611.31px;
            height: 390.91px;
            left: -40rem;
            top: 24.375rem;
            
            background: linear-gradient(180deg, #33CAFF -13.16%, #33CAFF 86.84%);
            filter: blur(151.579px);
            transform: matrix(0.2, 0.98, -0.98, 0.19, 0, 0);
            
            ${[theme.breakpoints.down("sm")]} {
                width: 245.96px;
                height: 118.51px;
                top: 17rem;
                left: -8rem;
            }
        }
    }

    &.point3 {
        &::after {
            width: 811.31px;
            height: 432.42px;
            right: -50rem;
            top: 3.375rem;
            background: linear-gradient(180deg, #EC43E5 -13.16%, #B404AC 86.84%);
            filter: blur(151.579px);
            transform: matrix(0.2, -0.98, -0.98, -0.19, 0, 0);
            
            ${[theme.breakpoints.down("sm")]} {
                width: 245.96px;
                height: 118.51px;
                top: 7rem;
                right: -8rem;
            }
        }
    }

    &.point4 {
        &::before {
            width: 245.96px;
            height: 118.51px;
            left: -24rem;
            top: 17.375rem;
            
            background: linear-gradient(180deg, #4372EC -13.16%, #1750E4 86.84%);
            filter: blur(151.579px);
            transform: matrix(0.2, 0.98, -0.98, 0.19, 0, 0);
            
            ${[theme.breakpoints.down("sm")]} {
                width: 245.96px;
                height: 118.51px;
                top: 7rem;
                left: -8rem;
            }
        }
    }
`)

export const Image = styled('img')(({ theme }) => ({
    width: 'auto',
    [theme.breakpoints.up("xl")]: {
        marginLeft: '-40px',
        width: 'auto',
    },
    [theme.breakpoints.down("lg")]: {
        marginLeft: '-40px',
        width: '606px',
    },
    [theme.breakpoints.down("md")]: {
        marginLeft: '40px',
        width: '506px',
    },
    [theme.breakpoints.down("sm")]: {
        marginLeft: '0',
        width: '258px',
        marginBottom: theme.typography.pxToRem(20),
    }
}));

export const WrapperButton = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    // marginTop: theme.typography.pxToRem(48),
    [theme.breakpoints.down("sm")]: {
        justifyContent: 'center',
        // marginTop: theme.typography.pxToRem(20),
    }
}));


export const WrapperImage = styled('div')(({ theme }) => `
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    & > img {
        ${[theme.breakpoints.up("md")]} {
            width: auto;
        }
        ${[theme.breakpoints.down("md")]} {
            width: 100%;
        }
        ${[theme.breakpoints.down("sm")]} {
            width: 180px;
        }
    }

    &.left {
        justify-content: flex-start;
    }

    &.right {
        justify-content: flex-end;
    }
    
    &.left.relationship {
        & > img {
        ${[theme.breakpoints.down("sm")]} {
            width: 120px;
        }
    }
    }

    &.left,
    &.right {
        ${[theme.breakpoints.down("md")]} {
            justify-content: center;
        }
    }
`)