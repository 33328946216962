import { styled } from "@mui/material";

import Misson from '@img/mission.svg';
import Vision from '@img/vision.svg';
import Values from '@img/values.png';

export const Main = styled('div')(({ theme }) => `
    padding: 3.125rem 0;
    overflow: hidden;
    
    ${[theme.breakpoints.down("sm")]} {
        padding: 0 0;
    }
`);

export const Section = styled('div')(({ theme }) => `

    position: relative;
    padding-bottom: 2.5rem;

    &.point1,
    &.point2,
    &.point3,
    &.point4,
    &.point6 {
        &::before,
        &::after {
            display: block;
            content: '';
            position: absolute;
            z-index: 1;
        }
    }

    &.point1 {
        &::before {
            width: 470px;
            height: 226px;
            left: -18rem;
            top: 20.375rem;
            
            background: linear-gradient(180deg, #3CFF95 -13.16%, #1AFF82 86.84%);
            filter: blur(500px);
            transform: matrix(0.94, -0.33, -0.34, -0.94, 0, 0);
            opacity: 0.2;
            
            ${[theme.breakpoints.down("sm")]} {
                top: 13.375rem;
                left: -32rem;
            }
        }
    }

    &.point2 {

        &::before {
            width: 470px;
            height: 226px;
            right: -18rem;
            top: 16.375rem;
            opacity: 0.4;
            
            background: linear-gradient(180deg, #4CD9ED -13.16%, #78DEED 86.84%);
            filter: blur(500px);
            transform: matrix(-0.94, -0.33, 0.34, -0.94, 0, 0);
            
            ${[theme.breakpoints.down("sm")]} {
                top: 13.375rem;
                left: -32rem;
                display: none;
            }
        }
        
        &::after {
            background-image: url(${Misson});
            top: 4.6875rem;
            right: -0.125rem;
            height: 21.25rem;
            width: 100%;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center right;
            z-index: 0;

            ${[theme.breakpoints.down("md")]} {
                top: 34px;
                height: 162px;
                right: -28px;

            }

            ${[theme.breakpoints.down("sm")]} {
                top: 15px;
                height: 92px;
                right: -20px;
            }
        }
    }

    &.point3 {
        
        &::before {
            background-image: url(${Vision});
            top: 5.75rem;
            bottom: 0;
            margin: auto;
            left: 1.25rem;
            height: 249px;
            width: 100%;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center left;
            z-index: 0;

            ${[theme.breakpoints.down("md")]} {
                top: 34px;
                height: 162px;
                right: -28px;

            }

            ${[theme.breakpoints.down("sm")]} {
                top: 15px;
                height: 68px;
                right: 0;
                left: -20px;
            }
        }

        &::after {
            width: 470px;
            height: 226px;
            left: -18rem;
            top: 16.375rem;
            opacity: 0.2;
            
            background: linear-gradient(180deg, #B835B3 -13.16%, #EF52E9 86.84%);
            filter: blur(500px);
            transform: matrix(0.94, -0.33, -0.34, -0.94, 0, 0);
            
            ${[theme.breakpoints.down("sm")]} {
                top: 13.375rem;
                left: -32rem;
                display: none;
            }
        }
    }

    &.point4 {

        &::before {
            width: 470px;
            height: 226px;
            right: -18rem;
            top: 16.375rem;
            z-index: 1;
            opacity: 0.2;
            
            background: linear-gradient(180deg, #33FF60 -13.16%, #3DF2C7 86.84%);
            filter: blur(500px);
            transform: matrix(-0.94, -0.33, 0.34, -0.94, 0, 0);
            
            ${[theme.breakpoints.down("sm")]} {
                top: 13.375rem;
                left: -32rem;
                display: none;
            }
        }
        
        &::after {
            background-image: url(${Values});
            top: 4.6875rem;
            right: -3.625rem;
            height: 21.25rem;
            width: 100%;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center right;
            z-index: 0;

            ${[theme.breakpoints.down("md")]} {
                top: 34px;
                height: 162px;
                right: -28px;

            }

            ${[theme.breakpoints.down("sm")]} {
                top: 28px;
                height: 90px;
                right: -40px;
                
            }
        }
    }

    &.point6 {
        &::before {
            width: 470px;
            height: 226px;
            left: -18rem;
            top: 20.375rem;
            opacity: 0.2;
            
            background: linear-gradient(180deg, #4CD9ED -13.16%, #78DEED 86.84%);
            filter: blur(500px);
            transform: matrix(-0.94, -0.33, 0.34, -0.94, 0, 0);
            
            ${[theme.breakpoints.down("sm")]} {
                top: 13.375rem;
                left: -32rem;
                display: none;
            }
        }
    }

    &.point7 {
        padding-bottom: 0;
        
        ${[theme.breakpoints.down("sm")]} {
            padding-bottom: 0;
        }
    }
    
    &.point8 {
        
        ${[theme.breakpoints.down("sm")]} {
            padding-bottom: 0;
        }
    }

`);

export const WrapperTypography = styled('div')(({ theme }) => `
    max-width: 700px;
    margin: 0 auto;
    margin-right: 0;
    
    ${[theme.breakpoints.down("md")]} {
        max-width: 500px;
    }
    
    ${[theme.breakpoints.down("sm")]} {
        max-width: 200px;
    }
`);

export const OurStoryWrapper = styled('div')(({ theme }) => `
        padding-top: 6.25rem;
    ${[theme.breakpoints.down("md")]} {
        padding-top: 2.5rem;
    }
`);

export const OurStory = styled('div')(({ theme }) => `
    background-color: #3CFF95;
    padding: 3.1875rem 6.5625rem 1.9375rem 6.5625rem;
    border-radius: 1rem;
    
    ${[theme.breakpoints.down("sm")]} {
        padding: 1.25rem 1.5625rem 1.25rem 1.5625rem;
    }
`);

export const Overlay = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    position: 'relative',
    top: '-2px',
    '& > img': {
        width: '99%',
        [theme.breakpoints.down("sm")]: {
            width: '95%',
        }
    }
}));

export const TextBorder = styled('div')(({ theme }) => `
    color: black;
    font-size: 268px;
    opacity: 0.5;
    position: relative;
    
    ${[theme.breakpoints.down("md")]} {
        font-size: 116px;
    }
    
    ${[theme.breakpoints.down("sm")]} {
        font-size: 63px;
    }

    &::after {
        content: '';
        display: block;
        border: .7504px solid;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgb(0,0,0);
        background: -moz-linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.9303922252494747) 24%, rgba(255,247,247,0) 100%);
        background: -webkit-linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.9303922252494747) 24%, rgba(255,247,247,0) 100%);
        background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.9303922252494747) 24%, rgba(255,247,247,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#fff7f7",GradientType=1); 
        opacity: 1;
    }
    
    
    &.mission {
        left: -1.125rem;
        text-shadow:    -1px 1px 0 #4CD9ED,
                        1px 1px 0 #4CD9ED,
                        1px -1px 0 #4CD9ED,
                        -1px -1px 0 #4CD9ED;

        ${[theme.breakpoints.down("md")]} {
            left: -6px;
        }
    }
    
    &.vision {
        left: -1.125rem;
        text-shadow:    -1px 1px 0 #EC43E5,
                        1px 1px 0 #EC43E5,
                        1px -1px 0 #EC43E5,
                        -1px -1px 0 #EC43E5;

        ${[theme.breakpoints.down("md")]} {
            left: -6px;
        }
    }
    
    &.values {
        left: -1.125rem;
        text-shadow:    -1px 1px 0 #6DFF28,
                        1px 1px 0 #6DFF28,
                        1px -1px 0 #6DFF28,
                        -1px -1px 0 #6DFF28;

        ${[theme.breakpoints.down("md")]} {
            left: -6px;
        }
    }
`);

export const OurValues = styled('div')(({ theme }) => `
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 0; //-132px
    position: relative;
    z-index: 1;
    
    ${[theme.breakpoints.down("md")]} {
        margin-top: -54px;
        align-items: flex-start;
        flex-direction: column;
    }
    
    ${[theme.breakpoints.down("sm")]} {
        margin-top: 0; //-32px
    }
`);

export const OurValuesColumn = styled('div')(({ theme }) => `
    display: flex;
    align-items: center;
    justify-content: flex-start;
    
    &:last-child {
        margin-left: 40px;
    }
    
    ${[theme.breakpoints.down("md")]} {
        margin-bottom: 5px;
        
        &:last-child {
            margin-left: 0;
        }
    }
    
    & > .value {
        margin-left: 40px;
        ${[theme.breakpoints.down("md")]} {
            margin-left: 20px;
        }
        
        &:first-child {
            margin-left: 0;
        }
    }
`);

export const PointValue = styled('h1')(({ theme }) => `
    font-size: 32px;
    color: ${theme.palette.common.white};
    font-weight: ${theme.typography.fontWeightRegular};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
    
    ${[theme.breakpoints.down("md")]} {
        font-size: 18px;
        min-width: 126px;
        width: auto;
    }
    
    ${[theme.breakpoints.down("sm")]} {
        font-size: 12px;
        min-width: 92px;
    }
`);

export const Dot = styled('span')(({ theme }) => `
    width: .4375rem;
    height: .4375rem;
    background: #6DFF28;
    display: block;
    border-radius: 6.25rem;
    margin-right: 12px;
    
    ${[theme.breakpoints.down("sm")]} {
        margin-right: 6px;
        width: .125rem;
        height: .125rem;
    }
`);

export const List = styled('ul')(({ theme }) => `
    max-width: 730px;
    width: 100%;
    padding: 0;
    margin: 0;
    padding-top: 100px;
    
    ${[theme.breakpoints.down("sm")]} {
        padding-top: 0px;
    }
    
    &.list-texts {
        padding-top: 126px;
        padding-bottom: 60px;
        
        ${[theme.breakpoints.down("md")]} {
            padding-top: 72px;
        }
        
        ${[theme.breakpoints.down("sm")]} {
            padding-top: 0;
        }

        li {
            margin-bottom: 30px;
        }
    }
    
    &.list-texts.left {
        padding-bottom: 30px;
        ${[theme.breakpoints.up("sm")]} {
            display: none;
        }
    }
    
    &.list-texts.right {
        ${[theme.breakpoints.down("sm")]} {
            display: none;
        }
    }
    
`);

export const ListItem = styled('li')(({ theme }) => `
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 60px;
    
    ${[theme.breakpoints.down("sm")]} {
        margin-bottom: 20px;
    }
    
    &:last-child {
        margin-bottom: 0;
    }
`);

export const Icon = styled('div')(({ theme }) => `
    margin-right: 100px;
    
    ${[theme.breakpoints.down("md")]} {
        margin-right: 60px;            
    }
    
    ${[theme.breakpoints.down("sm")]} {
        margin-right: 20px;            
    }

    & > .svg {

        & > div {

            & > svg {
                ${[theme.breakpoints.down("sm")]} {
                    height: 26px;
                    width: auto;                    
                }
            }
        }
    }
`);

export const GetInTouch = styled('div')(({ theme }) => `
    margin: 0 auto;
    padding: 100px 0;
    
    ${[theme.breakpoints.down("sm")]} {
        padding: 15px 0;
    }
`);

export const GetInTouchBox = styled('div')(({ theme }) => `
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
    padding: 2.5rem;
    color: ${theme.palette.common.white};
    background: #000;
    background-clip: padding-box; /* !importanté */
    border: solid .125rem transparent; /* !importanté */
    border-radius: 2.375rem;
    text-align: center;
    font-weight: ${theme.typography.fontWeightRegular};
    font-size: 32px;
    padding: 55px 30px 55px 30px;

    ${[theme.breakpoints.down("md")]} {
        padding: 36px 15px 36px 15px;
        border-radius: .9375rem;
        font-size: 28px;
    }
    
    ${[theme.breakpoints.down("sm")]} {
        padding: 20px 15px 20px 15px;
        border: solid .0625rem transparent; /* !importanté */
        font-size: 12px;
        border-radius: .5625rem;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        z-index: -1;
        margin: -.125rem; /* !importanté */
        border-radius: inherit; /* !importanté */
        background: linear-gradient(89.67deg, #4CD9ED -5.83%, #33CAFF 51.64%, #4CD9ED 111.55%);  
        
        ${[theme.breakpoints.down("sm")]} {
            margin: -.0625rem; /* !importanté */
        }
    }
`);

export const WrapperButton = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    width: '100%',
    height: 'calc(100% - 494px)',
    // marginTop: theme.typography.pxToRem(200),
    [theme.breakpoints.down("md")]: {
        height: 'calc(100% - 294px)',
    },

    [theme.breakpoints.down("sm")]: {
        height: 'auto',
        justifyContent: 'center',
        // marginTop: theme.typography.pxToRem(20),
    }
}));

export const Customers = styled('div')(({ theme }) => `
    height: 288px;
    margin-top: 6.25rem;
    
    ${[theme.breakpoints.down("md")]} {
        margin-top: 1.875rem;
        height: 127px;
    }
`);

export const CustomersImage = styled('div')`
    height: 225px;
    background-size: cover !important;
    ${props => [props.theme.breakpoints.down("md")]} {
        background-size: contain !important;
        height: 100%;
    }
`;