/* eslint-disable no-script-url */
import React from 'react';
import { Container, Typography, useMediaQuery } from '@mui/material';
import { ReactSVG } from 'react-svg';

import LogoFooter from '@img/logo-footer.svg';
import FooterTop from '@img/footer-top.svg';

import {
    FlagBrazil,
    FlagUs,
    Linkedin,
    Twitter,
    Instagram,
    Facebook,
    Lang,
    ChevronDown,
    FlagSpain
} from '@icons';

import {
    Main,
    Logo,
    Wrapper,
    HeadImage,
    Content,
    Columns,
    Column,
    JoinUsButton,
    SocialList,
    SocialLink,
    SelectLang,
    Icons,
    Langs,
    LangButton,
    ImageFlag,
    MuiContainer,
    Overlay,
    SecurityLogos,
} from './styles';

import { i18n, useTranslate } from '@hooks';
import { Languages } from '@types';

export const topbarMenu = [
    { _id: "0", href: "#!", title: "o clube" },
    { _id: "1", href: "#!", title: "loja" },
    { _id: "2", href: "#!", title: "blumers4life" },
    { _id: "3", href: "#!", title: "contato" },
];



const social = [
    {
        id: 'a5df1f6a',
        title: 'Linkedin',
        link: 'https://www.linkedin.com/company/commarket-tecnologia-e-inform-tica/',
        icon: Linkedin
    },
    {
        id: 'b7e42bd3',
        title: 'Twitter',
        link: 'https://twitter.com/commarket_tecno',
        icon: Twitter
    },
    {
        id: 'f82927e1',
        title: 'Facebook',
        link: 'https://www.facebook.com/commarket',
        icon: Facebook
    },
    {
        id: '578159a6',
        title: 'Instagram',
        link: 'https://www.instagram.com/commarket.loyalty/',
        icon: Instagram
    }
];

const Footer: React.FC = () => {

    const mobile = useMediaQuery('(min-width: 600px)');

    const [showLangs, setShowLangs] = React.useState(false);

    const { changeLang } = useTranslate();

    const langs = [
        {
            id: '72e63f07',
            title: 'Português',
            icon: FlagBrazil,
            action: () => {
                setShowLangs(prev => prev = false);
                changeLang(Languages['pt-BR']);
                document.location.reload();
            },
        },
        {
            id: '019ef84e',
            title: 'English',
            icon: FlagUs,
            action: () => {
                setShowLangs(prev => prev = false);
                changeLang(Languages.en);
                document.location.reload();
            },
        },
        {
            id: '67159dd1',
            title: 'Español',
            icon: FlagSpain,
            action: () => {
                setShowLangs(prev => prev = false);
                changeLang(Languages.es);
                document.location.reload();
            },
        },
    ];

    return (
        <Main id='footer-page'>
            <Container maxWidth="lg">
                <Overlay>
                    <img src={FooterTop} alt="overlay footer" />
                </Overlay>
            </Container>
            <MuiContainer maxWidth="lg">

                <Wrapper>
                    <HeadImage />
                    <Content>
                        <Columns>
                            <Column className='column'>
                                <Logo>
                                    <ReactSVG src={LogoFooter} wrapper="span" className='svg' />
                                </Logo>
                                <Typography
                                    variant="subtitle1"
                                    component="h1"
                                    gutterBottom
                                    sx={{
                                        fontSize: mobile ? '1rem' : '.5rem'
                                    }}
                                >
                                    Rua Marechal Barbacena, 1064
                                    <br />CEP 03333-000 São Paulo / SP
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    component="h1"
                                    gutterBottom
                                    sx={{
                                        fontSize: mobile ? '1rem' : '.5rem'
                                    }}
                                >
                                    +55 (11) 2671-0400
                                </Typography>
                                {!mobile && (
                                    <SelectLang>
                                        <Icons onClick={() => setShowLangs(prev => !prev)} className='lang-mob'>
                                            <ReactSVG src={Lang} wrapper="span" className='svg' />
                                            <ReactSVG style={{ transform: `rotate(${showLangs ? '180deg' : '0deg'})` }} src={ChevronDown} wrapper="span" className='svg' />
                                        </Icons>
                                        {showLangs && (
                                            <Langs className='lang-mob'>
                                                {langs.map(({ id, title, icon, action }) => (
                                                    <LangButton key={`${id}`} onClick={action} className='select-lang'>
                                                        <ImageFlag>
                                                            <img src={icon} alt={title} />
                                                        </ImageFlag>
                                                        {/* <ReactSVG src={icon} wrapper="span" className='svg' /> */}
                                                        <Typography
                                                            variant="subtitle1"
                                                            component="h1"
                                                            gutterBottom
                                                            sx={{
                                                                fontSize: '.5rem'
                                                            }}
                                                        >
                                                            {title}
                                                        </Typography>
                                                    </LangButton>
                                                ))}
                                            </Langs>
                                        )}
                                    </SelectLang>
                                )}
                            </Column>
                            <Column className='column'>
                                <JoinUsButton>
                                    Junte-se a nós
                                </JoinUsButton>
                            </Column>
                            {mobile && (
                                <Column className='column langs'>
                                    <SelectLang>
                                        <Icons onClick={() => setShowLangs(prev => !prev)}>
                                            <ReactSVG src={Lang} wrapper="span" className='svg' />
                                            <ReactSVG style={{ transform: `rotate(${showLangs ? '180deg' : '0deg'})` }} src={ChevronDown} wrapper="span" className='svg' />
                                        </Icons>
                                        {showLangs && (
                                            <Langs>
                                                {langs.map(({ id, title, icon, action }) => (
                                                    <LangButton key={`${id}`} onClick={action}>
                                                        <ImageFlag>
                                                            <img src={icon} alt={title} />
                                                        </ImageFlag>
                                                        {/* <ReactSVG src={icon} wrapper="span" className='svg' /> */}
                                                        <Typography
                                                            variant="subtitle1"
                                                            component="h1"
                                                            gutterBottom
                                                            sx={{
                                                                fontSize: '.5rem'
                                                            }}
                                                        >
                                                            {title}
                                                        </Typography>
                                                    </LangButton>
                                                ))}
                                            </Langs>
                                        )}
                                    </SelectLang>
                                </Column>
                            )}
                            <Column className='column'>
                                <SocialList>
                                    {social.map(({ id, title, link, icon }) => (

                                        <SocialLink
                                            key={`${title}-${id}`}
                                            title={title}
                                            href={link}
                                            rel="noreferrer"
                                            target="_blank">
                                            <ReactSVG src={icon} wrapper="span" className='svg' />
                                        </SocialLink>

                                    ))}
                                </SocialList>
                                <SecurityLogos>
                                    <a href="javascript:if(window.open('https://secure.trust-provider.com/ttb_searcher/trustlogo?v_querytype=W&amp;v_shortname=CL1&amp;v_search=https://commarket.com.br/&amp;x=6&amp;y=5','tl_wnd_credentials'+(new Date()).getTime(),'toolbar=0,scrollbars=1,location=1,status=1,menubar=1,resizable=1,width=374,height=660,left=60,top=120')){};tLlB(tLTB);">
                                        <img src="https://www.trustlogo.com/images/install/sectigo_trust_seal_md_106x42.png" alt='secure trust' />
                                    </a>

                                    <iframe
                                        id="Iframe1"
                                        src="https://dunsregistered.dnb.com/SealAuthentication.aspx?Cid=1"
                                        title='dunsregistered'
                                        scrolling="no"
                                        width="114px"
                                        height="97px"
                                        frameBorder="0">
                                    </iframe>
                                </SecurityLogos>
                            </Column>

                        </Columns>
                    </Content>
                </Wrapper>
            </MuiContainer>
        </Main>
    )
}

export default Footer;