import { styled } from "@mui/material";

export const Main = styled('div')(({ theme }) => `
    position: relative;
    overflow: hidden;
    padding-bottom: 6.25rem;

    &::before {
        content: '';
        display: block;
        position: absolute;
        right: -7rem;
        top: -6rem;
        bottom: 0;

        background: linear-gradient(180deg, rgba(3, 251, 162, 0.6) 0%, rgba(170, 156, 255, 0.6) 100%);
        filter: blur(189.415px);
        height: 570px;
        width: 460px;
        
        ${[theme.breakpoints.down("sm")]} {
            height: 370px;
            width: 160px;
            background: linear-gradient(180deg, rgba(3, 251, 162, 0.6) 0%, rgba(170, 156, 255, 0.6) 100%);
            filter: blur(2.8358rem);

        }
    }
`);

export const Image = styled('img')(({ theme }) => ({
    [theme.breakpoints.up("xl")]: {
        width: '603px',
    },
    [theme.breakpoints.down("lg")]: {
        marginLeft: '-40px',
        width: '606px',
    },
    [theme.breakpoints.down("md")]: {
        marginLeft: '40px',
        width: '506px',
    },
    [theme.breakpoints.down("sm")]: {
        marginLeft: '0',
        width: '258px',
        marginBottom: theme.typography.pxToRem(20),
    }
}));