import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Router from './Routes';
import { ThemeProvider } from '@mui/material/styles';
import { TranslateProvider } from '@hooks';

import "./assets/css/index.scss"

import 'react-toastify/dist/ReactToastify.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./data/fontawesome";

import { theme } from './themes/themes';

export default function App() {

  return (
    <ThemeProvider theme={theme}>
      <TranslateProvider>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </TranslateProvider>
    </ThemeProvider>
  );
}
