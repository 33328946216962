import { styled } from "@mui/material";

export const Main = styled('div')(({theme}) => ({}));

export const Columns = styled('div')(({theme}) => ({}));

export const Column = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start'

}));
