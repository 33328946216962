import React from 'react';
import { motion } from "framer-motion";
import { ReactSVG } from 'react-svg';

import { routes } from '@utils';

import {
    Hero,
    ImageLogo,
    MenuList,
    Button,
    Columns,
    Column,
    ActionColumn,
    SocialList,
    SocialLink,
    MuiContainer,
    ButtonIconTimes,
    WrapperIc,
    Icons,
    ImageFlag,
    LangButton,
    Langs,
    SelectLang,
} from './styles';

// * Images
import Logo from '@img/commarket-logo.png';
import { Link } from 'react-router-dom';

import { Linkedin, Twitter, Instagram, Times, Facebook, FlagBrazil, FlagUs, FlagSpain, Lang, ChevronDown } from '@icons';

import { useNavigate } from 'react-router-dom';

import { i18n, useTranslate } from '@hooks';
import { Languages } from '@types';
import { Typography, useTheme } from '@mui/material';
const { t } = i18n;

const listMenu = [
    { id: '44a45fa3', title: t('Nav.Plataformas') as string, href: routes.platform },
    { id: '70291773', title: 'On-demand', href: routes.onDemand },
    { id: '2a9fd79d', title: 'End-to-end', href: routes.endToEnd },
    { id: '5e55e4fd', title: t('Nav.QuemSomos') as string, href: routes.about },
]

const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
        opacity: 1,
        scale: 1,
        transition: {
            delayChildren: 0.3,
            staggerChildren: 0.2
        }
    }
};

const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
        y: 0,
        opacity: 1
    }
};

const social = [
    {
        id: 'a5df1f6a',
        title: 'Linkedin',
        link: 'https://www.linkedin.com/company/commarket-tecnologia-e-inform-tica/',
        icon: Linkedin
    },
    {
        id: 'b7e42bd3',
        title: 'Twitter',
        link: 'https://twitter.com/commarket_tecno',
        icon: Twitter
    },
    {
        id: 'f82927e1',
        title: 'Facebook',
        link: 'https://www.facebook.com/commarket',
        icon: Facebook
    },
    {
        id: '578159a6',
        title: 'Instagram',
        link: 'https://www.instagram.com/commarket.loyalty/',
        icon: Instagram
    }
];

const MobileHeader: React.FC = () => {

    const theme = useTheme();

    const navigate = useNavigate();

    const [showLangs, setShowLangs] = React.useState(false);

    const { changeLang } = useTranslate();

    const langs = [
        {
            id: '72e63f07',
            title: 'Português',
            icon: FlagBrazil,
            action: () => {
                setShowLangs(prev => prev = false);
                changeLang(Languages['pt-BR']);
                navigate(-1);
            },
        },
        {
            id: '019ef84e',
            title: 'English',
            icon: FlagUs,
            action: () => {
                setShowLangs(prev => prev = false);
                changeLang(Languages.en);
                navigate(-1);
            },
        },
        {
            id: '67159dd1',
            title: 'Español',
            icon: FlagSpain,
            action: () => {
                setShowLangs(prev => prev = false);
                changeLang(Languages.es);
                navigate(-1);
            },
        },
    ];


    return (
        <Hero>
            <MuiContainer maxWidth="lg">

                <Columns>

                    <Column className='column'>
                        <ImageLogo>
                            <Link to="/">
                                <img src={Logo} alt='COMMARKET logo' />
                            </Link>
                        </ImageLogo>

                        <ButtonIconTimes onClick={() => navigate(-1)}>
                            <WrapperIc>
                                <ReactSVG src={Times} wrapper="span" className='svg' />
                            </WrapperIc>
                        </ButtonIconTimes>

                    </Column>

                    <Column className='column'>
                        <MenuList
                            className="container"
                            variants={container}
                            initial="hidden"
                            animate="visible"
                        >
                            {listMenu.map(({ id, title, href }) => {

                                return (
                                    <motion.li key={`${id}`} className="item" variants={item} >
                                        <Link to={href} > {title} </Link>
                                    </motion.li>
                                )
                            })}

                        </MenuList>

                        <ActionColumn
                            className="container"
                            initial={{ position: 'relative', opacity: 0, bottom: '-50px' }}
                            animate={{ opacity: 1, bottom: '0px' }}
                            transition={{ delay: 1 }}
                        >

                            <Button>
                                <Link to="/contate-nos">
                                    {t('Nav.ButtonText') as string}
                                </Link>
                            </Button>

                            <SocialList>
                                {social.map(({ id, title, link, icon }) => (
                                    <SocialLink
                                        key={`${title}-${id}`}
                                        href={link}
                                        target="_blank"
                                        rel='noreferrer'
                                    >
                                        <ReactSVG src={icon} wrapper="span" className='svg' />
                                    </SocialLink>
                                ))}
                            </SocialList>
                            
                            <SelectLang>
                                <Icons onClick={() => setShowLangs(prev => !prev)} className={`${showLangs ? 'active' : ''}`}>
                                    <ReactSVG src={Lang} wrapper="span" className='svg' />
                                    <ReactSVG style={{ transform: `rotate(${showLangs ? '180deg' : '0deg'})` }} src={ChevronDown} wrapper="span" className='svg' />
                                </Icons>
                                {showLangs && (
                                    <Langs>
                                        {langs.map(({ id, title, icon, action }, i) => (
                                            <LangButton key={`${id}`} onClick={action} className={`flag-${i + 1}`}>
                                                <ImageFlag>
                                                    <img src={icon} alt={title} />
                                                </ImageFlag>
                                                {/* <ReactSVG src={icon} wrapper="span" className='svg' /> */}
                                                <Typography
                                                    variant="subtitle1"
                                                    component="h1"
                                                    gutterBottom
                                                    sx={{
                                                        fontSize: '.5rem',
                                                        color: theme.palette.common.white
                                                    }}
                                                >
                                                    {title}
                                                </Typography>
                                            </LangButton>
                                        ))}
                                    </Langs>
                                )}
                            </SelectLang>
                        </ActionColumn>
                    </Column>

                </Columns>

            </MuiContainer>
        </Hero >
    )
}

export { MobileHeader };