import React from 'react';
import { Routes as AppRoutes, Route, useLocation } from 'react-router-dom';
import {
    Home,
    OnDemand,
    About,
    Platform,
    EndToEnd,
    Contact,
} from '@pages';

import { MobileHeader, LayoutDefault, HeroHome, HeroPlatform, HeroEndToEnd } from '@components/patterns';

import { routes } from './RoutePaths';


const Routes = () => {

    const { pathname } = useLocation();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <AppRoutes>
            <Route element={<HeroHome />}>
                <Route index element={<Home />} />
            </Route>

            <Route element={<HeroPlatform />}>
                <Route path={routes.platform} element={<Platform />} />
            </Route>

            <Route element={<HeroEndToEnd />}>
                <Route path={routes.endToEnd} element={<EndToEnd />} />
            </Route>

            <Route element={<LayoutDefault />}>
                <Route path={routes.onDemand} element={<OnDemand />} />
                <Route path={routes.about} element={<About />} />
                <Route path={routes.contact} element={<Contact />} />
            </Route>
            <Route path={routes.mobileMenu} element={<MobileHeader />} />

        </AppRoutes>
    )
}

export default Routes;