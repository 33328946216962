import React from 'react';
import { Outlet } from 'react-router-dom';

import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';

import { Main, Image } from './styles';

import { Header, Footer } from '@components/patterns';

import HeroImage from '@img/hero-image.png';

import { i18n } from '@hooks';

const HeroPlatform: React.FC = () => {

    const theme = useTheme();
    const mobile = useMediaQuery('(min-width: 600px)');
    const { t } = i18n;

    return (
        <>
            <Main>
                <Header />

                <Container maxWidth="lg">
                    <Grid
                        container
                        spacing={2}
                        alignItems={'center'}
                        flexDirection={!mobile ? 'column-reverse' : 'row'}
                        sx={{
                            position: 'relative',
                            zIndex: 9,
                            marginTop: theme.typography.pxToRem(40)
                        }}
                    >
                        <Grid item xs={12} sm={5} md={5} lg={5} xl={6}>

                            <Typography
                                variant="body1"
                                component="div"
                                align='left'
                                gutterBottom
                                sx={{
                                    fontSize: '40px',
                                    fontWeight: theme.typography.fontWeightMedium,
                                    color: '#C64EFF',
                                    marginBottom: theme.typography.pxToRem(20),
                                    [theme.breakpoints.down("md")]: {
                                        fontSize: '32px',
                                    },
                                    [theme.breakpoints.down("sm")]: {
                                        marginBottom: theme.typography.pxToRem(0),
                                        fontSize: '18px',
                                    },
                                }}
                            >
                                {t('Platforms.Hero.Title') as string}
                            </Typography>
                            <Typography
                                variant="body1"
                                component="div"
                                align='left'
                                gutterBottom
                                sx={{
                                    fontSize: '40px',
                                    fontWeight: theme.typography.fontWeightMedium,
                                    color: theme.palette.common.white,
                                    marginBottom: theme.typography.pxToRem(20),
                                    [theme.breakpoints.down("md")]: {
                                        fontSize: '32px',
                                    },
                                    [theme.breakpoints.down("sm")]: {
                                        fontSize: '20px',
                                    },
                                }}
                            >
                                {t('Platforms.Hero.Subtitle') as string}
                            </Typography>
                            <Typography
                                variant="body1"
                                component="h1"
                                align='left'
                                sx={{
                                    fontSize: '18px',
                                    color: theme.palette.common.white,
                                    fontWeight: theme.typography.fontWeightRegular,
                                    marginBottom: theme.typography.pxToRem(20),
                                    [theme.breakpoints.down("md")]: {
                                        marginBottom: theme.typography.pxToRem(14),
                                        fontSize: '16px',
                                    },
                                    [theme.breakpoints.down("sm")]: {
                                        marginBottom: theme.typography.pxToRem(14),
                                        fontSize: '12px',
                                    },
                                }}
                            >
                                {t('Platforms.Hero.Text') as string}
                            </Typography>
                            <Typography
                                variant="body1"
                                component="h1"
                                align='left'
                                sx={{
                                    fontSize: '18px',
                                    color: theme.palette.common.white,
                                    fontWeight: theme.typography.fontWeightBold,
                                    [theme.breakpoints.down("md")]: {
                                        marginBottom: theme.typography.pxToRem(14),
                                        fontSize: '16px',
                                    },
                                    [theme.breakpoints.down("sm")]: {
                                        fontSize: '12px',
                                    },
                                }}
                            >
                                {t('Platforms.Hero.TextBottom') as string}
                            </Typography>

                        </Grid>
                        <Grid item xs={12} sm={5} md={5} lg={5} xl={6}>
                            <Image src={HeroImage} alt="hero" />
                        </Grid>
                    </Grid>
                </Container>
            </Main>
            <Outlet />
            <Footer />

        </>
    )
}

export { HeroPlatform };