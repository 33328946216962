import React from 'react';
import { Typography, useMediaQuery, useTheme } from '@mui/material';

import { Main, Columns, Column } from './styles';

interface OurNumberProps {
    value: number | string;
    title: string;
    subtitle: string;
}


const OurNumber: React.FC<OurNumberProps> = ({ value, title, subtitle }: OurNumberProps) => {

    const { typography, palette } = useTheme();
    const matches = useMediaQuery('(min-width: 600px)');

    return (

        <Main className='our-number'>
            <Columns className='columns'>
                <Column className='column'>
                    <Typography
                        variant="caption"
                        component="div"
                        align='left'
                        sx={{
                            fontSize: matches ? '72px' : '35px',
                            fontWeight: typography.fontWeightBold,
                            color: palette.neonBlue,
                            lineHeight: 1
                        }}
                    >
                        {value}
                    </Typography>
                    <Typography
                        variant="caption"
                        component="div"
                        align='left'
                        sx={{
                            fontSize: matches ? '18px' : '12px',
                            fontWeight: typography.fontWeightLight,
                            color: palette.common.white,
                            position: 'relative',
                            top: -4,
                            paddingLeft: '.625rem'
                        }}
                    >
                        {title}
                    </Typography>
                </Column>
                <Column className='column'>
                    <Typography
                        variant="caption"
                        component="div"
                        align='left'
                        sx={{
                            fontSize: matches ? '18px' : '11px',
                            fontWeight: typography.fontWeightLight,
                            color: palette.common.white,
                            position: 'relative',
                            top: -4,
                            paddingTop: '.625rem'
                        }}
                    >
                        {subtitle}
                    </Typography>
                </Column>

            </Columns>
        </Main>

    )
}

export { OurNumber };