import { styled, Container } from "@mui/material";

export const Main = styled('footer')(({ theme }) => `
    background-color: transparent;
    padding-top: 3.75rem;
    padding-bottom: 1.875rem;
    overflow: hidden;

    position: relative;
    &::before,
    &::after {
        display: block;
        content: '';
        position: absolute;
        z-index: -1;
    }

    &::before {
        width: 960.88px;
        height: 500.08px;
        left: -44rem;
        top: 17rem;

        background: linear-gradient(180deg, #DB8DD8 -13.16%, #960DAC 86.84%);
        filter: blur(500px);
        transform: matrix(-0.8, -0.61, -0.6, 0.79, 0, 0);

        ${[theme.breakpoints.down("sm")]} {
            left: -41rem;
            top: 16rem;
        }
    }

    &::after {
        width: 496.64px;
        height: 500.08px;
        right: -24rem;
        top: 20rem;

        background: linear-gradient(180deg, #2603FB -13.16%, #DC06FF 86.84%);
        filter: blur(500px);
        transform: matrix(-0.92, -0.39, -0.38, 0.92, 0, 0);

        ${[theme.breakpoints.down("sm")]} {
            right: -24rem;
            top: 16rem;
        }
    }
`);

export const MuiContainer = styled(Container)`
    background-color: transparent;
`;

export const Overlay = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    position: 'relative',
    top: 0,
    '& > img': {
        width: '100%'
    }
}));

export const Logo = styled('div')(({ theme }) => ({

    '& > .svg': {
        '& > span': {
            '& > svg': {
                width: 'auto',
                [theme.breakpoints.down("md")]: {
                    height: '24px',
                },
                [theme.breakpoints.down("sm")]: {
                    height: '20px',
                },
            }
        }
    }
}));

export const Wrapper = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    padding: '30px 30px 2.5rem 30px'
}));

export const HeadImage = styled('div')(({ theme }) => ({}));

export const Content = styled('div')(({ theme }) => ({}));

export const Columns = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    position: 'relative'
}));

export const Column = styled('div')(({ theme }) => ({
    position: 'relative',
    '&.column': {
        '&:nth-child(1)': {
            [theme.breakpoints.up("md")]: {
                maxWidth: '340px',
                width: '100%',
            },
        },
        '&:nth-child(2)': {
            display: 'flex',
        },
        '&.langs': {
            [theme.breakpoints.down("md")]: {
                position: 'absolute',
                bottom: 0,
                right: 0,
            }
        }
    },
    // '&.langs': {
    //     [theme.breakpoints.down("md")]: {
    //         display: 'none'
    //     }
    // }
}));

export const JoinUsButton = styled('button')(({ theme }) => ({
    fontSize: '1rem',
    fontWeight: theme.typography.fontWeightBold,
    fontFamily: theme.typography.fontFamily,
    padding: '.25rem .9375rem',
    backgroundColor: 'transparent',
    border: 0,
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    [theme.breakpoints.down("md")]: {
        fontSize: '12px',
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: '8px',
    },
}));

export const SocialList = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '100%',

    '& > a': {
        display: 'block',
        padding: '0 10px',
        backgroundColor: 'transparent',

        '&:first-child': {
            marginLeft: 0,
        },
        '&:nth-child(2)': {

            '& > .svg > span > svg': {
                position: 'relative',
                top: '2px'
            }
        },

        [theme.breakpoints.down("md")]: {
            padding: '0 5px',
        },

        '& > .svg > span > svg': {
            [theme.breakpoints.down("md")]: {
                height: theme.typography.pxToRem(16),
            },
            [theme.breakpoints.down("sm")]: {
                marginLeft: '0',
                height: theme.typography.pxToRem(12),
                width: 'auto'
            },
            '& > path': {
                fill: theme.palette.common.black,
            }
        },
    }
}));

export const SocialLink = styled('a')({
    lineHeight: 1,

    '& > .svg > span': {
        lineHeight: 0
    }
});

export const SelectLang = styled('div')(({ theme }) => `
    position: relative;
`);

export const Icons = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: '.3125rem',
    cursor: 'pointer',
    '&.lang-mob': {
        justifyContent: 'flex-start',
        marginTop: '.9375rem',
    },
    '& > .svg:last-child': {
        display: 'block',
        marginLeft: '.875rem',
        [theme.breakpoints.down("md")]: {
            marginLeft: '.375rem',
        },
        '& > span': {
            '& > svg': {
                [theme.breakpoints.down("md")]: {
                    height: '.375rem',
                    width: 'auto'
                },
            }
        }
    },
    '& > .svg': {
        lineHeight: 0,
        '& > span': {
            '& > svg': {
                [theme.breakpoints.down("md")]: {
                    height: '16px',
                    width: 'auto'
                },
            }
        }
    }
}));

export const Langs = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: theme.typography.pxToRem(40),
    right: theme.typography.pxToRem(-6),
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    [theme.breakpoints.down("md")]: {
        top: theme.typography.pxToRem(20),
        right: theme.typography.pxToRem(-6),
    },
    '&.lang-mob': {
        right: 'auto',
        left: 0,
        marginTop: '.3125rem',
        
        '& > .select-lang': {
            '&:first-child': {
                marginRight: '.625rem',
            }
        }
    }
}));

export const LangButton = styled('button')(({ theme }) => ({
    border: 0,
    backgroundColor: 'transparent',
    cursor: 'pointer',
    '& > h1': {
        margin: 0,
        lineHeight: 1
    },

}));

export const ImageFlag = styled('div')(({ theme }) => ({
    height: theme.typography.pxToRem(24),
    [theme.breakpoints.down("md")]: {
        height: theme.typography.pxToRem(18),

    },
    '& > img': {
        height: 'auto',
        width: theme.typography.pxToRem(28),
        [theme.breakpoints.down("md")]: {
            width: theme.typography.pxToRem(22),
        }
    },
    '& > .svg': {
        '& > span': {
            '& > svg': {
                position: 'relative',
                top: theme.typography.pxToRem(1),
                [theme.breakpoints.down("md")]: {
                    height: theme.typography.pxToRem(14),
                    width: 'auto'
                },
            }
        }
    }
}));

export const SecurityLogos = styled('div')(({ theme }) => `
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    flex-direction: column;
    
    & > img {
        display: block;

        &:nth-child(1) {
            height: auto; 
            
            ${[theme.breakpoints.down("sm")]} {
                height: 28px; 
            }
        }

        &:nth-child(2) {
            height: auto; 
            margin-left: 6px;
            
            ${[theme.breakpoints.down("sm")]} {
                height: 34px; 
            }
        }
    }
`);