export const es = {
    locale: "pt",
    Nav: {
        "Plataformas": "Plataformas",
        "QuemSomos": "Quienes somos",
        "ButtonText": "Contáctenos",
    },
    Home: {
        Hero: {
            "Headline": "Motor de Lealtad y Puntos",
            "Text": "Plataforma tecnológica utilizada por los mayores programas de fidelización de Brasil.",
            "ButtonText": "Sepa más",
        },
        Point1: {
            "HeadlineStart": "Con más de ",
            "HeadlineEnd": "de experiencia, somos una empresa llena de casos de éxito que contar.",
            "Tag": "20 años",
            "BottomHeadline": "Ayudamos a empresas y consumidores a estrechar lazos y alcanzar metas.",
            "ButtonText": "Aprenda más acerca de nosotros",
            Block_1: {
                "Title": "Millones",
                "Text": "de usuarios registrados",
            },
            Block_2: {
                "Title": "Millones",
                "Text": "de transacciones procesadas por mes",
            },
            Block_3: {
                "Title": "Mil Millones",
                "Text": "de transacciones procesadas por año",
            },
            Block_4: {
                "Title": "Mil",
                "Text": "transacciones del programa de lealtad procesadas por mes",
            }, 
        },
        Point2: {
            "Headline": "Muchas posibilidades en un solo lugar",
            Block_1: {
                "Title": "COMMARKET",
                "Text": "Motor de puntos "
            },
            Block_2: {
                "Title": "COMMARKET",
                "Text": "Campañas"
            },
            Block_3: {
                "Title": "COMMARKET",
                "Text": "Gestión de OKR"
            },
            Block_4: {
                "Title": "COMMARKET",
                "Text": "Marketplace"
            },
            "ButtonText": "Conozca más sobre nuestros productos",
        },
        Point3: {
            "Headline": "Servicios",
            "Tag": "On-demand",
            Block_1: {
                "Title": "Planificación Estratégica y Gestión",
            },
            Block_2: {
                "Title": "Marketing y BI",
            },
            Block_3: {
                "Title": "Programas de Lealtad, Gestión de Relaciones y Campañas",
            },
            Block_4: {
                "Title": "Tecnología",
            },
            "ButtonText": "Sepa más",
        },
        Point4: {
            "Headline": "Durante las últimas dos décadas, nos hemos dedicado a brindar soluciones de alto rendimiento a estas organizaciones.",
        },
        Point5: {
            "Headline": "¿Quiere saber más sobre nuestros casos de éxito?",
            "ButtonText": "Agende una charla con nosotros",
        }
    },
    Platforms: {
        Hero: {
            "Title": "COMMARKET Motor de puntos",
            "Subtitle": "Muchas posibilidades en una plataforma",
            "Text": "El RP2000 es una plataforma de alto rendimiento orientada a eventos con la máxima capacidad de procesamiento transaccional, lo que permite el seguimiento de los programas de fidelización.",
            "TextBottom": "Plataforma tecnológica utilizada por los mayores programas de fidelización de Brasil."
        },
        Point2: {
            Block1: {
                "Title": "Programas de frecuencia ",
                "Text": "Los clientes obtienen puntos o créditos para canjear; ofertas únicas",
            },
            Block2: {
                "Title": "Programas de fidelización",
                "Text": "los clientes reciben los beneficios que se derivan de ascender a un estado superior de cliente; relación a largo plazo",
            },
            Block3: {
                "Title": "Programas de recomendación",
                "Text": "los clientes ganan puntos o créditos para canjearlos por recomendar productos a otros clientes potenciales; ofertas únicas",
            },
            Block4: {
                "Title": "Campañas de incentivos ",
                "Text": "Planes que brindan compensación a un determinado grupo de partes interesadas por lograr ciertas metas de desempeño o condiciones similares dentro de un período específico",
            },
            Block5: {
                "Title": "Campañas promocionales",
                "Text": "promociones que estimulan las compras y las ventas al ofrecer descuentos, devoluciones, envío gratuito, obsequios y más",
            },
            Block6: {
                "Title": "Programas de coalición",
                "Text": "programas de lealtad únicos que abarcan múltiples negocios",
            },
            "ButtonText": "Hable con un experto",
        },
        Point3: {
            "Headline": "COMMARKET Gestión de OKR",
            "Title": "Impulsa el rendimiento de su equipo o negocio",
            Block1: {
                "Title": "El software de gestión de OKR de COMMARKET es una herramienta que le ayuda a implementar estrategias comerciales de forma guiada y clara. Establezca, realice un seguimiento y supere los objetivos con el software de gestión de OKR de COMMARKET."
            },
            Block2: {
                "Title": "El Software de Gestión OKR de COMMARKET permite la alineación entre la planificación y ejecución de estrategias, la visualización del progreso de su equipo y el seguimiento del desempeño de personas, áreas o unidades de negocio en tiempo real."
            },
            Block3: {
                "Title": "Tenga un plan estratégico, déle acceso a su equipo y asegúrese de que todos estén en el camino correcto."
            },
            Block4: {
                "Title": "Plataforma intuitiva y altamente informativa que hace más efectivo el proceso de comunicación."
            },
            Block5: {
                "Title": "El software de gestión OKR de COMMARKET es el puente entre sus estrategias y objetivos, basado en la gestión de objetivos."
            },
            Block6: {
                "Title": "Potente marco de establecimiento de objetivos que promueve la convergencia de la visión y los esfuerzos, la rápida adaptación al entorno cambiante y los registros guiados muy amigables."
            },
            Featured: {
                "Title": "COMMARKET Gestión de OKR",
                "Text": "¡aumenta sus posibilidades de éxito!",
                "ButtonText": "Hable con uno de nuestros asesores"
            }
        },
        Point4: {
            "Headline": "COMMARKET Campañas",
            "Title": "Impulsa la relación de su empresa con los empleados, socios y consumidores",
            Block1: {
                "Title": "COMMARKET Campañas es una plataforma digital que permite parametrizar, monitorear y medir programas de incentivos y recompensas para los más variados públicos."
            },
            Block2: {
                "Title": "Defina lo que espera de sus empleados, socios comerciales o consumidores, realice un seguimiento de su evolución y recompénselos por alcanzar los objetivos con las campañas de COMMARKET."
            },
            Block3: {
                "Title": "Puede administrar fácilmente programas de incentivos y recompensas en tiempo real. Y lo mejor de todo, quienes se unen a sus programas rastrean todo desde una cuenta única, personal y protegida."
            },
            Block4: {
                "Title": "COMMARKET Campañas es la plataforma más fácil de usar y segura del mercado. Y lo mejor de todo: ¡la plataforma se parece a su empresa!"
            },
            Block5: {
                "Title": "Olvídese de las hojas de cálculo de Excel. Dejae de usar cuentas que no son suyas, no son administradas por usted. ¡Tengalo todo en un solo entorno!"
            },
            Featured: {
                "TitleStart": "¡Acérquese a sus",
                "TitleEnd": "y audiencia!",
                "Tag": "objetivos",
                "ButtonText": "Hable con uno de nuestros asesores"
            }
        },
        Point5: {
            "Headline": "COMMARKET Marketplace",
            "Title": "A COMMARKET ya ha creado el mercado que necesita.",
            "BottomHeadline": "Si necesitas una herramienta para la venta online, le ofrecemos la estructura y el servicio de operación/soporte.",
            "BottomText1": "Sin embargo, si está buscando un marketplace y alianzas para su programa de fidelización o campañas de incentivos/recompensas, estamos integrados con varios, incluidos los más importantes del mercado. Cupones, vales, tarjetas, tarjetas de regalo, bienes y servicios están disponibles en modo plug & play.",
            "BottomText2": "Por último, no lo olvide: podemos crear su propio catálogo y ponerlo a disposición en nuestra plataforma. ¡Expanda su red! ¡Aumente sus ventas!",
            Block1: {
                "Title": "Store"
            },
            Block2: {
                "Title": "Accounts"
            },
            Block3: {
                "Title": "Logística"
            },
            Block4: {
                "Title": "Admin"
            },
            Block5: {
                "Title": "Comunicación"
            },
            Block6: {
                "Title": "Pago"
            },
            Featured: {
                "Title": "¡Está todo listo e integrado!",
            },
            "ButtonText": "Hable con uno de nuestros asesores",
        },
    },
    About: {
        "Headline": "Estamos agradecidos de servir a tantas marcas importantes y tantos ejecutivos y empresarios brillantes.",
        Point1: {
            "Headline": "Nuestra historia",
            "HistoryText": "La historia de COMMARKET está entrelazada con la historia de los programas de Lealtad y Relaciones en Brasil, cuando se trata de conocimiento y tecnología integrada.",
        },
        Point2: {
            "Title": "Misión",
            "Text": "Nuestra Misión es crear valor para las organizaciones ofreciendo conocimiento y soluciones sistémicas con enfoque en la gestión estratégica de las relaciones organizacionales (B2B, B2C y B2E) y resultados, buscando el crecimiento del negocio y aumentando la rentabilidad para todos los clientes y usuarios de nuestras plataformas."
        },
        Point3: {
            "Title": "Visión",
            "Text": "Nuestra Visión es tener más de 200 millones de usuarios activos en nuestras plataformas para 2025."
        },
        Point4: {
            "Title": "Valores",
            "Text": "Nuestros valores son:",
            Block1: {
                "Title": "Pasión",
            },
            Block2: {
                "Title": "Excelencia",
            },
            Block3: {
                "Title": "Sencillez",
            },
            Block4: {
                "Title": "Principio moral",
            },
        },
        Point5: {
            "Title": "",
            "Text": "",
            Block1: {
                "Title": "Más de 20 años de experiencia en Gestión Estratégica de Relaciones y Resultados",
            },
            Block2: {
                "Title": "Integra soluciones de consultoría y tecnología para todos los perfiles de negocio",
            },
            Block3: {
                "Title": "Equipo de expertos en creación de valor: Planificación Estratégica, Gestión de Metas y Desempeño, Programas de Lealtad y Campañas de Incentivos.",
            },
            Block4: {
                "Title": "Empresa innovadora con capacidad de producción y personalización de sistemas",
            },
            Block5: {
                "Title": "Alianzas estratégicas con empresas líderes",
            },
        },
        Point6: {
            "Title": "Trabajamos incansablemente para mantener nuestro espíritu pionero.",
            "Text": "Estamos orgullosos de nuestra historia y de lo mucho que ayudamos a nuestros clientes y usuarios a alcanzar sus objetivos."
        },
        Point7: {
            "Title": "Únese a nosotros",
            "Text": "Buscamos constantemente rodearnos y trabajar en alianza con personas y organizaciones que valoran y aspiran a lo mismo que nosotros:",
            "ButtonText": "Oportunidades de trabajo",
            Block1: {
                "Tag": "Conocimiento ",
                "Title": "Empodérarse y capacítarse para cumplir lo que promete, con lo que se compromete.",
            },
            Block2: {
                "Tag": "Excelencia técnica ",
                "Title": "Realizar tareas con calidad y responsabilidad en el menor tiempo posible.",
            },
            Block3: {
                "Tag": "Respeto",
                "Title": "Respetar las leyes e inversiones de quienes confían en nosotros.",
            },
            Block4: {
                "Tag": "Innovación",
                "Title": "Desarrollar incesantemente métodos que sorprendan positivamente a quienes nos afectan o son afectados por nosotros.",
            },
            Block5: {
                "Tag": "Credibilidad ",
                "Title": "Apuntar a mantener la credibilidad, el activo más valioso de una persona física o jurídica.",
            },
            Block6: {
                "Tag": "Deseo de Transformación ",
                "Title": "Prepararse para superar las crisis y cumplir con las expectativas de quienes nos afectan o son afectados por nuestras acciones.",
            },
        },
    },
    Contact: {
        "Headline": "Mandenos un mensaje",
        "Nome": "Nombre",
        "EMail": "Correo electrónico",
        "Telefone": "Teléfono",
        "Companhia": "Compañía",
        "Mensagem": "Mensaje",
        "ButtonText": "Enviar",
        Messages: {
            Name: "Escriba su nombre",
            EMail: "Digite seu e-mail",
            Company: "Escriba su correo electrónico",
            Phone: "Ingrese su número telefónico",
            Message: "Escriba un mensaje",
        }
    },
    EndToEnd: {
        Hero: {
            "Headline": "El único ecosistema para la gestión de resultados de principio a fin",
            "Text": "Crezca su negocio con nuestro pack de 4 productos",
            Featured: {
                "Title": "Comience su jornada COMMARKET con un solo módulo o el ecosistema completo.",
            },
            Block_1: {
                "Title": "COMMARKET ",
                "Text": "Motor de puntos",
            },
            Block_2: {
                "Title": "COMMARKET ",
                "Text": "Gestión de OKR",
            },
            Block_3: {
                "Title": "COMMARKET ",
                "Text": "Campañas",
            },
            Block_4: {
                "Title": "COMMARKET ",
                "Text": "Marketplace",
            },
        },
        Point1: {
            "Headline": "Desde la gestión de objetivos basada en OKR hasta el compromiso de los empleados, socios comerciales y consumidores",
            Block_1: {
                "Title": "Ganar convergencia.",
            },
            Block_2: {
                "Title": "Aumentar el nivel de confianza y compromiso.",
            },
            Block_3: {
                "Title": "¡Crezca su negocio!",
            },
            "ButtonText": "Agende una charla con nosotros"
        }
    },
    OnDemand: {
        Point1: {
            "Headline": "Planificación Estratégica y Gestión",
            "Text_1": "Asesoramos a empresas y emprendedores desde la concepción de la Misión y Visión de un proyecto o negocio hasta la alineación de recursos para su ejecución estratégica.",
            "Text_2": "Cuente con nuestro equipo de consultores para apalancar los resultados de su proyecto o negocio con el Software de Gestión OKR de COMMARKET.",
            "Text_3": "La variedad de experiencias y sectores en los que ya trabajamos, y la constante actualización de nuestros profesionales, nos convierten en un socio valioso para la consecución de sus objetivos.",
            "ButtonText": "Contáctenos"
        },
        Point2: {
            "Headline": "Programas de Lealtad, Relaciones y Campañas",
            "Text_1": "Durante dos décadas, existimos para ayudar a las empresas a fortalecer los lazos y reconocer los logros de los empleados, socios comerciales y consumidores.",
            "Text_2": "Si tu empresa tiene un programa de fidelización, relación, incentivo o recompensa desactualizado, ineficaz o incluso obsoleto, te ayudamos a crear una estrategia que promueva la participación de tu público objetivo y genere resultados reales.",
            "Text_3": "Conocemos las mejores prácticas y escenarios de presupuesto, materiales de apoyo y formas de comunicación necesarias para que logres tus objetivos.",
            "ButtonText": "Contáctenos"
        },
        Point3: {
            "Headline": "Marketing & BI",
            "Text_1": "Asesoramos a nuestros clientes en la observación del comportamiento de los consumidores y otras partes interesadas relevantes, con miras a mejorar estrategias o desarrollar nuevos productos, servicios o procesos.",
            "Text_2": "El análisis de datos y las redes sociales están en el corazón de nuestro trabajo. Contamos con el conocimiento necesario para potenciar las ventas, los resultados financieros y la satisfacción de quienes se relacionan con su negocio.",
            "ButtonText": "Contáctenos"
        },
        Point4: {
            "Headline": "Tecnología",
            "Text_1": "¿Tenemos nuestras propias plataformas, concebidas, diseñadas, desarrolladas y operadas por nosotros? ¡Si tenemos! Pero lo que necesita saber es que nuestra historia se ha pavimentado con proyectos hechos a medida y personalizados.",
            "Text_2": "SSomos una FÁBRICA DE SOFTWARE y gestionamos proyectos como un servicio, apoyamos a nuestros clientes (Help Desk), integramos sistemas, operamos y monitoreamos los programas y áreas de nuestros clientes.",
            "Text_3": "Conocimiento COMMARKET + Tecnología COMMARKET = Resultado",
            "ButtonText": "Contáctenos"
        },
    },
    Footer: {
        "ButtonText": "Contáctenos",
    }
};