import { styled } from "@mui/material";

import Divider from '@img/divider.svg';
import DividerSmall from '@img/divider-small.svg';
import DividerPurple from '@img/divider-purple.svg';
import OctogonoBg from '@img/octogono-bg.svg';

export const Main = styled('main')`
    color: ${props => props.theme.palette.primary.light};
    /* padding: 100px 0; */
    overflow: hidden;
    height: auto;
`;

export const WrapperSections = styled('div')(({theme}) => `
    background-image: url(${OctogonoBg});
    background-size: contain;
    background-position: 50% -62%;
    background-repeat: no-repeat;

    ${[theme.breakpoints.down("sm")]} {
        background-image: none;
    }
`);

export const Section = styled('div')(({ theme }) => `
padding: 60px 0;


&.point1,
&.point2,
&.point3 {
    position: relative;
    &::before,
    &::after {
        display: block;
        content: '';
        position: absolute;
        z-index: -1;
    }
}

&.point1 {
    &::after {
        width: 34.5rem;
        height: 18.875rem;
        left: -50rem;
        top: 12.375rem;
        
        background: linear-gradient(208.41deg, #06FFF0 17.55%, #03C3FF 43.06%);
        filter: blur(31.25rem);
        transform: matrix(-0.92, -0.39, -0.38, 0.92, 0, 0);
        opacity: 0.2;
        
        ${[theme.breakpoints.down("lg")]} {
            left: -34rem;
        }
        
        ${[theme.breakpoints.down("sm")]} {
            left: -34rem;
        }
    }
}

&.point2 {

    &::before {
        width: 37.5rem;
        height: 21.875rem;
        left: -50rem;
        top: 14.375rem;
        
        background: linear-gradient(160.41deg, #7626CA 13.12%, #8628E2 66.93%);
        filter: blur(31.25rem);
        transform: matrix(-1, -0.04, -0.04, 1, 0, 0);
        opacity: 0.2;

        ${[theme.breakpoints.down("lg")]} {
            left: -34rem;
        }
        
        ${[theme.breakpoints.down("sm")]} {
            left: -34rem;
        }
    }

    &::after {
        width: 399.18px;
        height: 230.85px;
        right: -45rem;
        top: 110px;

        background: linear-gradient(160.41deg, #016AE1 13.12%, #33CAFF 66.93%);
        filter: blur(500px);
        transform: matrix(-1, -0.04, -0.04, 1, 0, 0);
        opacity: 0.5;

        ${[theme.breakpoints.down("lg")]} {
            right: -22rem;
        }
        
        ${[theme.breakpoints.down("sm")]} {
            right: -22rem;
        }
    }
}

&.point3 {
    &::before {
        width: 37.5rem;
        height: 21.875rem;
        left: -50rem;
        top: 0;
        bottom: 0;
        margin: auto;
        opacity: 0.3;
        
        background: linear-gradient(160.41deg, #8208E2 13.12%, #8D43EC 66.93%);
        filter: blur(500px);
        transform: matrix(-1, -0.04, -0.04, 1, 0, 0);

        ${[theme.breakpoints.down("lg")]} {
            right: -31rem;
        }
        
        ${[theme.breakpoints.down("sm")]} {
            left: -31rem;
            opacity: 1;
        }
    }
    &::after {
        width: 399.18px;
        height: 230.85px;
        right: -45rem;
        top: 16rem;

        background: linear-gradient(160.41deg, #016AE1 13.12%, #33CAFF 66.93%);
        filter: blur(500px);
        transform: matrix(-1, -0.04, -0.04, 1, 0, 0);
        opacity: 0.3;

        ${[theme.breakpoints.down("lg")]} {
            right: -22rem;
        }
        
        ${[theme.breakpoints.down("sm")]} {
            right: -22rem;
        }
    }
}
`);

export const GridNumbers = styled('div')(({ theme }) => `

    margin-top: 3rem;
    
    ${[theme.breakpoints.down("md")]} {
        margin-top: 2.375rem;
    }
    
    & > .MuiGrid-container {
        & > .grid-item {
            position: relative;

            &::after {
                display: block;
                content: '';
                position: absolute;
                height: 12.75rem;
                width: .125rem;
                background-image: url(${Divider});
                top: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                
                ${[theme.breakpoints.down("md")]} {
                    background-image: url(${DividerSmall});
                    height: 6.1875rem;
                }
            }

            & > .our-number {
                
                ${[theme.breakpoints.up("sm")]} {
                    padding: 0 30px;
                }
            }

            &:nth-child(4) {
                &::after {
                    display: none;
                }
            }

            &:nth-child(2),
            &:nth-child(4) {
                &::after {
                    ${[theme.breakpoints.down("sm")]} {
                        display: none;
                    }
                }
            }

            &:nth-child(1),
            &:nth-child(2) {
                margin-bottom: 30px;
            }
            
            &:nth-child(1),
            &:nth-child(3) {
                & > .our-number {
                    ${[theme.breakpoints.down("sm")]} {
                        padding-right: 15px;
                    }
                }
            }
            
            &:nth-child(2),
            &:nth-child(4) {
                & > .our-number {
                    ${[theme.breakpoints.down("sm")]} {
                        padding-left: 15px;
                    }
                }
            }
        }
    }

`);

export const SectionAction = styled('div')(({ theme }) => `
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 70px;
    
    ${[theme.breakpoints.down("md")]} {
        margin-top: 30px;
    }
`);

export const Benefits = styled('div')(({ theme }) => `
    margin-top: 8.6875rem;

    ${[theme.breakpoints.down("md")]} {
        margin-top: 3.75rem;
    }

    ${[theme.breakpoints.down("sm")]} {
        margin-top: 3.25rem;
    }
`);

export const BenefitColumns = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const BenefitColumn = styled('div')(({ theme }) => `
    width: 100%;

    &.column {

        &:first-child {
            & > .block {
                margin-right: 3.125rem;
                
                ${[theme.breakpoints.down("md")]} {
                    margin-right: 1.5625rem;
                }
                
                ${[theme.breakpoints.down("sm")]} {
                    margin-right: .3125rem;
                }
                
                &:first-child {
                    margin-bottom: 7.625rem;
        
                    ${[theme.breakpoints.down("md")]} {
                        margin-bottom: 5.125rem;
                    }

                    ${[theme.breakpoints.down("sm")]} {
                        margin-bottom: 1.25rem;
                    }
                }
            }
        }

        &:last-child {
            & > .block {
                margin-left: 3.125rem;

                ${[theme.breakpoints.down("md")]} {
                    margin-left: 1.5625rem;
                }
                
                ${[theme.breakpoints.down("sm")]} {
                    margin-left: .3125rem;
                }
                
                &:first-child {
                    margin-bottom: 7.625rem;

                    ${[theme.breakpoints.down("md")]} {
                        margin-bottom: 5.125rem;
                    }

                    ${[theme.breakpoints.down("sm")]} {
                        margin-bottom: 1.25rem;
                    }
                }
            }
        }
    }
`);

export const ColumnCenter = styled('div')(({ theme }) => `
    min-width: 16.375rem;
    display: flex;
    align-items: center;
    justify-content: center;
    
    ${[theme.breakpoints.down("md")]} {
        min-width: 10.125rem;
    }
    
    ${[theme.breakpoints.down("sm")]} {
        min-width: 3.3125rem;
        max-width: 3.3125rem;
    }

    & > .svg {
        & > span {
            & > svg {
                height: 100%;
                width: 100%;
            }
        }
    }
`);

export const WrapperGrid = styled('div')(({ theme }) => `
    background-color: #1C1C1C;
    border-radius: 1.875rem;
    margin-top: 4.375rem;
    
    ${[theme.breakpoints.down("md")]} {
        margin-top: 3.125rem;
    }
    
    ${[theme.breakpoints.down("sm")]} {
        margin-top: 1.875rem;
        background-color: transparent;
    }

    & > .MuiGrid-container {
        & > .grid-item {
            position: relative;
            ${[theme.breakpoints.down("sm")]} {
                background-color: #1C1C1C;
                margin-bottom: .875rem;
                border-radius: .75rem;
            }

                &::after {
                display: block;
                content: '';
                position: absolute;
                height: 12.75rem;
                width: .125rem;
                background-image: url(${DividerPurple});
                top: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                
                ${[theme.breakpoints.down("sm")]} {
                    display: none;
                }
            }

            &:last-child {
                &::after {
                    display: none;
                }
            }
        }
    }
`);

export const BlockGrid = styled('div')(({ theme }) => `
    padding: 30px;
    
    ${[theme.breakpoints.down("sm")]} {
        padding: 10px 15px 30px 15px;
    }
`);

export const Head = styled('div')(({ theme }) => `
    min-height: 186px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    ${[theme.breakpoints.down("sm")]} {
        min-height: 136px;
    }
`);

export const Icon = styled('div')(({ theme }) => `
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    & > img {
        width: auto;
        ${[theme.breakpoints.down("md")]} {
            height: 123px;
        }

        ${[theme.breakpoints.down("sm")]} {
            height: 93px;
        }
    }
`);

export const Title = styled('div')(({ theme }) => `
    font-size: 1.125rem;
    font-weight: ${theme.typography.fontWeightMedium};
    color: ${theme.palette.common.white};
    text-align: center;
    
    ${[theme.breakpoints.down("md")]} {
        font-size: .875rem;
    }
`);

export const Customers = styled('div')(({ theme }) => `
    height: 288px;
    margin-top: 6.25rem;
    
    ${[theme.breakpoints.down("md")]} {
        margin-top: 1.875rem;
        height: 127px;
    }
`);

export const CustomersImage = styled('div')`
    height: 225px;
    background-size: cover !important;
    ${props => [props.theme.breakpoints.down("md")]} {
        background-size: contain !important;
        height: 100%;
    }
`;

export const GetInTouch = styled('div')(({ theme }) => `
    max-width: 590px;
    width: 100%;
    margin: 0 auto;
`);

export const ButtonAction = styled('div')(({ theme }) => `
    margin-top: -36px;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
`);

export const GetInTouchBox = styled('div')(({ theme }) => `
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
    padding: 2.5rem;
    color: ${theme.palette.common.white};
    background: #000;
    background-clip: padding-box; /* !importanté */
    border: solid .125rem transparent; /* !importanté */
    border-radius: 2.375rem;
    text-align: center;
    font-weight: ${theme.typography.fontWeightRegular};
    font-size: 28px;
    padding: 55px 30px 62px 30px;

    ${[theme.breakpoints.down("md")]} {
        padding: 36px 15px 46px 15px;
        border-radius: .9375rem;
        font-size: 14px;
    }
    
    ${[theme.breakpoints.down("sm")]} {
        border: solid .0625rem transparent; /* !importanté */
        border-radius: .5625rem;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        z-index: -1;
        margin: -.125rem; /* !importanté */
        border-radius: inherit; /* !importanté */
        background: linear-gradient(89.67deg, #03C3FF -5.83%, #BD00FF 51.64%, #E10000 111.55%);  ;
    }
`);
