import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LngDetector from 'i18next-browser-languagedetector';

import { en, pt, es } from '@lang';

const resources = {
    en: { translation: en },
    'pt-BR': { translation: pt },
    'es': { translation: es },
}

i18n
    .use(LngDetector)
    .use(initReactI18next)
    .init({
        resources,
        //lng: "pt_BR",
        fallbackLng: 'pt-BR',
        keySeparator: '.', // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    })


export { i18n };