import React from 'react';
import { Header, Footer } from '@components/patterns';
import { Outlet } from 'react-router-dom';

import { Main } from './styles';

import "jquery";
import "jquery-sticky";

const LayoutDefault: React.FC = () => {

    React.useEffect(() => {
        $("#sticker").sticky({ topSpacing: 0 });
    }, []);

    return (
        <Main>
            <Header id='sticker' />
            <Outlet />
            <Footer />
        </Main>
    )
}

export { LayoutDefault };