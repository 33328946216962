import { styled, Theme } from "@mui/material";

interface MainProps {
    borderColor: string;
   color: string;
    theme: Theme;
}

export const Main = styled('div')(({ borderColor, color, theme }: MainProps) => `
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
    padding: 2.5rem;

    background: #000;
    background-clip: padding-box; /* !importanté */
    border: solid .125rem transparent; /* !importanté */
    border-radius: 2.375rem;
    text-align: left;

    ${[theme.breakpoints.down("md")]} {
        padding: 1.25rem;
        border-radius: .9375rem;
    }
    
    ${[theme.breakpoints.down("sm")]} {
        padding: .5rem;
        border: solid .0625rem transparent; /* !importanté */
        border-radius: .5625rem;
    }

    &:before {
    content: '';
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: -1;
    margin: -.125rem; /* !importanté */
    border-radius: inherit; /* !importanté */
    background: ${borderColor};

        ${[theme.breakpoints.down("sm")]} {
            margin: -.0625rem; /* !importanté */
        }
    }

    & > .bottom {

        & > .tag {
            color: ${color} !important;
        }
    }
`);

export const Head = styled('div')``;

export const Icon = styled('div')(({ theme }) => `
    & > .svg {
        & > span {
            & > svg {
                height: auto;
                width: auto;
                ${[theme.breakpoints.down("md")]} {
                    height: 54px;
                }
                
                ${[theme.breakpoints.down("sm")]} {
                    height: 30px;
                }
            }
        }
    }
`);

export const Bottom = styled('div')(({ theme }) => `
    margin-top: 1.875rem;

    ${[theme.breakpoints.down("md")]} {
        margin-top: .9375rem;
    }
    
    ${[theme.breakpoints.down("sm")]} {
        margin-top: .5rem;
    }

`);

export const Title = styled('div')(({ theme }) => `
    font-size: 32px;
    font-weight: ${theme.typography.fontWeightLight};
    color: ${theme.palette.common.white};
    
    ${[theme.breakpoints.down("md")]} {
        font-size: 18px;
    }
    
    ${[theme.breakpoints.down("sm")]} {
        font-size: 11px;
    }
`);

export const Tag = styled('div')(({ theme }) => `
    font-size: 32px;
    font-weight: ${theme.typography.fontWeightRegular};

    ${[theme.breakpoints.down("md")]} {
        font-size: 18px;
    }
    
    ${[theme.breakpoints.down("sm")]} {
        font-size: 12px;
    }
    
`);
