import React from 'react';
import { ReactSVG } from 'react-svg';
import { Button } from '@components/design-system';
import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';

import {
  Main,
  Section,
  ImageManagement,
  Image,
  GetInTouch,
  GetInTouchBox,
  GridWrapperCols,
  ImageOverlay,
  WrapperButton,
} from './styles';

import OctOverlay from '@img/octogono-hd-overlay.svg';
import ManagementImg from '@img/management.png';

import { CheckMark } from '@icons';

import { tawkToChat } from '@utils';
import { i18n } from '@hooks';

const EndToEnd: React.FC = () => {

  const theme = useTheme();
  const mobile = useMediaQuery('(min-width: 600px)');
  const { t } = i18n;

  const texts = [
    { id: 'b7e42bd3', title: t('EndToEnd.Point1.Block_1.Title') as string },
    { id: '578159a6', title: t('EndToEnd.Point1.Block_2.Title') as string },
    { id: 'a5df1f6a', title: t('EndToEnd.Point1.Block_3.Title') as string },
  ]

  return (
    <Main>
      <Section className="point2">
        <Container maxWidth="lg" className='container'>

          <ImageOverlay>
            <Image src={OctOverlay} alt="Overlay" />
          </ImageOverlay>
          <Typography
            variant="body1"
            component="h1"
            sx={{
              fontSize: '40px',
              color: theme.palette.common.white,
              fontWeight: theme.typography.fontWeightRegular,
              textAlign: 'center',
              width: '100%',
              marginTop: theme.typography.pxToRem(-50),
              [theme.breakpoints.down("md")]: {
                fontSize: '28px',
              },
              [theme.breakpoints.down("sm")]: {
                fontSize: '16px',
              }
            }}
          >
            {t('EndToEnd.Point1.Headline') as string}

          </Typography>

          <GridWrapperCols>
            <Grid container spacing={!mobile ? 2 : 6} alignItems={'center'}>

              <Grid item xs={12} sm={7}>
                <ImageManagement>
                  <Image src={ManagementImg} alt="Gestão de Objetivos" />
                </ImageManagement>
              </Grid>

              <Grid item xs={12} sm={5}>
                <GetInTouch>
                  <GetInTouchBox>

                    {texts.map(({ id, title }) => (
                      <Typography
                        key={`${title}-${id}`}
                        variant="body1"
                        component="h1"
                        sx={{
                          fontSize: '30px',
                          color: theme.palette.common.white,
                          fontWeight: theme.typography.fontWeightRegular,
                          textAlign: 'left',
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          paddingBottom: theme.typography.pxToRem(30),
                          '& > .svg': {
                            lineHeight: 0,
                            marginRight: theme.typography.pxToRem(10),
                          },
                          '& > .svg > div > svg ': {
                            height: 24,
                            [theme.breakpoints.down("sm")]: {
                              height: 14,
                            }
                          },
                          '& > .svg > div > svg > path': {
                            fill: `${theme.palette.common.white} !important`,
                          },
                          [theme.breakpoints.down("md")]: {
                            fontSize: '20px',
                          },
                          [theme.breakpoints.down("sm")]: {
                            paddingBottom: theme.typography.pxToRem(15),
                            fontSize: '14px',
                          }
                        }}
                      >
                        <ReactSVG src={CheckMark} className='svg' />
                        {title}
                      </Typography>

                    ))}

                  </GetInTouchBox>
                </GetInTouch>
              </Grid>

            </Grid>

            <WrapperButton>
              <Button
                backgroundColor='#A74DFF'
                color='white'
                buttonBorderColor='#A74DFF'
                buttonBorderHoverColor='#A74DFF'
                buttonSize='medium'
                onClick={tawkToChat}
              >
                {t('EndToEnd.Point1.ButtonText') as string}
              </Button>
            </WrapperButton>
          </GridWrapperCols>
        </Container>
      </Section>

    </Main>
  )
}

export { EndToEnd };