import React from 'react';
import { ReactSVG } from 'react-svg';
import { Typography, useMediaQuery } from '@mui/material';

import { routes } from '@utils';

import {
  HeroWrapper,
  ImageLogo,
  MenuList,
  Button,
  Columns,
  Column,
  ActionColumn,
  SocialList,
  SocialLink,
  MuiContainer,
  Dropdown,
  Open,
  DropdownContent,
  ButtonIconOpen,
  Icons,
  ImageFlag,
  LangButton,
  Langs,
  SelectLang,
} from './styles';

// * Images
import Logo from '@img/commarket-logo.png';
import { Link } from 'react-router-dom';
import MenuIcon from '@img/icons/menu.svg';

import { Linkedin, Twitter, Instagram, Facebook, FlagBrazil, FlagUs, FlagSpain, Lang, ChevronDown } from '@icons';

import "jquery";
import "jquery-sticky";
import { useTheme } from '@mui/material';
import { i18n, useTranslate } from '@hooks';
import { Languages } from '@types';
const { t } = i18n;
const listMenu = [
  { id: '44a45fa3', title: t('Nav.Plataformas') as string, href: routes.platform },
  { id: '70291773', title: 'On-demand', href: routes.onDemand },
  { id: '2a9fd79d', title: 'End-to-end', href: routes.endToEnd },
  { id: '5e55e4fd', title: t('Nav.QuemSomos') as string, href: routes.about },
]

interface HeaderProps {
  id?: string;
  className?: string;
}

const social = [
  {
    id: 'a5df1f6a',
    title: 'Linkedin',
    link: 'https://www.linkedin.com/company/commarket-tecnologia-e-inform-tica/',
    icon: Linkedin
  },
  {
    id: 'b7e42bd3',
    title: 'Twitter',
    link: 'https://twitter.com/commarket_tecno',
    icon: Twitter
  },
  {
    id: 'f82927e1',
    title: 'Facebook',
    link: 'https://www.facebook.com/commarket',
    icon: Facebook
  },
  {
    id: '578159a6',
    title: 'Instagram',
    link: 'https://www.instagram.com/commarket.loyalty/',
    icon: Instagram
  }
];



const Header: React.FC<HeaderProps> = ({ id, className }: HeaderProps) => {

  const ref = React.useRef<HTMLDivElement>(null);
  const theme = useTheme();

  const matches = useMediaQuery('(min-width: 600px)');
  const matchesMenu = useMediaQuery('(min-width: 992px)');

  const [dropdown, setDropdown] = React.useState<boolean>(false);

  const [showLangs, setShowLangs] = React.useState(false);

  const { changeLang } = useTranslate();

  const newMenu = [...listMenu];
  const dropdownMenu = [newMenu[newMenu.length - 1], newMenu[newMenu.length - 2]];

  React.useEffect(() => {
    $(`#sticker`).sticky({ topSpacing: 0 });

    $('#sticker').on('sticky-start', function () {

      this.style.backgroundColor = theme.palette.common.black
    });

    $('#sticker').on('sticky-end', function () {
      this.style.backgroundColor = 'transparent'
    });
  }, [theme]);

  const langs = [
    {
      id: '72e63f07',
      title: 'Português',
      icon: FlagBrazil,
      action: () => {
        setShowLangs(prev => prev = false);
        changeLang(Languages['pt-BR']);
        document.location.reload();
      },
    },
    {
      id: '019ef84e',
      title: 'English',
      icon: FlagUs,
      action: () => {
        setShowLangs(prev => prev = false);
        changeLang(Languages.en);
        document.location.reload();
      },
    },
    {
      id: '67159dd1',
      title: 'Español',
      icon: FlagSpain,
      action: () => {
        setShowLangs(prev => prev = false);
        changeLang(Languages.es);
        document.location.reload();
      },
    },
  ];

  return (
    <HeroWrapper ref={ref} id={'sticker'} className={className ? `${className} header` : 'header'}>

      <MuiContainer maxWidth="lg">

        <Columns>
          <Column className='column'>
            <ImageLogo>
              <Link to="/">
                <img src={Logo} alt='COMMARKET logo' />
              </Link>
            </ImageLogo>

            {!matches && (
              <ButtonIconOpen>
                <Link to="/menu">
                  <ReactSVG src={MenuIcon} wrapper="span" className='svg' />
                </Link>
              </ButtonIconOpen>
            )}
          </Column>

          {matches && (
            <Column className='column'>
              <MenuList>

                {listMenu.map(({ id, title, href }) => {

                  return (
                    <Link key={`${id}`} to={href}> {title} </Link>
                  )
                }).splice(0, matches ? matchesMenu ? listMenu.length : 2 : 4)}

                {matches && !matchesMenu && (
                  <Dropdown>
                    <Open
                      onClick={() => setDropdown(prev => !prev)}
                    >
                      <span></span>
                      <span></span>
                      <span></span>
                    </Open>
                    {dropdown && (
                      <DropdownContent>
                        {dropdownMenu.map(({ id, href, title }) => (
                          <Link key={`${id}`} to={href}> {title} </Link>
                        ))}
                      </DropdownContent>
                    )}
                  </Dropdown>
                )}

              </MenuList>

              <ActionColumn>

                <Button>
                  <Link to="/contate-nos">
                    {t('Nav.ButtonText') as string}
                  </Link>
                </Button>

                <SocialList>
                  {social.map(({ id, title, link, icon }) => (

                    <SocialLink
                      key={`${title}-${id}`}
                      title={title}
                      href={link}
                      rel="noreferrer"
                      target="_blank">
                      <ReactSVG src={icon} wrapper="span" className='svg' />
                    </SocialLink>

                  ))}
                </SocialList>

                <SelectLang>
                  <Icons onClick={() => setShowLangs(prev => !prev)} className={`${showLangs ? 'active': ''}`}>
                    <ReactSVG src={Lang} wrapper="span" className='svg' />
                    <ReactSVG style={{ transform: `rotate(${showLangs ? '180deg' : '0deg'})` }} src={ChevronDown} wrapper="span" className='svg' />
                  </Icons>
                  {showLangs && (
                    <Langs>
                      {langs.map(({ id, title, icon, action }, i) => (
                        <LangButton key={`${id}`} onClick={action} className={`flag-${i + 1}`}>
                          <ImageFlag>
                            <img src={icon} alt={title} />
                          </ImageFlag>
                          {/* <ReactSVG src={icon} wrapper="span" className='svg' /> */}
                          <Typography
                            variant="subtitle1"
                            component="h1"
                            gutterBottom
                            sx={{
                              fontSize: '.5rem',
                              color: theme.palette.common.white
                            }}
                          >
                            {title}
                          </Typography>
                        </LangButton>
                      ))}
                    </Langs>
                  )}
                </SelectLang>

              </ActionColumn>
            </Column>
          )}

        </Columns>

      </MuiContainer>

    </HeroWrapper>
  )
}

export default Header;