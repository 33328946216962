import React from 'react';
import { Typography, useMediaQuery, useTheme, TypographyProps } from '@mui/material';

interface HeadlineProps extends TypographyProps {
    children: React.ReactNode;
    textColor: string;
    textAlign?: "right" | "left" | "inherit" | "center" | "justify";
    highlightColor?: string;
    highlightTextColor?: string;
    size: "small" | "medium";
    fontWeight: "regular" | "bold";
}

interface fontSizesProps {
    small: string;
    medium: string;
}

interface fontWeightsProps {
    regular: number;
    bold: number;
}

const Headline: React.FC<HeadlineProps> = (props: HeadlineProps) => {

    const { children, size, fontWeight, highlightColor, highlightTextColor, textColor, textAlign } = props;

    const theme = useTheme();
    const mobile = useMediaQuery('(min-width: 600px)');

    const rem = (px: number) => theme.typography.pxToRem(px);

    const fontSizes: fontSizesProps = {
        small: rem(16),
        medium: rem(40),
    }

    const fontWeights: fontWeightsProps = {
        regular: 400,
        bold: 700,
    }

    return (
        <Typography
            variant="body1"
            align={textAlign}
            gutterBottom
            sx={{
                fontSize: fontSizes[size],
                fontWeight: fontWeights[fontWeight],
                color: textColor,
                marginBottom: theme.typography.pxToRem(!mobile ? 5 : 20),
                '& > span': {
                    backgroundColor: highlightColor,
                    color: highlightTextColor,
                    display: 'inline-block',
                    padding: '0 .25rem'

                }
            }}
            {...props}
        >
            {children}
        </Typography>
    );
}

export { Headline };