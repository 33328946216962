import { library } from '@fortawesome/fontawesome-svg-core'

import { faBars, faTimes } from "@fortawesome/pro-light-svg-icons";

import { faSearch, faChevronDown, faChevronUp, faTimes as faTimesReg, faLongArrowAltLeft, faAngleLeft } from "@fortawesome/pro-regular-svg-icons";

import { faCaretDown, faCaretUp } from "@fortawesome/pro-solid-svg-icons";

import {
    faFacebookF,
    faInstagram,
    faTwitter,
    faYoutube,
} from "@fortawesome/free-brands-svg-icons";

library.add(
    faSearch,
    faFacebookF,
    faInstagram,
    faTwitter,
    faYoutube,
    faCaretDown,
    faCaretUp,
    faBars,
    faTimes,
    faTimesReg,
    faChevronDown,
    faChevronUp,
    faLongArrowAltLeft,
    faAngleLeft
);