import React from 'react';
import { BlockBenefit, Button } from '@components/design-system';
import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';

import {
  FrequencyWhite,
  Fidelity,
  Indication,
  Incentive,
  Promotions,
  Coalition,
  Store,
  AccountGear,
  Shipping,
  SecurityAdmin,
  Message,
  PaymentCheck
} from '@icons';

import {
  Main,
  Section,
  WrapperButton,
  WrapperGridBlocks,
  Image,
  GetInTouch,
  GetInTouchBox,
  WrapperImage,
  OurStory,
  OurStoryWrapper,
  Overlay,
} from './styles';

import CmkGo from '@img/cmk-go.png';
import CmkCampain from '@img/cmk-campain.png';
import OutStoryTop from '@img/cmk-go-top.svg';
import OutStoryBottom from '@img/cmk-go-bottom.svg';
import OutStoryCampainTop from '@img/cmk-campain-top.svg';
import OutStoryCampainBottom from '@img/cmk-campain-bottom.svg';
import OutStoryMarketplaceTop from '@img/marketplace-top.svg';
import OutStoryMarketplaceBottom from '@img/marketplace-bottom.svg';

import { tawkToChat } from '@utils';

import { i18n } from '@hooks';

const Platform: React.FC = () => {

  const theme = useTheme();
  const mobile = useMediaQuery('(min-width: 600px)');
  const { t } = i18n;

  const programs = [
    {
      id: '28cde73a',
      title: t('Platforms.Point2.Block1.Title') as string,
      substile: t('Platforms.Point2.Block1.Text') as string,
      icon: FrequencyWhite
    },
    {
      id: '636bd4d3',
      title: t('Platforms.Point2.Block2.Title') as string,
      substile: t('Platforms.Point2.Block2.Text') as string,
      icon: Fidelity
    },
    {
      id: 'a93f96ed',
      title: t('Platforms.Point2.Block3.Title') as string,
      substile: t('Platforms.Point2.Block3.Text') as string,
      icon: Indication
    },
    {
      id: '8d3b8cab',
      title: t('Platforms.Point2.Block4.Title') as string,
      substile: t('Platforms.Point2.Block4.Text') as string,
      icon: Incentive
    },
    {
      id: '57f9465f',
      title: t('Platforms.Point2.Block5.Title') as string,
      substile: t('Platforms.Point2.Block5.Text') as string,
      icon: Promotions
    },
    {
      id: '908a9cda',
      title: t('Platforms.Point2.Block6.Title') as string,
      substile: t('Platforms.Point2.Block6.Text') as string,
      icon: Coalition
    },
  ];

  const solutions = [
    { id: 'ade41c49', title: t('Platforms.Point5.Block1.Title') as string, icon: Store },
    { id: '59a7cb02', title: t('Platforms.Point5.Block2.Title') as string, icon: AccountGear },
    { id: '9b8ec546', title: t('Platforms.Point5.Block3.Title') as string, icon: Shipping },
    { id: 'b3495949', title: t('Platforms.Point5.Block4.Title') as string, icon: SecurityAdmin },
    { id: '46e49fba', title: t('Platforms.Point5.Block5.Title') as string, icon: Message },
    { id: '95d588ed', title: t('Platforms.Point5.Block6.Title') as string, icon: PaymentCheck },
  ];
  
  return (
    <Main>
      <Section className='point2'>
        <Container maxWidth="lg">
          <WrapperGridBlocks>

            <Grid container spacing={!mobile ? 2 : 6}>
              {programs.map(({ id, title, substile, icon }, i) => (
                <Grid key={`${id}`} item xs={6} sm={4} md={4} lg={4} xl={4} className={`${i + 1 !== 1 ? 'grid-item programs icon' : 'grid-item programs'}`}>
                  <BlockBenefit
                    title={title}
                    tag={substile}
                    icon={icon}
                    borderColor="linear-gradient(89.67deg, #8628E2 -5.83%, #BA79FF 51.64%, #8628E2 111.55%);  "
                    color='#fff'
                  />
                </Grid>
              ))}
            </Grid>

            <WrapperButton>
              <Button
                backgroundColor='#000'
                color='white'
                buttonBorderColor='linear-gradient(89.67deg, #BC2DFF -5.83%, #E004CA 51.64%, #BC2DFF 111.55%);  '
                buttonBorderHoverColor='linear-gradient(89.67deg, #BC2DFF -5.83%, #E004CA 51.64%, #BC2DFF 111.55%);  '
                buttonSize='medium'
                onClick={tawkToChat}
              >
                {t('Platforms.Point2.ButtonText') as string}
              </Button>
            </WrapperButton>
          </WrapperGridBlocks>
        </Container>
      </Section>

      <Section className='point3'>
        <Container maxWidth="lg">
          <Grid container spacing={2}>

            <Grid item xs={12} sm={5}>
              <Grid container spacing={2} alignItems={'center'} sx={{ maxWidth: '380px' }}>

                <Grid item xs={5} sm={12}>
                  <GetInTouch>
                    <GetInTouchBox>
                      <WrapperImage>
                        <Image src={CmkGo} alt="Commarket Gestão de OKRs" />
                      </WrapperImage>
                    </GetInTouchBox>
                  </GetInTouch>
                </Grid>
                <Grid item xs={7} sm={12}>
                  <OurStoryWrapper>
                    <Overlay className='top'>
                      <img src={OutStoryTop} alt="overlay footer" />
                    </Overlay>
                    <OurStory>

                      <Typography
                        variant="body1"
                        component="div"
                        align='center'
                        sx={{
                          fontSize: '24px',
                          fontWeight: theme.typography.fontWeightBold,
                          color: theme.palette.common.black,
                          [theme.breakpoints.down("md")]: {
                            alignItems: 'center',
                            flexDirection: 'column',
                            fontSize: '16px',
                          },
                          [theme.breakpoints.down("sm")]: {
                            fontSize: '12px',
                          }
                        }}
                      >
                        {t('Platforms.Point3.Featured.Title') as string}
                      </Typography>

                      <Typography
                        variant="body1"
                        component="div"
                        align='center'
                        gutterBottom
                        sx={{
                          fontSize: '24px',
                          fontWeight: theme.typography.fontWeightRegular,
                          color: theme.palette.common.black,
                          [theme.breakpoints.down("md")]: {
                            alignItems: 'center',
                            flexDirection: 'column',
                            fontSize: '16px',
                          },
                          [theme.breakpoints.down("sm")]: {
                            fontSize: '12px',
                          }
                        }}
                      >
                        {t('Platforms.Point3.Featured.Text') as string}
                      </Typography>
                    </OurStory>
                    <Overlay className='bottom'>
                      <img src={OutStoryBottom} alt="overlay footer" />
                    </Overlay>
                  </OurStoryWrapper>
                </Grid>
                <Grid item xs={12} sm={12}>

                  <WrapperButton className='in-point3 only-d'>
                    <Button
                      backgroundColor='#000'
                      color='white'
                      buttonBorderColor=' linear-gradient(89.67deg, #4CD9ED -5.83%, #0095E9 51.64%, #4CD9ED 111.55%)'
                      buttonBorderHoverColor=' linear-gradient(89.67deg, #4CD9ED -5.83%, #0095E9 51.64%, #4CD9ED 111.55%)'
                      buttonSize='medium'
                      onClick={tawkToChat}
                    >
                      {t('Platforms.Point3.Featured.ButtonText') as string}
                    </Button>
                  </WrapperButton>
                </Grid>

              </Grid>
            </Grid>

            <Grid item xs={12} sm={7} >
              <Typography
                variant="body1"
                component="div"
                align='left'
                gutterBottom
                sx={{
                  fontSize: '40px',
                  fontWeight: theme.typography.fontWeightMedium,
                  color: '#33CAFF',
                  marginBottom: theme.typography.pxToRem(20),
                  [theme.breakpoints.down("md")]: {
                    fontSize: '32px',
                  },
                  [theme.breakpoints.down("sm")]: {
                    marginBottom: theme.typography.pxToRem(0),
                    fontSize: '18px',
                  },
                }}
              >
                {t('Platforms.Point3.Headline') as string}
              </Typography>
              <Typography
                variant="body1"
                component="div"
                align='left'
                gutterBottom
                sx={{
                  fontSize: '40px',
                  fontWeight: theme.typography.fontWeightMedium,
                  color: theme.palette.common.white,
                  marginBottom: theme.typography.pxToRem(20),
                  [theme.breakpoints.down("md")]: {
                    fontSize: '32px',
                  },
                  [theme.breakpoints.down("sm")]: {
                    fontSize: '20px',
                  },
                }}
              >
                {t('Platforms.Point3.Title') as string}
              </Typography>
              <Typography
                variant="body1"
                component="h1"
                align='left'
                sx={{
                  fontSize: '18px',
                  color: theme.palette.common.white,
                  fontWeight: theme.typography.fontWeightRegular,
                  marginBottom: theme.typography.pxToRem(20),
                  [theme.breakpoints.down("md")]: {
                    marginBottom: theme.typography.pxToRem(14),
                    fontSize: '16px',
                  },
                  [theme.breakpoints.down("sm")]: {
                    marginBottom: theme.typography.pxToRem(14),
                    fontSize: '12px',
                  },
                }}
              >
                {t('Platforms.Point3.Block1.Title') as string}
                <br />
                <br />
                {t('Platforms.Point3.Block2.Title') as string}
                <br />
                <br />
                {t('Platforms.Point3.Block3.Title') as string}
                <br />
                <br />
                {t('Platforms.Point3.Block4.Title') as string}
                <br />
                <br />
                {t('Platforms.Point3.Block5.Title') as string}
                <br />
                <br />
                {t('Platforms.Point3.Block6.Title') as string}
              </Typography>

            </Grid>

          </Grid>

          <WrapperButton className='in-point3 only-m'>
            <Button
              backgroundColor='#000'
              color='white'
              buttonBorderColor=' linear-gradient(89.67deg, #4CD9ED -5.83%, #0095E9 51.64%, #4CD9ED 111.55%)'
              buttonBorderHoverColor=' linear-gradient(89.67deg, #4CD9ED -5.83%, #0095E9 51.64%, #4CD9ED 111.55%)'
              buttonSize='medium'
              onClick={tawkToChat}
            >
              {t('Platforms.Point3.Featured.ButtonText') as string}
            </Button>
          </WrapperButton>
        </Container>
      </Section>

      <Section className='point4'>
        <Container maxWidth="lg">
          <Grid container spacing={2} sx={{
            [theme.breakpoints.down("sm")]: {
              flexDirection: 'column-reverse'
            }
          }}>

            <Grid item xs={12} sm={7} >
              <Typography
                variant="body1"
                component="div"
                align='left'
                gutterBottom
                sx={{
                  fontSize: '40px',
                  fontWeight: theme.typography.fontWeightMedium,
                  color: '#EC43E5',
                  marginBottom: theme.typography.pxToRem(20),
                  [theme.breakpoints.down("md")]: {
                    fontSize: '32px',
                  },
                  [theme.breakpoints.down("sm")]: {
                    marginBottom: theme.typography.pxToRem(0),
                    fontSize: '18px',
                  },
                }}
              >
                {t('Platforms.Point4.Headline') as string}
              </Typography>
              <Typography
                variant="body1"
                component="div"
                align='left'
                gutterBottom
                sx={{
                  fontSize: '40px',
                  fontWeight: theme.typography.fontWeightMedium,
                  color: theme.palette.common.white,
                  marginBottom: theme.typography.pxToRem(20),
                  [theme.breakpoints.down("md")]: {
                    fontSize: '32px',
                  },
                  [theme.breakpoints.down("sm")]: {
                    fontSize: '20px',
                  },
                }}
              >
                {t('Platforms.Point4.Title') as string}
              </Typography>
              <Typography
                variant="body1"
                component="h1"
                align='left'
                sx={{
                  fontSize: '18px',
                  color: theme.palette.common.white,
                  fontWeight: theme.typography.fontWeightRegular,
                  marginBottom: theme.typography.pxToRem(20),
                  [theme.breakpoints.down("md")]: {
                    marginBottom: theme.typography.pxToRem(14),
                    fontSize: '16px',
                  },
                  [theme.breakpoints.down("sm")]: {
                    marginBottom: theme.typography.pxToRem(14),
                    fontSize: '12px',
                  },
                }}
              >
                {t('Platforms.Point4.Block1.Title') as string}
                <br />
                <br />
                {t('Platforms.Point4.Block2.Title') as string}
                <br />
                <br />
                {t('Platforms.Point4.Block3.Title') as string}
                <br />
                <br />
                {t('Platforms.Point4.Block4.Title') as string}
                <br />
                <br />
                {t('Platforms.Point4.Block5.Title') as string}
              </Typography>

            </Grid>

            <Grid item xs={12} sm={5}>
              <Grid container spacing={2} alignItems={'center'} sx={{
                maxWidth: '380px',
                [theme.breakpoints.up("sm")]: {
                  margin: 'auto',
                  marginRight: 0,
                },

              }}>

                <Grid item xs={5} sm={12}>
                  <GetInTouch>
                    <GetInTouchBox>
                      <WrapperImage>
                        <Image src={CmkCampain} alt="COMMARKET Go" />
                      </WrapperImage>
                    </GetInTouchBox>
                  </GetInTouch>
                </Grid>
                <Grid item xs={7} sm={12}>
                  <OurStoryWrapper className='campain-wrapper'>
                    <Overlay className='top'>
                      <img src={OutStoryCampainTop} alt="overlay footer" />
                    </Overlay>
                    <OurStory className='campain'>

                      <Typography
                        variant="body1"
                        component="div"
                        align='center'
                        gutterBottom
                        sx={{
                          fontSize: '24px',
                          fontWeight: theme.typography.fontWeightRegular,
                          color: theme.palette.common.black,
                          [theme.breakpoints.down("md")]: {
                            alignItems: 'center',
                            flexDirection: 'column',
                            fontSize: '16px',
                          },
                          [theme.breakpoints.down("sm")]: {
                            fontSize: '12px',
                          }
                        }}
                      >
                        {t('Platforms.Point4.Featured.TitleStart') as string}
                        {" "}
                        <strong>
                        {t('Platforms.Point4.Featured.Tag') as string}
                        </strong>
                        {" "}
                        {t('Platforms.Point4.Featured.TitleEnd') as string}
                      </Typography>
                    </OurStory>
                    <Overlay className='bottom'>
                      <img src={OutStoryCampainBottom} alt="overlay footer" />
                    </Overlay>
                  </OurStoryWrapper>
                </Grid>
                <Grid item xs={12} sm={12}>

                  <WrapperButton className='in-point3 only-d'>
                    <Button
                      backgroundColor='#000'
                      color='white'
                      buttonBorderColor='linear-gradient(89.67deg, #E100D8 -5.83%, #AD00FF 51.64%, #E100D8 111.55%)'
                      buttonBorderHoverColor='linear-gradient(89.67deg, #E100D8 -5.83%, #AD00FF 51.64%, #E100D8 111.55%)'
                      buttonSize='medium'
                      onClick={tawkToChat}
                    >
                      {t('Platforms.Point4.Featured.ButtonText') as string}
                    </Button>
                  </WrapperButton>
                </Grid>

              </Grid>
            </Grid>

          </Grid>

          <WrapperButton className='in-point3 only-m'>
            <Button
              backgroundColor='#000'
              color='white'
              buttonBorderColor=' linear-gradient(89.67deg, #4CD9ED -5.83%, #0095E9 51.64%, #4CD9ED 111.55%)'
              buttonBorderHoverColor=' linear-gradient(89.67deg, #4CD9ED -5.83%, #0095E9 51.64%, #4CD9ED 111.55%)'
              buttonSize='medium'
              onClick={tawkToChat}
            >
              {t('Platforms.Point3.Featured.ButtonText') as string}
            </Button>
          </WrapperButton>
        </Container>
      </Section>


      <Section className='point5'>
        <Container maxWidth="lg">
          <Grid container spacing={!mobile ? 4 : 6}>

            <Grid item xs={12} sm={6} >
              <Typography
                variant="body1"
                component="div"
                align='left'
                gutterBottom
                sx={{
                  fontSize: '40px',
                  fontWeight: theme.typography.fontWeightMedium,
                  color: '#4AFFD4',
                  marginBottom: theme.typography.pxToRem(20),
                  [theme.breakpoints.down("md")]: {
                    fontSize: '32px',
                  },
                  [theme.breakpoints.down("sm")]: {
                    marginBottom: theme.typography.pxToRem(10),
                    fontSize: '18px',
                  },
                }}
              >
                {t('Platforms.Point5.Headline') as string}
              </Typography>

              <Typography
                variant="body1"
                component="div"
                align='left'
                gutterBottom
                sx={{
                  fontSize: '40px',
                  fontWeight: theme.typography.fontWeightMedium,
                  color: theme.palette.common.white,
                  [theme.breakpoints.down("md")]: {
                    fontSize: '32px',
                  },
                  [theme.breakpoints.down("sm")]: {
                    fontSize: '20px',
                    marginBottom: theme.typography.pxToRem(30),
                  },
                }}
              >
                {t('Platforms.Point5.Title') as string}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={12} >

                  <OurStoryWrapper className='marketplace-wrapper'>
                    <Overlay className='top'>
                      <img src={OutStoryMarketplaceTop} alt="overlay footer" />
                    </Overlay>
                    <OurStory className='marketplace'>

                      <Typography
                        variant="body1"
                        component="div"
                        align='center'
                        gutterBottom
                        sx={{
                          fontSize: '32px',
                          fontWeight: theme.typography.fontWeightRegular,
                          color: theme.palette.common.black,
                          [theme.breakpoints.down("md")]: {
                            alignItems: 'center',
                            flexDirection: 'column',
                            fontSize: '16px',
                          },
                          [theme.breakpoints.down("sm")]: {
                            fontSize: '12px',
                          }
                        }}
                      >
                        {t('Platforms.Point5.Featured.Title') as string}
                      </Typography>
                    </OurStory>
                    <Overlay className='bottom'>
                      <img src={OutStoryMarketplaceBottom} alt="overlay footer" />
                    </Overlay>
                  </OurStoryWrapper>
                </Grid>
                <Grid item xs={6} sm={12} >

                  <Typography
                    variant="body1"
                    component="div"
                    align='left'
                    gutterBottom
                    sx={{
                      fontSize: '22px',
                      fontWeight: theme.typography.fontWeightBold,
                      color: theme.palette.common.white,
                      [theme.breakpoints.down("md")]: {
                        fontSize: '18px',
                      },
                      [theme.breakpoints.down("sm")]: {
                        fontSize: '10px',
                      },
                    }}
                  >
                    {t('Platforms.Point5.BottomHeadline') as string}
                  </Typography>

                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6}>
              <WrapperGridBlocks className='solutions'>
                <Grid container spacing={2} >
                  {solutions.map(({ id, title, icon }) => (
                    <Grid key={`${id}`} item xs={4} sm={6} className='grid-item'>
                      <BlockBenefit
                        title={title}
                        icon={icon}
                        borderColor="#4BFFD4"
                        color='#000'
                      />
                    </Grid>
                  ))}
                </Grid>
              </WrapperGridBlocks>
            </Grid>

          </Grid>

          <Typography
            variant="body1"
            component="h1"
            align='left'
            sx={{
              fontSize: '18px',
              color: theme.palette.common.white,
              fontWeight: theme.typography.fontWeightRegular,
              marginTop: theme.typography.pxToRem(100),
              marginBottom: theme.typography.pxToRem(80),
              [theme.breakpoints.down("md")]: {
                marginTop: theme.typography.pxToRem(80),
                marginBottom: theme.typography.pxToRem(60),
                fontSize: '16px',
              },
              [theme.breakpoints.down("sm")]: {
                marginTop: theme.typography.pxToRem(30),
                marginBottom: theme.typography.pxToRem(40),
                fontSize: '12px',
              },
            }}
          >
            {t('Platforms.Point5.BottomText1') as string}
            <br />
            <br />
            {t('Platforms.Point5.BottomText2') as string}
          </Typography>

          <WrapperButton className='point5-action'>
            <Button
              backgroundColor='#000'
              color='white'
              buttonBorderColor='linear-gradient(89.67deg, #03C3FF -5.83%, #3BFF95 51.64%, #0071BC 111.55%)'
              buttonBorderHoverColor='linear-gradient(89.67deg, #03C3FF -5.83%, #3BFF95 51.64%, #0071BC 111.55%)'
              buttonSize='medium'
              onClick={tawkToChat}
            >
              {t('Platforms.Point5.ButtonText') as string}
            </Button>
          </WrapperButton>

        </Container>
      </Section>

    </Main>
  )
}

export { Platform };