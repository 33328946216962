import { styled } from "@mui/material";

export const Main = styled('div')(({ theme }) => `
    padding: 3.125rem 0;
    overflow: hidden;
    
    ${[theme.breakpoints.down("sm")]} {
        padding: 0 0;
    }
`);

export const Section = styled('div')(({ theme }) => `

    &.point1 {
        margin-top: 40px;
        ${[theme.breakpoints.down("sm")]} {
            margin-top: 20px;
        }
    }

    &.point2 {
        margin-top: 120px;
        
        ${[theme.breakpoints.down("sm")]} {
            margin-top: 60px;
        }
    }

`);

export const Form = styled('form')(({ theme }) => ``);

export const HintFormStatus = styled('span')(({ theme }) => `
    display: block;
    color: ${theme.palette.red};
    font-size: 12px;
    padding: 4px 0 0 15px;
`);

export const WrapperButton = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginTop: theme.typography.pxToRem(40),
    [theme.breakpoints.down("md")]: {
        marginTop: theme.typography.pxToRem(40),
    },
    [theme.breakpoints.down("sm")]: {
        marginTop: theme.typography.pxToRem(20),
    }
}));

export const ContactItem = styled('div')(({ theme }) => `
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
`);

export const Icon = styled('div')(({ theme }) => `
    margin-right: 22px;
    
    ${[theme.breakpoints.down("sm")]} {
        margin-right: 12px;
    }

    & > .svg {
        position: relative;
        & > span {

            & > svg {
                width: auto;
            }
        }
    }

    & > .svg.local {
        top: 4px;

        & > span {

            & > svg {
                height: 30px;
                
                ${[theme.breakpoints.down("sm")]} {
                    height: 19px;
                }
            }
        }
    }

    & > .svg.phone {
        top: 2px;

        & > span {
            & > svg {
                height: 26px;

                ${[theme.breakpoints.down("sm")]} {
                    height: 16px;
                }
            }
        }
    }

    & > .svg.mail {
        top: 4px;
        & > span {

            & > svg {
                height: 22px;

                ${[theme.breakpoints.down("sm")]} {
                    height: 14px;
                }
            }
        }
    }
`);