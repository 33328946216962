import { styled } from "@mui/material";

export const Main = styled('div')(({ theme }) => `
    position: relative;
    overflow: hidden;
    padding-bottom: 6.25rem;

    &::before {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        top: -6rem;
        bottom: 0;

        background: linear-gradient(102.07deg, #FB03F5 4.03%, #AA9CFF 70.5%);
        filter: blur(189.415px);
        height: 570px;
        width: 460px;
        
        ${[theme.breakpoints.down("sm")]} {
            height: 370px;
            width: 160px;
            background: linear-gradient(102.07deg, #FB03F5 4.03%, #AA9CFF 70.5%);
            filter: blur(2.8358rem);

        }
    }
`);

export const Image = styled('img')(({ theme }) => ({
    width: 'auto',
    [theme.breakpoints.up("xl")]: {
        marginLeft: '-40px',
        width: 'auto',
    },
    [theme.breakpoints.down("lg")]: {
        marginLeft: '-40px',
        width: '606px',
    },
    [theme.breakpoints.down("md")]: {
        marginLeft: '40px',
        width: '506px',
    },
    [theme.breakpoints.down("sm")]: {
        marginLeft: '0',
        width: '258px',
        marginBottom: theme.typography.pxToRem(20),
    }
}));

export const WrapperButton = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    marginTop: theme.typography.pxToRem(48),
    [theme.breakpoints.down("sm")]: {
        justifyContent: 'center',
        marginTop: theme.typography.pxToRem(20),
    }
}));

export const WrapperSliderItem = styled('div')(({ theme }) => `
    margin-top: 2.5rem;
    position: relative;
    z-index: 9;
`);