import React from 'react';
import { ReactSVG } from 'react-svg';

import {
    Main,
    Head,
    Icon,
    Bottom,
    Title,
    Tag,
} from './styles';

interface BlockBenefitProps {
    icon: string;
    title: string;
    tag?: string;
    borderColor: string;
    color: string;
}

const BlockBenefit: React.FC<BlockBenefitProps> = ({ title, tag, icon, borderColor, color }: BlockBenefitProps) => {
    return (
        <Main
            className='block'
            borderColor={borderColor}
            color={color}   
        >

            <Head className='head'>
                <Icon className='icon'>
                    <ReactSVG src={icon} wrapper="span" className='svg' />
                </Icon>
            </Head>

            <Bottom className='bottom'>
                <Title className='title'>{title}</Title>
                {tag && (
                    <Tag className='tag'>{tag}</Tag>
                )}
            </Bottom>

        </Main>
    )
}

export { BlockBenefit };