export const pt = {
    locale: "pt",
    Nav: {
        "Plataformas": "Plataformas",
        "QuemSomos": "Quem somos",
        "ButtonText": "Contate-nos",
    },
    Home: {
        Hero: {
            "Headline": "Fidelidade & Motor de Pontos",
            "Text": "Plataforma tecnológica utilizada pelos maiores programas de fidelidade do Brasil.",
            "ButtonText": "Saiba mais",
        },
        Point1: {
            "HeadlineStart": "Com mais de ",
            "HeadlineEnd": "de experiência, somos uma empresa cheia de estórias de sucesso para contar.",
            "Tag": "20 anos",
            "BottomHeadline": "Ajudamos empresas e consumidores a estreitarem laços e realizarem objetivos.",
            "ButtonText": "Conheça nossa história",
            Block_1: {
                "Title": "Milhões",
                "Text": "Usuários cadastrados",
            },
            Block_2: {
                "Title": "Milhões",
                "Text": "Transações processados por mês",
            },
            Block_3: {
                "Title": "Bilhões",
                "Text": "Transações processadas por ano",
            },
            Block_4: {
                "Title": "Mil",
                "Text": "Transações do programa de fidelidade processados por mês",
            }, 
        },
        Point2: {
            "Headline": "Muitas possibilidades em um só lugar",
            Block_1: {
                "Title": "COMMARKET",
                "Text": "Motor de pontos"
            },
            Block_2: {
                "Title": "COMMARKET",
                "Text": "Campanhas"
            },
            Block_3: {
                "Title": "COMMARKET",
                "Text": "Gestão de OKRs"
            },
            Block_4: {
                "Title": "COMMARKET",
                "Text": "Marketplace"
            },
            "ButtonText": "Conheça nossos produtos",
        },
        Point3: {
            "Headline": "Serviços",
            "Tag": "On-demand",
            Block_1: {
                "Title": "Planejamento e Gestão Estratégica",
            },
            Block_2: {
                "Title": "Marketing & BI",
            },
            Block_3: {
                "Title": "Programas de Fidelidade, Relacionamento e Campanhas",
            },
            Block_4: {
                "Title": "Tecnologia",
            },
            "ButtonText": "Saiba mais",
        },
        Point4: {
            "Headline": "Nas últimas duas décadas, nos dedicamos para entregar soluções de alta performance a estas organizações.",
        },
        Point5: {
            "Headline": "Gostaria de conhecer mais sobre nossos cases de sucesso?",
            "ButtonText": "Agende um papo conosco",
        }
    },
    Platforms: {
        Hero: {
            "Title": "COMMARKET Motor de Pontos",
            "Subtitle": "Muitas possibilidades em uma só plataforma",
            "Text": "O RP2000 é uma plataforma de alto desempenho, orientada a eventos e com máxima capacidade de processamento transacional, possibilitando o acompanhamento de programas de fidelidade.",
            "TextBottom": "Plataforma tecnológica utilizada pelos maiores programas de fidelidade do Brasil."
        },
        Point2: {
            Block1: {
                "Title": "Programas de frequência ",
                "Text": "Clientes ganham pontos ou créditos a serem resgatados; ofertas únicas",
            },
            Block2: {
                "Title": "Programas de fidelização ",
                "Text": "Clientes recebem os benefícios que advêm de ascender a um estatuto superior de cliente; relacionamento de longo prazo",
            },
            Block3: {
                "Title": "Programas de indicação ",
                "Text": "Clientes ganham pontos ou créditos a serem resgatados por recomendar produtos a outros potenciais clientes; ofertas únicas",
            },
            Block4: {
                "Title": "Campanhas de incentivo ",
                "Text": "Planos que proporcionam compensação a um determinado grupo de stakeholders por atingir determinadas metas de desempenho ou condições semelhantes dentro de um determinado período",
            },
            Block5: {
                "Title": "Campanhas promocionais ",
                "Text": "Promoções que estimulam compras e vendas com descontos, cashback, frete grátis, brindes e muito mais",
            },
            Block6: {
                "Title": "Programas de coalizão ",
                "Text": "Programas de fidelidade únicos que abrangem vários negócios",
            },
            "ButtonText": "Fale com um especialista",
        },
        Point3: {
            "Headline": "COMMARKET Gestão de OKRs",
            "Title": "Impulsione o desempenho de sua equipe ou negócio",
            Block1: {
                "Title": "COMMARKET Gestão de OKRs é um Software que ajuda você a implantar estratégias de negócios de forma orientada e clara. Defina, acompanhe e supere metas com COMMARKET Gestão de OKRs."
            },
            Block2: {
                "Title": "COMMARKET Gestão de OKRs viabiliza o alinhamento entre o planejamento e a execução de estratégias, a visualização do progresso da equipe e o monitoramento do desempenho de cada indivíduo, área ou negócio em tempo real."
            },
            Block3: {
                "Title": "Tenha um plano estratégico, dê acesso à sua equipe e garanta que todos estejam no caminho certo."
            },
            Block4: {
                "Title": "Plataforma intuitiva, bastante informativa e que torna mais eficaz o processo de comunicação."
            },
            Block5: {
                "Title": "COMMARKET Gestão de OKRs é a ponte entre as estratégias e o resultado desejado, fundada na Gestão de Objetivos."
            },
            Block6: {
                "Title": "Poderosa estrutura de definição de metas que promove convergência de visão e esforços, adaptação rápida às mudanças de ambiente e check-ins guiados muito amigáveis."
            },
            Featured: {
                "Title": "COMMARKET Gestão de OKRs",
                "Text": "aumenta as suas chances de sucesso!",
                "ButtonText": "Fale com um especialista"
            }
        },
        Point4: {
            "Headline": "COMMARKET Campanhas",
            "Title": "Alavanque a relação da sua empresa com colaboradores, parceiros e consumidores",
            Block1: {
                "Title": "COMMARKET Campanhas é uma plataforma digital que permite a parametrização, o acompanhamento e a mensuração de programas de incentivo e recompensas para os mais variados públicos."
            },
            Block2: {
                "Title": "Defina o que você espera que seus colaboradores, parceiros de negócios e consumidores façam, acompanhe a evolução deles e os premie pelo atendimento de metas com COMMARKET Campanhas."
            },
            Block3: {
                "Title": "Você pode gerenciar facilmente programas de incentivos e recompensas em tempo real. E o melhor, quem participa dos programas acompanha tudo de uma única conta pessoal e protegida."
            },
            Block4: {
                "Title": "COMMARKET Campanhas é a plataforma mais amigável e segura do mercado. E o melhor de tudo: a plataforma fica com a cara da sua empresa!"
            },
            Block5: {
                "Title": "Saia da planilha de Excel. Pare de usar accounts que não são seus, não são administrados por você. Tenha tudo em único ambiente!"
            },
            Featured: {
                "TitleStart": "Aproxima você das suas",
                "TitleEnd": "e do seu público!",
                "Tag": "conquistas",
                "ButtonText": "Fale com um especialista"
            }
        },
        Point5: {
            "Headline": "COMMARKET Marketplace",
            "Title": "A COMMARKET já construiu a estrutura do marketplace que você precisa.",
            "BottomHeadline": "Se você precisa de uma ferramenta para vendas online, nós oferecemos a estrutura e o serviço de operação/sustentação.",
            "BottomText1": "Porém, se o que você procura é um marketplace e parceiros para o seu programa de fidelidade ou campanhas de incentivo/recompensa, temos integrações com diversos, incluindo os maiores players do mercado. Cupons, vouchers, cartões, gift cards, bens e serviços estão à disposição no modo plug&play.",
            "BottomText2": "Por fim, não se esqueça: nós podemos montar o seu próprio catálogo e disponibilizá-lo na nossa plataforma. Expanda a sua rede! Aumente suas vendas!",
            Block1: {
                "Title": "Store"
            },
            Block2: {
                "Title": "Accounts"
            },
            Block3: {
                "Title": "Logística"
            },
            Block4: {
                "Title": "Admin"
            },
            Block5: {
                "Title": "Comunicação"
            },
            Block6: {
                "Title": "Meios de pagamento"
            },
            Featured: {
                "Title": "Está tudo pronto e integrado!",
            },
            "ButtonText": "Fale com um especialista",
        },
    },
    About: {
        "Headline": "Somos gratos por atendermos tantas marcas importantes e tantos executivos e empreendedores brilhantes.",
        Point1: {
            "Headline": "Nossa História",
            "HistoryText": "A história da COMMARKET se confunde com a história dos programas de Fidelidade e Relacionamento no Brasil, quando o assunto é conhecimento e tecnologia embarcada.",
        },
        Point2: {
            "Title": "Missão",
            "Text": "Nossa Missão é criar valor para organizações por meio da oferta de conhecimento e soluções sistêmicas com foco em gestão estratégica de relacionamentos organizacionais (B2B, B2C e B2E) e resultados, com vistas ao crescimento dos negócios e incremento de rentabilidade de todos os clientes e usuários das nossas plataformas."
        },
        Point3: {
            "Title": "Visão",
            "Text": "Nossa Visão é ter mais de 200 milhões de usuários ativos em nossas plataformas até 2025."
        },
        Point4: {
            "Title": "Valores",
            "Text": "Nossos Valores são:",
            Block1: {
                "Title": "Paixão",
            },
            Block2: {
                "Title": "Excelência",
            },
            Block3: {
                "Title": "Simplicidade",
            },
            Block4: {
                "Title": "Ética",
            },
        },
        Point5: {
            "Title": "",
            "Text": "",
            Block1: {
                "Title": "Experiência de 20+ anos em Gestão Estratégica de Relacionamentos e Resultados",
            },
            Block2: {
                "Title": "Soluções de consultoria e tecnologia integradas para todos os perfis de negócio",
            },
            Block3: {
                "Title": "Equipe de especialistas em criação de valor: Planejamento Estratégico, Gestão de Objetivos e Desempenho, Programas de Fidelidade e Campanhas de Incentivo.",
            },
            Block4: {
                "Title": "Empresa inovadora e com capacidade de produção e customização de sistemas",
            },
            Block5: {
                "Title": "Alianças estratégicas com empresas líderes",
            },
        },
        Point6: {
            "Title": "Trabalhamos incansavelmente para mantermos nosso pioneirismo.",
            "Text": "Nos orgulhamos da nossa história e de quanto ajudamos nossos clientes e usuários a realizarem objetivos."
        },
        Point7: {
            "Title": "Junte-se a nós",
            "Text": "Buscamos constantemente nos cercar e trabalhar em parceria com pessoas e organizações que valorizam e ambicionam o mesmo que nós:",
            "ButtonText": "Vagas disponíveis",
            Block1: {
                "Tag": "Conhecimento",
                "Title": "Capacitar-se e qualificar-se para executar o que promete, se compromete.",
            },
            Block2: {
                "Tag": "Excelência técnica",
                "Title": "Executar tarefas com qualidade e responsabilidade, no menor tempo possível.",
            },
            Block3: {
                "Tag": "Respeito",
                "Title": "Respeitar as leis e os investimentos de quem acredita na gente.",
            },
            Block4: {
                "Tag": "Inovação",
                "Title": "Desenvolver incessantemente métodos que surpreendam positivamente quem afeta ou é afetado por nós.",
            },
            Block5: {
                "Tag": "Credibilidade",
                "Title": "Objetivar, sempre, a manutenção da credibilidade, o bem mais valioso de uma pessoa física ou jurídica.",
            },
            Block6: {
                "Tag": "Desejo de Transformação ",
                "Title": "Preparar-se para contornar crises e atender os anseios de quem nos afetada ou é afetado pelas nossas ações.",
            },
        },
    },
    Contact: {
        "Headline": "Envie-nos uma mensagem",
        "Nome": "Nome",
        "EMail": "Email",
        "Telefone": "Telefone",
        "Companhia": "Companhia",
        "Mensagem": "Mensagem",
        "ButtonText": "Enviar",
        Messages: {
            Name: "Digite seu nome",
            EMail: "Digite seu e-mail",
            Company: "Digite sua companhia",
            Phone: "Digite sua número de telefone",
            Message: "Digite uma mensagem",
        }
    },
    EndToEnd: {
        Hero: {
            "Headline": "O único ecossistema para gestão de resultados ponta a ponta",
            "Text": "Expanda seus negócios com nosso pacote de 4 produtos",
            Featured: {
                "Title": "Comece sua jornada COMMARKET com um único módulo ou o ecossistema completo.",
            },
            Block_1: {
                "Title": "COMMARKET ",
                "Text": "Motor de pontos",
            },
            Block_2: {
                "Title": "COMMARKET ",
                "Text": "Gestão de OKRs",
            },
            Block_3: {
                "Title": "COMMARKET ",
                "Text": "Campanhas",
            },
            Block_4: {
                "Title": "COMMARKET ",
                "Text": "Marketplace",
            },
        },
        Point1: {
            "Headline": "Da Gestão de Objetivos fundada em OKRs ao Engajamento de Colaboradores, Parceiros de Negócios e Consumidores",
            Block_1: {
                "Title": "Ganhe convergência.",
            },
            Block_2: {
                "Title": "Aumente o nível de confiança e engajamento.",
            },
            Block_3: {
                "Title": "Cresça seu negócio!",
            },
            "ButtonText": "Agende um papo conosco"
        }
    },
    OnDemand: {
        Point1: {
            "Headline": "Planejamento e Gestão Estratégica",
            "Text_1": "Assessoramos empresas e empreendedores desde a concepção da Missão e da Visão de um projeto ou negócio até o alinhamento de recursos para a execução estratégica.",
            "Text_2": "Conte com o nosso time de consultores para alavancar o resultado do seu projeto ou negócio com o Software COMMARKET Gestão de OKRs.",
            "Text_3": "A variedade de experiências e setores nos quais já trabalhamos, e a constante atualização dos nossos profissionais, nos torna um parceiro valioso para a realização dos seus objetivos.",
            "ButtonText": "Contate-nos"
        },
        Point2: {
            "Headline": "Programas de Fidelidade, Relacionamento e Campanhas",
            "Text_1": "Há duas décadas, existimos para ajudar as empresas a estreitarem laços e reconhecerem as conquistas de colaboradores, parceiros de négócios e consumidores.",
            "Text_2": "Se sua empresa tem um programa de fidelidade, relacionamento, incentivo ou recompensa desatualizado, ineficaz, ou sequer ter um, ajudamos você a criar uma estratégia que promova a participação do seu público-alvo e gere resultados reais.",
            "Text_3": "Conhecemos as melhores práticas e cenários de orçamentos, materiais de apoio e formas de comunicação necessários para que alcance seus objetivos.",
            "ButtonText": "Contate-nos"
        },
        Point3: {
            "Headline": "Marketing & BI",
            "Text_1": "Assessoramos nossos clientes na observação do comportamento do consumidor e de outros stakeholders relevantes, com vistas ao aprimoramentos de estratégias ou desenvolvimento de novos produtos, serviços ou processos.",
            "Text_2": "Data Analytics e Social Media estão na raíz do nosso trabalho. Temos o conhecimento necessário para alavancar vendas, resultados financeiros e a satisfação de quem se relaciona com o seu negócio.",
            "ButtonText": "Contate-nos"
        },
        Point4: {
            "Headline": "Tecnologia",
            "Text_1": "Temos plataformas próprias, idealizadas, projetadas, desenvolvidas e operadas por nós? Sim, temos! Mas o que você precisa saber é que a nossa história foi pavimentada por projetos sob medida, customizados.",
            "Text_2": "Somos uma FÁBRICA DE SOFTWARE e gerenciamos projetos como serviço, damos suporte/sustentação (Help Desk) aos nossos clientes, integramos sistemas, operamos e monitoramos programas e áreas dos nossos clientes.",
            "Text_3": "Conhecimento COMMARKET + Tecnologia COMMARKET = Resultado",
            "ButtonText": "Contate-nos"
        },
    },
    Footer: {
        "ButtonText": "Junte-se a nós",
    }
};