import React from 'react';
import { Outlet } from 'react-router-dom';
// import Slider from "react-slick";
import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';

import { Main, Image, WrapperButton, WrapperSliderItem } from './styles';

import HeroImage from '@img/hero-image.png';
import { Button } from '@components/design-system';
import { tawkToChat } from '@utils';

import { Header, Footer } from '@components/patterns';
import { i18n } from '@hooks';


const HeroHome: React.FC = () => {

    const theme = useTheme();
    const mobile = useMediaQuery('(min-width: 600px)');
    const { t } = i18n;

    // const settings = {
    //     dots: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     arrows: false
    // };

    return (
        <>
            <Main>
                <Header />

                <Container maxWidth="lg">
                    {/* <Slider {...settings} className='hero-slider'> */}
                    <WrapperSliderItem>
                        <Grid container spacing={2} alignItems={'center'} flexDirection={!mobile ? 'column-reverse' : 'row'}>
                            <Grid item xs={12} sm={5} md={5} lg={5} xl={6}>

                                <Typography
                                    variant="body1"
                                    component="div"
                                    align={!mobile ? 'center' : 'left'}
                                    gutterBottom
                                    sx={{
                                        fontSize: !mobile ? '16px' : '28px',
                                        fontWeight: theme.typography.fontWeightLight,
                                        color: theme.palette.common.white,
                                        marginBottom: theme.typography.pxToRem(!mobile ? 5 : 20)
                                    }}
                                >
                                    {t('Home.Hero.Headline') as string}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    component="h1"
                                    align={!mobile ? 'center' : 'left'}
                                    sx={{
                                        fontSize: !mobile ? '20px' : '34px',
                                        color: theme.palette.common.white,
                                        fontWeight: theme.typography.fontWeightBold,
                                    }}
                                >
                                {t('Home.Hero.Text') as string}
                                </Typography>

                                <WrapperButton>
                                    <Button
                                        backgroundColor='#000'
                                        color='white'
                                        buttonBorderColor='linear-gradient(89.67deg, #03C3FF -5.83%, #BD00FF 51.64%, #E10000 111.55%)'
                                        buttonBorderHoverColor='inear-gradient(89.67deg, #03C3FF -5.83%, #BD00FF 51.64%, #E10000 111.55%)'
                                        buttonSize='small'
                                        onClick={tawkToChat}
                                    >
                                        {t('Home.Hero.ButtonText') as string}
                                    </Button>
                                </WrapperButton>
                            </Grid>
                            <Grid item xs={12} sm={5} md={5} lg={5} xl={6}>
                                <Image src={HeroImage} alt="hero" />
                            </Grid>
                        </Grid>
                    </WrapperSliderItem>
                    {/* </Slider> */}
                </Container>
            </Main>
            <Outlet />
            <Footer />

        </>

    )
}

export { HeroHome };