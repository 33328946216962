import { alpha, Container, styled } from "@mui/material";
import { routes } from "@utils";

interface PositionProps {
    right: string;
    top: string;
    bottom: string;
}

const url = document.location.pathname;
const values = Object.values(routes);
const find = values.find(item => item === url);
let render: any;
let position: PositionProps = {
    right: '0',
    top: '0',
    bottom: '0',
};

switch (find) {
    case routes.home:
        render = `linear-gradient(102.07deg, #FB03F5 4.03%, #AA9CFF 70.5%)`;
        position = {
            right: '0',
            top: '-0.57%',
            bottom: '86.17%'
        }
        break;
    case routes.onDemand:
        render = `linear-gradient(180deg, #8628E2 -13.16%, #A860EF 86.84%)`;
        position = {
            right: '-7rem',
            top: '-0.57%',
            bottom: '86.17%'
        }
        break;
    case routes.platform:
        render = `linear-gradient(180deg, rgba(3, 251, 162, 0.6) 0%, rgba(170, 156, 255, 0.6) 100%); `;
        position = {
            right: '-7rem',
            top: '-0.57%',
            bottom: '86.17%'
        }
        break;
    case routes.endToEnd:
        render = `linear-gradient(180deg, #33CAFF 0%, #87E1FF 100%);`;
        position = {
            right: '-7rem',
            top: '-10rem',
            bottom: '86.17%'
        }
        break;

    default:
        break;
}


export const HeroWrapper = styled('div')(({ theme }) => `
    position: relative;
    overflow: hidden;
    z-index: 9999;

    -webkit-transition: all 0.15s ease-in-out;
    -moz-transition: all 0.15s ease-in-out;
    -ms-transition: all 0.15s ease-in-out;
    -o-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;

    & > .active-header {
        background: transparent;
    }

    /* &::before {
        content: '';
        display: block;
        position: absolute;
        right: ${position.right};
        top: ${position.top};
        bottom: ${position.bottom};

        background: ${render};
        filter: blur(189.415px);
        height: 570px;
        width: 460px;
        
        ${[theme.breakpoints.down("sm")]} {
            height: 370px;
            width: 160px;
            background: ${render};
            filter: blur(2.8358rem);

        }
    } */
`);

export const MuiContainer = styled(Container)(({ theme }) => ({
    paddingTop: '1.25rem',
    paddingBottom: '1.25rem',

    [theme.breakpoints.down("sm")]: {
        paddingTop: '.75rem',
        paddingBottom: '.75rem',
    },

}));

export const ImageLogo = styled('div')(({ theme }) => ({

    '& > a': {
        display: 'flex',
        alignItems: 'center',

        '& > img': {
            width: 'auto',
            [theme.breakpoints.down("lg")]: {
                height: '44px',
            },
            [theme.breakpoints.down("sm")]: {
                height: '36px',
            },
        }
    }
}));

export const Button = styled('button')(({ theme }) => ({
    border: 0,
    borderRadius: '6.25rem',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    backgroundColor: theme.palette.common.white,
    overflow: 'hidden',
    '& > a': {
        display: 'block',
        padding: '.625rem 1rem',
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.common.black,
        backgroundColor: theme.palette.common.white,
        fontWeight: 500,
        textDecoration: 'none'

    },
    [theme.breakpoints.down("sm")]: {
        padding: '.375rem .625rem',
    },
}));

export const Columns = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
    // overflow: 'hidden',
    [theme.breakpoints.down("sm")]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
}));

export const Column = styled('div')(({ theme }) => ({
    '&.column': {
        '&:nth-child(1), &:nth-child(2), , &:nth-child(2)': {
            [theme.breakpoints.down("sm")]: {
                width: '100%',
            },
        },
        '&:nth-child(1), &:nth-child(3)': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        '&:nth-child(2)': {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            [theme.breakpoints.down("sm")]: {
                padding: '.875rem 0',
                flexDirection: 'column',
                alignItems: 'space-between',
                width: '100%',
                margin: '30px 0'
            },
        },
        '&:nth-child(3)': {
            paddingRight: '0',
            width: '100%',
            justifyContent: 'flex-start',
            [theme.breakpoints.down("sm")]: {
                justifyContent: 'space-between',
                padding: '.875rem 0',
            },
        }
    }
}));

export const ActionColumn = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    position: 'relative',
    [theme.breakpoints.down("sm")]: {
        width: '100%',
        justifyContent: 'space-between',
    }
}));

export const MenuList = styled('div')(({ theme }) => ({
    backgroundColor: 'transparent',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: '3.125rem',
    [theme.breakpoints.down("sm")]: {
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        paddingTop: '1.875rem',
        height: 'calc(100vh - 180px)',
        paddingRight: '0',
        width: '100%',
    },
    '& > a': {
        display: 'block',
        color: theme.palette.common.white,
        textDecoration: 'none',
        fontSize: '.875rem',
        fontWeight: 500,
        padding: '0 1.25rem',
        whiteSpace: 'nowrap',
        width: '100%',
        '&:last-child': {
            [theme.breakpoints.down("sm")]: {
                borderBottom: `.0625rem solid ${alpha(theme.palette.common.white, 0.1)}`,
            }
        },
        [theme.breakpoints.down("md")]: {
            fontSize: '.8125rem'
        },
        [theme.breakpoints.down("sm")]: {
            borderTop: `.0625rem solid ${alpha(theme.palette.common.white, 0.1)}`,
            fontWeight: 400,
            fontSize: '1rem',
            padding: '.625rem 0',
        },

    }
}));

export const SocialList = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '100%',
    marginLeft: '3.125rem',
    marginRight: '15px',

    [theme.breakpoints.down("md")]: {
        marginLeft: '.9375rem',
    },

    '& > a': {
        display: 'block',
        padding: '0.3125rem',
        backgroundColor: 'transparent',
        marginLeft: '.625rem',

        '&:first-child': {
            marginLeft: 0,
        },

        '& > .svg > span > svg': {
            [theme.breakpoints.down("sm")]: {
                width: '12px',
            },
            [theme.breakpoints.down("md")]: {
                width: '16px',
            },
            '& > path': {
                fill: theme.palette.common.white,
            }
        },
    }
}));

export const SocialLink = styled('a')({
    lineHeight: 1,

    '& > .svg > span': {
        lineHeight: 0
    }
});

export const ButtonIconOpen = styled('button')({
    lineHeight: 1,
    border: 0,
    backgroundColor: 'transparent',
    height: '46px',
    width: '46px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    cursor: 'pointer',

    '& > a': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
    },

    '& > .svg': {
        lineHeight: 0,
        position: 'relative',
        top: '.0625rem'
    }
});

export const Dropdown = styled('div')({
    position: 'relative'
});

export const DropdownContent = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: '20px',
    right: '-15px',
    padding: '5px 10px',
    backgroundColor: 'rgba(255,255,255,0.04)',
    '& > a': {
        display: 'block',
        color: theme.palette.common.white,
        textDecoration: 'none',
        fontSize: '.875rem',
        fontWeight: 500,
        padding: '10px 1.25rem',
        whiteSpace: 'nowrap',
        width: '100%',
        '&:last-child': {
            [theme.breakpoints.down("sm")]: {
                borderBottom: `.0625rem solid ${alpha(theme.palette.common.white, 0.1)}`,
            }
        },
        [theme.breakpoints.down("sm")]: {
            borderTop: `.0625rem solid ${alpha(theme.palette.common.white, 0.1)}`,
            fontWeight: 400,
            fontSize: '1rem',
            padding: '.625rem 0',
        },
        [theme.breakpoints.down("md")]: {
            fontSize: '.8125rem'
        },
    }
}));

export const Open = styled('button')(({ theme }) => ({
    border: '0',
    padding: '.3125rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    '& > span': {
        display: 'block',
        height: '.25rem',
        width: '.25rem',
        backgroundColor: theme.palette.common.white,
        marginLeft: '.25rem',
        borderRadius: '6.25rem',
    }
}));


// Translate

export const SelectLang = styled('div')(({ theme }) => `
    /* position: relative; */
    `);

export const Icons = styled('div')(({ theme }) => ({
    background: 'rgba(255,255,255,0.1)',
    padding: '6px',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    cursor: 'pointer',
    '&.active': {
        borderBottomLeftRadius: '0',
        borderBottomRightRadius: '0',
    },
    '&.lang-mob': {
        justifyContent: 'flex-start',
        marginTop: '.9375rem',
    },
    '& > .svg:last-child': {
        display: 'block',
        marginLeft: '.375rem',
        [theme.breakpoints.down("md")]: {
            marginLeft: '.375rem',
        },
        '& > span': {
            '& > svg': {
                width: '10px',
                [theme.breakpoints.down("md")]: {
                    height: '.375rem',
                    width: 'auto'
                },
                '& > path': {
                    fill: theme.palette.common.white
                }
            }
        }
    },
    '& > .svg': {
        lineHeight: 0,
        '& > span': {
            '& > svg': {
                height: '16px',
                width: 'auto',
                '& > path': {
                    fill: theme.palette.common.white
                }
            }
        }
    }
}));

export const Langs = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: theme.typography.pxToRem(34),
    right: theme.typography.pxToRem(0),
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    background: 'rgba(255,255,255,0.1)',
    padding: '.1875rem',
    borderTopLeftRadius: '.25rem',
    borderBottomLeftRadius: '.25rem',
    borderBottomRightRadius: '.25rem',
    [theme.breakpoints.down("md")]: {
        top: theme.typography.pxToRem(20),
        right: theme.typography.pxToRem(-6),
    },
    '&.lang-mob': {
        right: 'auto',
        left: 0,
        marginTop: '.3125rem',

        '& > .select-lang': {
            '&:first-child': {
                marginRight: '.625rem',
            }
        }
    },
}));

export const LangButton = styled('button')(({ theme }) => ({
    border: 0,
    backgroundColor: 'transparent',
    cursor: 'pointer',
    margin: '0 2px',
    '& > h1': {
        margin: 0,
        lineHeight: 1
    },

    '&.flag-1 > div': {
        '& > img': {
            height: 'auto',
            width: theme.typography.pxToRem(20),
        },
    },
    '&.flag-2 > div': {
        '& > img': {
            height: 'auto',
            width: theme.typography.pxToRem(26),
        },
    },
    '&.flag-3 > div': {
        '& > img': {
            height: 'auto',
            width: theme.typography.pxToRem(22),
        },
    },

}));

export const ImageFlag = styled('div')(({ theme }) => ({
    height: theme.typography.pxToRem(16),
    [theme.breakpoints.down("md")]: {
        height: theme.typography.pxToRem(18),

    },
    '& > img': {
        height: 'auto',
        width: theme.typography.pxToRem(24),
        [theme.breakpoints.down("md")]: {
            width: theme.typography.pxToRem(22),
        }
    },
    '& > .svg': {
        '& > span': {
            '& > svg': {
                position: 'relative',
                top: theme.typography.pxToRem(1),
                [theme.breakpoints.down("md")]: {
                    height: theme.typography.pxToRem(14),
                    width: 'auto'
                },
            }
        }
    }
}));
