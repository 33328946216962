import React from 'react';
import { ReactSVG } from 'react-svg';

import { BlockBenefit } from '@components/design-system';
import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Header, Footer } from '@components/patterns';
import {
  PointsEngine,
  Management,
  Campain,
  Marketplace,
  ArrowRight,
} from '@icons';

import {
  Main,
  OurStory,
  Overlay,
  OurStoryWrapper,
  GridWrapper,
  Icon,
} from './styles';

import OutStoryBottom from '@img/end-to-end-bottom.svg';
import { Outlet } from 'react-router-dom';
import { i18n } from '@hooks';

const HeroEndToEnd: React.FC = () => {

  const theme = useTheme();
  const mobile = useMediaQuery('(min-width: 600px)');
  const { t } = i18n;

  return (
    <>
      <Main>
        <Header />
        <Container maxWidth="lg" className='container'>

          <Grid
            container spacing={!mobile ? 2 : 6}
            alignItems={'center'}
            flexDirection={!mobile ? 'column-reverse' : 'row'}
            sx={{
              position: 'relative',
              zIndex: 9,
              marginTop: theme.typography.pxToRem(40),
              [theme.breakpoints.down("sm")]: {
                marginTop: theme.typography.pxToRem(0),
              }
            }}
          >
            <Grid item xs={12} sm={5} md={5} lg={5}>

              <Typography
                variant="body1"
                component="h1"
                align='left'
                sx={{
                  fontSize: '40px',
                  color: theme.palette.common.white,
                  fontWeight: theme.typography.fontWeightBold,
                  marginBottom: theme.typography.pxToRem(30),
                  lineHeight: 1.3,
                  [theme.breakpoints.down("md")]: {
                    fontSize: '30px',
                    marginBottom: theme.typography.pxToRem(30),
                  },
                  [theme.breakpoints.down("sm")]: {
                    fontSize: '20px',
                    marginBottom: theme.typography.pxToRem(22),
                  }
                }}
              >
                {t('EndToEnd.Hero.Headline') as string}
              </Typography>

              <GridWrapper className='only-m'>
                <Grid container spacing={!mobile ? 4 : 6}>

                  <Grid item xs={6} sm={6} className='grid-item'>
                    <BlockBenefit
                      title={t('EndToEnd.Hero.Block_1.Title') as string}
                      tag={t('EndToEnd.Hero.Block_1.Text') as string}
                      icon={PointsEngine}
                      borderColor="linear-gradient(89.67deg, #8628E2 -5.83%, #BA79FF 51.64%, #8628E2 111.55%);  "
                      color='#8628E2'
                    />
                  </Grid>

                  <Grid item xs={6} sm={6} className='grid-item'>
                    <BlockBenefit
                      title={t('EndToEnd.Hero.Block_2.Title') as string}
                      tag={t('EndToEnd.Hero.Block_2.Text') as string}
                      icon={Management}
                      borderColor='linear-gradient(89.67deg, #33CAFF -5.83%, #0075FF 51.64%, #33CAFF 111.55%);  '
                      color='#33CAFF'
                    />
                  </Grid>

                  <Grid item xs={6} sm={6} className='grid-item'>
                    <BlockBenefit
                      title={t('EndToEnd.Hero.Block_3.Title') as string}
                      tag={t('EndToEnd.Hero.Block_3.Text') as string}
                      icon={Campain}
                      borderColor='linear-gradient(89.67deg, #FF00B8 -5.83%, #9B3BAB 51.64%, #FF00B8 111.55%);'
                      color='#EC43E5'
                    />
                  </Grid>

                  <Grid item xs={6} sm={6} className='grid-item'>
                    <BlockBenefit
                      title={t('EndToEnd.Hero.Block_4.Title') as string}
                      tag={t('EndToEnd.Hero.Block_4.Text') as string}
                      icon={Marketplace}
                      borderColor='linear-gradient(89.67deg, #3BFF95 -5.83%, #3BFFFF 51.64%, #3BFF95 111.55%);'
                      color='#4AFFD4'
                    />
                  </Grid>

                </Grid>
              </GridWrapper>

              <Typography
                variant="body1"
                component="div"
                align='left'
                gutterBottom
                sx={{
                  fontSize: '18px',
                  fontWeight: theme.typography.fontWeightLight,
                  color: theme.palette.common.white,
                  marginBottom: theme.typography.pxToRem(60),
                  [theme.breakpoints.down("md")]: {
                    fontSize: '14px',
                    marginBottom: theme.typography.pxToRem(30),
                  },
                  [theme.breakpoints.down("sm")]: {
                    marginBottom: theme.typography.pxToRem(30),
                  }
                }}
              >
                {t('EndToEnd.Hero.Text') as string}
              </Typography>

              <OurStoryWrapper>
                <OurStory>

                  <Typography
                    variant="body1"
                    component="div"
                    align='left'
                    gutterBottom
                    sx={{
                      display: 'flex',
                      alignItems: 'flex-end',
                      justifyContent: 'flex-end',
                      fontSize: '24px',
                      fontWeight: theme.typography.fontWeightRegular,
                      color: theme.palette.common.white,
                      textAlign: 'center',
                      [theme.breakpoints.down("md")]: {
                        alignItems: 'center',
                        flexDirection: 'column',
                        fontSize: '16px',
                      },
                      [theme.breakpoints.down("sm")]: {
                        fontSize: '12px',
                      }
                    }}
                  >
                    {t('EndToEnd.Hero.Featured.Title') as string}
                    <Icon><ReactSVG src={ArrowRight} className='svg' /></Icon>

                  </Typography>
                </OurStory>
                <Overlay>
                  <img src={OutStoryBottom} alt="overlay footer" />
                </Overlay>
              </OurStoryWrapper>

            </Grid>
            <Grid item xs={12} sm={7} md={7} lg={7}>
              <GridWrapper className='only-d'>
                <Grid container spacing={!mobile ? 4 : 6}>

                  <Grid item xs={6} sm={6} className='grid-item'>
                    <BlockBenefit
                      title={t('EndToEnd.Hero.Block_1.Title') as string}
                      tag={t('EndToEnd.Hero.Block_1.Text') as string}
                      icon={PointsEngine}
                      borderColor="linear-gradient(89.67deg, #8628E2 -5.83%, #BA79FF 51.64%, #8628E2 111.55%);  "
                      color='#8628E2'
                    />
                  </Grid>

                  <Grid item xs={6} sm={6} className='grid-item'>
                    <BlockBenefit
                      title={t('EndToEnd.Hero.Block_2.Title') as string}
                      tag={t('EndToEnd.Hero.Block_2.Text') as string}
                      icon={Management}
                      borderColor='linear-gradient(89.67deg, #33CAFF -5.83%, #0075FF 51.64%, #33CAFF 111.55%);  '
                      color='#33CAFF'
                    />
                  </Grid>

                  <Grid item xs={6} sm={6} className='grid-item'>
                    <BlockBenefit
                      title={t('EndToEnd.Hero.Block_3.Title') as string}
                      tag={t('EndToEnd.Hero.Block_3.Text') as string}
                      icon={Campain}
                      borderColor='linear-gradient(89.67deg, #FF00B8 -5.83%, #9B3BAB 51.64%, #FF00B8 111.55%);'
                      color='#EC43E5'
                    />
                  </Grid>

                  <Grid item xs={6} sm={6} className='grid-item'>
                    <BlockBenefit
                      title={t('EndToEnd.Hero.Block_4.Title') as string}
                      tag={t('EndToEnd.Hero.Block_4.Text') as string}
                      icon={Marketplace}
                      borderColor='linear-gradient(89.67deg, #3BFF95 -5.83%, #3BFFFF 51.64%, #3BFF95 111.55%);'
                      color='#4AFFD4'
                    />
                  </Grid>

                </Grid>
              </GridWrapper>
            </Grid>
          </Grid>
        </Container>

      </Main>

      <Outlet />

      <Footer />
    </>

  )
}

export { HeroEndToEnd };