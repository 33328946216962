import { styled } from "@mui/material";

import ReactBG from '@img/react-bg.svg';

export const Main = styled('div')(({ theme }) => `
    overflow: hidden;
`);

export const Section = styled('div')(({ theme }) => `

&.point1,
&.point3,
&.point4,
&.point5 {
    position: relative;
    &::before,
    &::after {
        display: block;
        content: '';
        position: absolute;
        z-index: -1;
    }
}

    &.point3 {
        padding-top: 160px;
        
        ${[theme.breakpoints.down("sm")]} {
            padding-top: 40px;
        }

        &::before {
            width: 811px;
            height: 390px;
            left: -34rem;
            top: 40rem;
            bottom: 0;
            margin: auto;
            
            background: linear-gradient(180deg, #33CAFF -13.16%, #3DC3F2 86.84%);
            filter: blur(500px);
            transform: matrix(0.73, -0.68, -0.69, -0.73, 0, 0);
            opacity: 0.3;
            
            ${[theme.breakpoints.down("sm")]} {
                width: 611px;
                height: 190px;
                top: 0;
                left: -27rem;
            }
        }
    }

    &.point4 {
        padding-top: 160px;
        
        ${[theme.breakpoints.down("sm")]} {
            padding-top: 60px;
        }

        &::after {
            width: 37.5rem;
            height: 21.875rem;
            right: -25rem;
            top: 40rem;
            bottom: 0;
            margin: auto;
            
            background: linear-gradient(180deg, #EC43E5 -13.16%, #960DAC 86.84%);
            filter: blur(500px);
            transform: matrix(0.83, 0.57, 0.56, -0.83, 0, 0);
            opacity: 0.6;
            
            ${[theme.breakpoints.down("sm")]} {
                width: 195px;
                height: 103.93px;
                top: -10rem;
                right: -7rem;
            }
        }
    }
    
    &.point5 {
        margin-top: 100px;
        padding-top: 100px;
        background-image: url(${ReactBG});
        background-size: contain;
        background-position: 50% -62%;
        background-repeat: no-repeat;
        
        ${[theme.breakpoints.down("sm")]} {
            background-position: center;
            padding-top: 40px;
        }

        &::before {
            width: 592px;
            height: 230px;
            left: -26rem;
            top: 0;
            bottom: 0;
            margin: auto;
            
            background: linear-gradient(160.41deg, #3CFF95 13.12%, #00FFFF 66.93%);
            filter: blur(500px);
            transform: matrix(-0.68, 0.73, 0.74, 0.68, 0, 0);
            opacity: 0.3;
            
            ${[theme.breakpoints.down("sm")]} {
                left: -34rem;
            }
        }
    }
`);

export const WrapperGridBlocks = styled('div')(({ theme }) => `

    & > .MuiGrid-container {

        & > .grid-item {

            & > .block {
                margin: 0;
                background: #BC2DFF !important;
                align-items: center;
                padding: 20px;
                min-height: 304px;
                
                ${[theme.breakpoints.down("sm")]} {
                    min-height: auto;
                }
                
                & > .head {
                    
                    min-height: 100px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    ${[theme.breakpoints.down("sm")]} {
                        min-height: 30px;
                    }

                    & > .icon {
                        & > .svg {
                            & > span {
                                & > svg {
                                    width: auto;
                                    height: auto;
                                    ${[theme.breakpoints.down("sm")]} {
                                        width: 26px;
                                    }
                                }
                            }
                        }
                    }
                }

                & > .bottom {
                    margin-top: 0;

                    & > .title {
                        color: #FFF !important;
                        font-weight: 500;
                        font-size: 36px;
                        text-align: center;
                        margin-bottom: 10px;
                        line-height: 1.3;
                        
                        ${[theme.breakpoints.down("md")]} {
                            font-size: 26px;
                        }
                        
                        ${[theme.breakpoints.down("sm")]} {
                            font-size: 12px;
                        }

                    }
                    
                    & > .tag {
                        color: #FFF !important;
                        font-weight: 300;
                        font-size: 18px;
                        text-align: center;

                        ${[theme.breakpoints.down("md")]} {
                            font-size: 16px;
                        }

                        ${[theme.breakpoints.down("sm")]} {
                            font-size: 12px;
                            display: none;
                        }
                    }
                }
            }
        }
        & > .grid-item.programs {

            & > .block {

                & > .bottom {
                    
                    ${[theme.breakpoints.up("sm")]} {
                        min-height: 17.125rem;
                    }

                    & > .title {
                        color: #FFF !important;
                        padding: 0 .9375rem;
                    }
                    
                    & > .tag {
                        color: #FFF !important;
                    }
                }
            }

        }
        & .grid-item.programs.icon {
            
            & > .block {
                & > .head {

                    & > .icon {
                        & > .svg {
                            & > span {
                                & > svg {
                                    path {
                                        fill: ${theme.palette.common.white} !important
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.solutions {
        & > .MuiGrid-container {

            & > .grid-item {

                & > .block {
                    margin: 0;
                    background: #4BFFD4 !important;
                    align-items: flex-start;
                    padding: 20px;
                    min-height: 222px;
                    
                    ${[theme.breakpoints.down("sm")]} {
                        padding: 10px;
                        min-height: 88px;
                    }
                    
                    & > .head {
                        
                        min-height: 100px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        ${[theme.breakpoints.down("sm")]} {
                            min-height: 30px;
                        }

                        & > .icon {
                            & > .svg {
                                & > span {
                                    & > svg {
                                        width: auto;
                                        height: auto;
                                        ${[theme.breakpoints.down("sm")]} {
                                            width: 26px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    & > .bottom {
                        margin-top: 0;

                        & > .title {
                            color: #000 !important;
                            font-weight: 500;
                            font-size: 24px;
                            text-align: left;
                            margin-bottom: 10px;
                            line-height: 1.3;
                            
                            ${[theme.breakpoints.down("md")]} {
                                font-size: 18px;
                            }
                            
                            ${[theme.breakpoints.down("sm")]} {
                                font-size: 10px;
                            }

                        }
                        
                        & > .tag {
                            color: #000 !important;
                            font-weight: 300;
                            font-size: 18px;
                            text-align: center;

                            ${[theme.breakpoints.down("md")]} {
                                font-size: 16px;
                            }

                            ${[theme.breakpoints.down("sm")]} {
                                font-size: 12px;
                                display: none;
                            }
                        }
                    }
                }
            }
        }

    }

`);

export const WrapperButton = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginTop: theme.typography.pxToRem(80),
    [theme.breakpoints.down("md")]: {
        marginTop: theme.typography.pxToRem(50),
    },
    '&.in-point3': {
        marginTop: theme.typography.pxToRem(80),
        [theme.breakpoints.down("sm")]: {
            marginTop: theme.typography.pxToRem(30),
        },
    },
    '&.only-m': {
        [theme.breakpoints.up("sm")]: {
            display: 'none'
        },
    },
    '&.only-d': {
        [theme.breakpoints.down("sm")]: {
            display: 'none'
        },
    },
    '&.point5-action': {
        marginTop: theme.typography.pxToRem(0),
    },
}));


export const Image = styled('img')(({ theme }) => ({
    width: '100%'
}));


export const GetInTouch = styled('div')(({ theme }) => `
    margin: 0 auto;
    
    ${[theme.breakpoints.down("sm")]} {
        padding: 15px 0;
    }
`);

export const GetInTouchBox = styled('div')(({ theme }) => `
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
    padding: 2.5rem;
    color: ${theme.palette.common.white};
    background: #000;
    background-clip: padding-box; /* !importanté */
    border: solid .125rem transparent; /* !importanté */
    border-radius: 2.375rem;
    text-align: center;
    font-weight: ${theme.typography.fontWeightRegular};
    font-size: 32px;
    /* padding: 55px 30px 55px 30px; */
    padding: 0;

    ${[theme.breakpoints.down("md")]} {
        /* padding: 36px 15px 36px 15px; */
        padding: 0;
        border-radius: .9375rem;
        font-size: 28px;
    }
    
    ${[theme.breakpoints.down("sm")]} {
        /* padding: 20px 15px 20px 15px; */
        padding: 0;
        border: solid .0625rem transparent; /* !importanté */
        font-size: 12px;
        border-radius: .5625rem;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        z-index: -1;
        margin: -.125rem; /* !importanté */
        border-radius: inherit; /* !importanté */
        background: linear-gradient(89.67deg, #4CD9ED -5.83%, #0095E9 51.64%, #4CD9ED 111.55%); 
        
        ${[theme.breakpoints.down("sm")]} {
            margin: -.0625rem; /* !importanté */
        }
    }
`);

export const WrapperImage = styled('div')(({ theme }) => `
    overflow: hidden;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    border-radius: 2.375rem;

    ${[theme.breakpoints.down("md")]} {
        border-radius: .9375rem;
    }

    ${[theme.breakpoints.down("sm")]} {
        border-radius: .5625rem;
    }
`);

export const OurStoryWrapper = styled('div')(({ theme }) => `
    margin-top: 12px;
    
    &.campain-wrapper {
        margin-top: 27px;
        & > .campain {
            background-color: #EC43E5;
        }
    }
    
    &.marketplace-wrapper {
        max-width: 415px;
        margin: 85px 0 70px 0;
        
        ${[theme.breakpoints.down("md")]} {
            margin: 55px 0 40px 0;
        }
        
        ${[theme.breakpoints.down("sm")]} {
            margin: 0;
        }
        
        & > .marketplace {
            background-color: #4AFFD4;
        }
    }
`);

export const OurStory = styled('div')(({ theme }) => `
    background-color: #33CAFF;
    padding: 34px 30px 34px 30px;
    border-radius: 1rem;
    
    ${[theme.breakpoints.down("sm")]} {
        border-radius: .25rem;
        padding: 15px 10px 15px 10px;
    }

`);

export const Overlay = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    position: 'relative',
    top: '-3px',
    '& > img': {
        width: '98%',
        [theme.breakpoints.down("sm")]: {
            width: '100%',
        }
    },
    '&.top': {
        top: '8px',
        [theme.breakpoints.down("sm")]: {
            display: 'none',
        }
    },
    '&.bottom': {
        [theme.breakpoints.up("sm")]: {
            display: 'none',
        }
    }

}));