import React from 'react';
// import { useTranslate } from "react-translate";

import { ReactSVG } from 'react-svg';

import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';

import { BlockBenefit, Button, Headline, OurNumber } from '@components/design-system';

import {
  Main,
  Section,
  GridNumbers,
  SectionAction,
  Benefits,
  BenefitColumns,
  BenefitColumn,
  ColumnCenter,
  WrapperGrid,
  BlockGrid,
  Head,
  Icon,
  Title,
  Customers,
  CustomersImage,
  GetInTouch,
  GetInTouchBox,
  ButtonAction,
  WrapperSections,
} from './styles';
import { OctogonoArrows, PointsEngine, Management, Campain, Marketplace } from '@icons';

import BussinesAnalytics from '@img/business-analytics.png';
import MarketingBi from '@img/marketing-bi.png';
import Relationship from '@img/relationship.png';
import Technology from '@img/technology.png';
import CustomersLogos from '@img/customers.png';

import { tawkToChat } from '@utils';

import { i18n } from '@hooks';

const Home: React.FC = () => {

  const { t } = i18n;
  const { typography, palette } = useTheme();
  const matches = useMediaQuery('(min-width: 600px)');
  //{t('Home.HeroHeadline') as string}
  return (
    <Main>
      <Container maxWidth="lg">

        <Section className='point1'>
          <Headline
            size={matches ? 'medium' : 'small'}
            fontWeight='bold'
            highlightColor='#00FFFF'
            highlightTextColor={palette.common.black}
            textColor={palette.common.white}
            textAlign='center'
          >

            {/* Com mais de <span>20 anos</span> de experiência, somos uma empresa cheia de estórias de sucesso para contar. */}
            {t('Home.Point1.HeadlineStart') as string}
            {" "}
            <span>
              {t('Home.Point1.Tag') as string}
            </span>
            {" "}
            {t('Home.Point1.HeadlineEnd') as string}
          </Headline>

          <GridNumbers>
            <Grid container spacing={0}>
              <Grid item xs={6} sm={3} lg={3} className='grid-item'>
                <OurNumber
                  value={50}
                  title={t('Home.Point1.Block_1.Title') as string}
                  subtitle={t('Home.Point1.Block_1.Text') as string}
                />
              </Grid>
              <Grid item xs={6} sm={3} lg={3} className='grid-item'>
                <OurNumber
                  value={25}
                  title={t('Home.Point1.Block_2.Title') as string}
                  subtitle={t('Home.Point1.Block_2.Text') as string}
                />
              </Grid>
              <Grid item xs={6} sm={3} lg={3} className='grid-item'>
                <OurNumber
                  value={'9,1'}
                  title={t('Home.Point1.Block_3.Title') as string}
                  subtitle={t('Home.Point1.Block_3.Text') as string}
                />
              </Grid>
              <Grid item xs={6} sm={3} lg={3} className='grid-item'>
                <OurNumber
                  value={50}
                  title={t('Home.Point1.Block_4.Title') as string}
                  subtitle={t('Home.Point1.Block_4.Text') as string}
                />
              </Grid>
            </Grid>
          </GridNumbers>

          <SectionAction>
            <Typography
              variant="body1"
              component="div"
              align={'center'}
              gutterBottom
              sx={{
                fontSize: matches ? '28px' : '14px',
                fontWeight: typography.fontWeightLight,
                color: palette.common.white,
                marginBottom: typography.pxToRem(matches ? 80 : 30)
              }}
            >
              {t('Home.Point1.BottomHeadline') as string}
            </Typography>
            <Button
              backgroundColor='#000'
              color='white'
              buttonBorderColor='#00FFFF'
              buttonBorderHoverColor='#00FFFF'
              buttonSize='medium'
              link='/quem-somos'
            >
              {t('Home.Point1.ButtonText') as string}
            </Button>
          </SectionAction>
        </Section>

        <WrapperSections>
          <Section className='point2'>
            <Headline
              size={matches ? 'medium' : 'small'}
              fontWeight='bold'
              textColor={palette.common.white}
              textAlign='center'
            >
              {t('Home.Point2.Headline') as string}
            </Headline>

            <Benefits>
              <BenefitColumns>

                <BenefitColumn className='column'>
                  <BlockBenefit
                    title='COMMARKET'
                    tag={t('Home.Point2.Block_1.Text') as string}
                    icon={PointsEngine}
                    borderColor="linear-gradient(89.67deg, #8628E2 -5.83%, #BA79FF 51.64%, #8628E2 111.55%);  "
                    color='#8628E2'
                  />
                  <BlockBenefit
                    title='COMMARKET'
                    tag={t('Home.Point2.Block_2.Text') as string}
                    icon={Campain}
                    borderColor='linear-gradient(89.67deg, #FF00B8 -5.83%, #9B3BAB 51.64%, #FF00B8 111.55%);'
                    color='#EC43E5'
                  />

                </BenefitColumn>

                <ColumnCenter>
                  <ReactSVG src={OctogonoArrows} wrapper="span" className='svg' />
                </ColumnCenter>

                <BenefitColumn className='column'>
                  <BlockBenefit
                    title='COMMARKET'
                    tag={t('Home.Point2.Block_3.Text') as string}
                    icon={Management}
                    borderColor='linear-gradient(89.67deg, #33CAFF -5.83%, #0075FF 51.64%, #33CAFF 111.55%);  '
                    color='#33CAFF'
                  />
                  <BlockBenefit
                    title='COMMARKET'
                    tag={t('Home.Point2.Block_4.Text') as string}
                    icon={Marketplace}
                    borderColor='linear-gradient(89.67deg, #3BFF95 -5.83%, #3BFFFF 51.64%, #3BFF95 111.55%);'
                    color='#4AFFD4'
                  />
                </BenefitColumn>

              </BenefitColumns>

            </Benefits>

            <SectionAction>
              <Button
                backgroundColor='#FFFFFF'
                color='#000000'
                buttonBorderColor='#FFFFFF'
                buttonBorderHoverColor='#FFFFFF'
                buttonSize='medium'
                link='/plataformas'
              >
                {t('Home.Point2.ButtonText') as string}
              </Button>
            </SectionAction>
          </Section>

          <Section className='point3'>

            <Headline
              size={matches ? 'medium' : 'small'}
              fontWeight='bold'
              highlightColor='#8628E2'
              highlightTextColor={palette.common.white}
              textColor={palette.common.white}
              textAlign='center'
            >
              {t('Home.Point3.Headline') as string} <span>{t('Home.Point3.Tag') as string}</span>
            </Headline>

            <WrapperGrid>

              <Grid container spacing={0}>
                <Grid item xs={12} sm={3} lg={3} className='grid-item'>
                  <BlockGrid className='block-grid'>
                    <Head>
                      <Icon>
                        <img src={BussinesAnalytics} alt="bussines analytics" />
                      </Icon>
                    </Head>
                    <Title>
                      {t('Home.Point3.Block_1.Title') as string}
                    </Title>
                  </BlockGrid>
                </Grid>
                <Grid item xs={12} sm={3} lg={3} className='grid-item'>
                  <BlockGrid className='block-grid'>
                    <Head>
                      <Icon>
                        <img src={MarketingBi} alt="bussines analytics" />
                      </Icon>
                    </Head>
                    <Title>
                      {t('Home.Point3.Block_2.Title') as string}
                    </Title>
                  </BlockGrid>
                </Grid>
                <Grid item xs={12} sm={3} lg={3} className='grid-item'>
                  <BlockGrid className='block-grid'>
                    <Head>
                      <Icon>
                        <img src={Relationship} alt="bussines analytics" />
                      </Icon>
                    </Head>
                    <Title>
                      {t('Home.Point3.Block_3.Title') as string}
                    </Title>
                  </BlockGrid>
                </Grid>
                <Grid item xs={12} sm={3} lg={3} className='grid-item'>
                  <BlockGrid className='block-grid'>
                    <Head>
                      <Icon>
                        <img src={Technology} alt="bussines analytics" />
                      </Icon>
                    </Head>
                    <Title>
                      {t('Home.Point3.Block_4.Title') as string}
                    </Title>
                  </BlockGrid>
                </Grid>
              </Grid>

            </WrapperGrid>

            <SectionAction>
              <Button
                backgroundColor='#000'
                color='white'
                buttonBorderColor='linear-gradient(89.67deg, #FFFFFF -5.83%, #BD00FF 51.64%, #E10000 111.55%);'
                buttonBorderHoverColor='linear-gradient(89.67deg, #FFFFFF -5.83%, #BD00FF 51.64%, #E10000 111.55%);'
                buttonSize='medium'
                link='/on-demand'
              >
                {t('Home.Point3.ButtonText') as string}
              </Button>
            </SectionAction>

          </Section>
        </WrapperSections>

      </Container>

      <Section className='point4'>
        <Container maxWidth="lg">
          <Headline
            size={matches ? 'medium' : 'small'}
            fontWeight='regular'
            textColor={palette.common.white}
            textAlign='center'
          >
            {t('Home.Point4.Headline') as string}
          </Headline>
        </Container>

        <Customers className='infinity-carousel'>
          <CustomersImage
            className='infinity-carousel--image'
            style={{ background: `url(${CustomersLogos})` }}
          />
        </Customers>

      </Section>

      <Container maxWidth="lg">

        <GetInTouch>
          <GetInTouchBox>
            {t('Home.Point5.Headline') as string}
          </GetInTouchBox>
          <ButtonAction>
            <Button
              backgroundColor='#BD2DEF'
              color='white'
              buttonBorderColor='#BD2DEF'
              buttonBorderHoverColor='#BD2DEF'
              buttonSize='medium'
              className='button-action'
              onClick={tawkToChat}
            >
              {t('Home.Point5.ButtonText') as string}
            </Button>
          </ButtonAction>
        </GetInTouch>

      </Container>
    </Main>
  );
}

export { Home };