export const en = {
    locale: "en",
    Nav: {
        "Plataformas": "Platforms",
        "QuemSomos": "About us",
        "ButtonText": "Contact us",
    },
    Home: {
        Hero: {
            "Headline": "Loyalty and Points Engine",
            "Text": "Technological platform used by the largest loyalty programs in Brazil.",
            "ButtonText": "Find out more",
        },
        Point1: {
            "HeadlineStart": "With over",
            "HeadlineEnd": "of experience, we are a company full of success stories to tell.",
            "Tag": "20 years",
            "BottomHeadline": "We help companies and consumers to strengthen ties and achieve goals.",
            "ButtonText": "Learn more about us",
            Block_1: {
                "Title": "Millions",
                "Text": "Registered users",
            },
            Block_2: {
                "Title": "Millions",
                "Text": "Transactions processed per month",
            },
            Block_3: {
                "Title": "Billion",
                "Text": "Transactions processed per year",
            },
            Block_4: {
                "Title": "Thousand",
                "Text": "Loyalty program transactions processed per month",
            },
        },
        Point2: {
            "Headline": "Many possibilities in one place",
            Block_1: {
                "Title": "COMMARKET",
                "Text": "Points engine"
            },
            Block_2: {
                "Title": "COMMARKET",
                "Text": "Campaigns"
            },
            Block_3: {
                "Title": "COMMARKET",
                "Text": "OKR management"
            },
            Block_4: {
                "Title": "COMMARKET",
                "Text": "Marketplace"
            },
            "ButtonText": "Learn more about our products",
        },
        Point3: {
            "Headline": "On-demand",
            "Tag": "Services",
            Block_1: {
                "Title": "Strategic Planning and Management",
            },
            Block_2: {
                "Title": "Marketing & BI",
            },
            Block_3: {
                "Title": "Loyalty Programs, Relationships Management and Campaigns",
            },
            Block_4: {
                "Title": "Technology",
            },
            "ButtonText": "Find out more",
        },
        Point4: {
            "Headline": "Over the past two decades, we have been dedicated to delivering high performance solutions to these organizations.",
        },
        Point5: {
            "Headline": "Would you like to know more about our success stories?",
            "ButtonText": "Schedule a chat with us",
        },
    },
    Platforms: {
        Hero: {
            "Title": "COMMARKET Points Engine",
            "Subtitle": "Many possibilities in one platform",
            "Text": "The RP2000 is a high-performance, event-oriented platform with maximum transactional processing capacity, enabling the monitoring of loyalty programs.",
            "TextBottom": "Technological platform used by the largest loyalty programs in Brazil."
        },
        Point2: {
            Block1: {
                "Title": "Frequency programs ",
                "Text": "Customers earn points or credits to be redeemed; one-shot deals",
            },
            Block2: {
                "Title": "Loyalty programs ",
                "Text": "Customers receive the benefits that come from climbing to a higher status of customer; long term relationship",
            },
            Block3: {
                "Title": "Referral programs ",
                "Text": "Customers earn points or credits to be redeemed for recommending products to other potential customers; one-shot deals",
            },
            Block4: {
                "Title": "Incentive campaigns ",
                "Text": "Plans which provide compensation to a certain group of stakeholders for achieving certain performance goals or similar conditions within a specified period",
            },
            Block5: {
                "Title": "Promotional campaigns ",
                "Text": "Promos which stimulate purchasing and sales by giving discounts, cashback, free shipping, gifts, and more",
            },
            Block6: {
                "Title": "Coalition programs ",
                "Text": "Single loyalty programs that comprehend across multiple businesses",
            },
            "ButtonText": "Talk to an expert",
        },
        Point3: {
            "Headline": "COMMARKET OKR Management Software",
            "Title": "Boost the performance of your team or business",
            Block1: {
                "Title": "COMMARKET OKR Management Software is a tool that helps you to implement business strategies in a guided and clear way. Set, track and exceed goals with COMMARKET OKR Management Software."
            },
            Block2: {
                "Title": "COMMARKET OKR Management Software enables the alignment between planning and execution of strategies, visualization of your team's progress and monitoring of the performance of individuals, areas or business units in real time."
            },
            Block3: {
                "Title": "Have a strategic plan, give your team access to it, and ensure everyone is on the right track."
            },
            Block4: {
                "Title": "Intuitive, highly informative platform that makes the communication process more effective."
            },
            Block5: {
                "Title": "COMMARKET OKR Management Software is the bridge between your strategies and goals, based on Goals Management."
            },
            Block6: {
                "Title": "Powerful goal setting framework that promotes convergence of vision and efforts, quick adaptation to changing environment, and very friendly guided check-ins."
            },
            Featured: {
                "Title": "COMMARKET OKR Management",
                "Text": "increases your chances of success!",
                "ButtonText": "Talk to one of our consultants"
            }
        },
        Point4: {
            "Headline": "COMMARKET Campaigns",
            "Title": "Leverage your company's relationship with employees, partners, and consumers",
            Block1: {
                "Title": "COMMARKET Campaigns is a digital platform that allows parameterization, monitoring and measurement of incentive and reward programs for the most varied audiences."
            },
            Block2: {
                "Title": "Define what you expect from your employees, business partners or consumers, track their evolution, and reward them for meeting goals with COMMARKET Campaigns."
            },
            Block3: {
                "Title": "You can easily manage incentives and rewards programs in real time. And best of all, those who join your programs track everything from a single, personal, and protected account."
            },
            Block4: {
                "Title": "COMMARKET Campaigns is the most user-friendly and secure platform in the market. And best of all: the platform looks like your company!"
            },
            Block5: {
                "Title": "Forget Excel worksheets. Stop using accounts that are not yours, are not managed by you. Have it all in a single environment!"
            },
            Featured: {
                "TitleStart": "Get you closer to your",
                "TitleEnd": "and audience!",
                "Tag": "goals",
                "ButtonText": "Talk to one of our consultants"
            }
        },
        Point5: {
            "Headline": "COMMARKET Marketplace",
            "Title": "COMMARKET has already built the marketplace you need.",
            "BottomHeadline": "If you need a tool for online sales, we offer the structure and the operation/support service.",
            "BottomText1": "However, if you are looking for a marketplace and partners for your loyalty program or incentive/reward campaigns, we are integrated with several, including the biggest players in the market. Coupons, vouchers, cards, gift cards, goods and services are available in plug&play mode.",
            "BottomText2": "Finally, don't forget: we can create your own catalog and make it available on our platform. Expand your network! Increase your sales!",
            Block1: {
                "Title": "Store"
            },
            Block2: {
                "Title": "Accounts"
            },
            Block3: {
                "Title": "Logistics"
            },
            Block4: {
                "Title": "Admin"
            },
            Block5: {
                "Title": "Communication"
            },
            Block6: {
                "Title": "Payment"
            },
            Featured: {
                "Title": "It's all set and integrated!",
            },
            "ButtonText": "Talk to one of our consultants",
        },
    },
    About: {
        "Headline": "We are grateful to serve so many important brands and so many brilliant executives and entrepreneurs.",
        Point1: {
            "Headline": "Our History",
            "HistoryText": "The history of COMMARKET is intertwined with the history of Loyalty and Relationship programs in Brazil, when it comes to knowledge and embedded technology.",
        },
        Point2: {
            "Title": "Mission",
            "Text": "Our Mission is to create value for organizations by offering knowledge and systemic solutions with a focus on strategic management of organizational relationships (B2B, B2C and B2E) and results, aiming business growth and increasing profitability for all customers and users of our platforms."
        },
        Point3: {
            "Title": "Eyesight",
            "Text": "Our Vision is to have over 200 million active users on our platforms by 2025."
        },
        Point4: {
            "Title": "Values",
            "Text": "Our Values are:",
            Block1: {
                "Title": "Passion",
            },
            Block2: {
                "Title": "Excellence",
            },
            Block3: {
                "Title": "Simplicity",
            },
            Block4: {
                "Title": "Ethic",
            },
        },
        Point5: {
            Block1: {
                "Title": "20+ years of experience in Strategic Management of Relationships and Results",
            },
            Block2: {
                "Title": "Integrate consulting and technology solutions for all business profiles",
            },
            Block3: {
                "Title": "Team of experts in value creation: Strategic Planning, Goals and Performance Management, Loyalty Programs, and Incentive Campaigns.",
            },
            Block4: {
                "Title": "Innovative company with production capacity, and systems customization",
            },
            Block5: {
                "Title": "Strategic alliances with leading companies",
            },
        },
        Point6: {
            "Title": "We work tirelessly to maintain our pioneering spirit.",
            "Text": "We are proud of our history and how much we help our customers and users to achieve their goals."
        },
        Point7: {
            "Title": "Join us",
            "Text": "We constantly seek to surround ourselves and work in partnership with people and organizations that value and aspire to the same as we do:",
            "ButtonText": "Job opportunities",
            Block1: {
                "Tag": "Knowledge",
                "Title": "Empower and qualify yourself to deliver what you promise, are committed with.",
            },
            Block2: {
                "Tag": "Technical excellence",
                "Title": "Perform tasks with quality and responsibility in the shortest possible time.",
            },
            Block3: {
                "Tag": "Respect",
                "Title": "Respect the laws and investments of those who trust us.",
            },
            Block4: {
                "Tag": "Innovation",
                "Title": "Develop incessantly methods that positively surprise those who affect or are affected by us.",
            },
            Block5: {
                "Tag": "Credibility",
                "Title": "Aim to maintain credibility, the most valuable asset of an individual or legal entity.",
            },
            Block6: {
                "Tag": "Desire for Transformation",
                "Title": "Prepare to overcome crises and meet the expectations of those who affect us or are affected by our actions.",
            },
        },
    },
    Contact: {
        "Headline": "Send us a message",
        "Nome": "Name",
        "EMail": "Email",
        "Telefone": "Phone",
        "Compania": "Company",
        "Mensagem": "Message",
        "ButtonText": "Send",
        Messages: {
            Name: "Type your name",
            EMail: "Type your e-mail",
            Company: "Enter your company",
            Phone: "Enter your phone number",
            Message: "Type a message",
        }
    },
    EndToEnd: {
        Hero: {
            "Headline": "The only ecosystem for end-to-end outcome management",
            "Text": "Grow your business with our pack of 4 products",
            Featured: {
                "Title": "Start your COMMARKET journey with a single module or the complete ecosystem.",
            },
            Block_1: {
                "Title": "COMMARKET ",
                "Text": "Points engine",
            },
            Block_2: {
                "Title": "COMMARKET ",
                "Text": "OKR management",
            },
            Block_3: {
                "Title": "COMMARKET ",
                "Text": "Campaigns",
            },
            Block_4: {
                "Title": "COMMARKET ",
                "Text": "Marketplace",
            },
        },
        Point1: {
            "Headline": "From Goals Management founded on OKR to Employee, Business Partners and Consumers Engagement",
            Block_1: {
                "Title": "Gain convergence.",
            },
            Block_2: {
                "Title": "Increase the level of trust and engagement.",
            },
            Block_3: {
                "Title": "Grow your business!",
            },
            "ButtonText": "Schedule a chat with us"
        }
    },
    OnDemand: {
        Point1: {
            "Headline": "Strategic Planning and Management",
            "Text_1": "We advise companies and entrepreneurs from the conception of the Mission and Vision of a project or business to the alignment of resources for strategic execution.",
            "Text_2": "Count on our team of consultants to leverage the results of your project or business with the COMMARKET OKR Management Software.",
            "Text_3": "The variety of experiences and sectors in which we already work, and the constant updating of our professionals, make us a valuable partner for the achievement of your goals.",
            "ButtonText": "Contact us"
        },
        Point2: {
            "Headline": "Loyalty Programs, Relationships Management and Campaigns",
            "Text_1": "For two decades, we have existed to help companies strengthen ties and recognize the achievements of employees, business partners and consumers.",
            "Text_2": "If your company has an outdated, ineffective, or even outdated loyalty, relationship, incentive or reward program, we help you create a strategy that promotes the participation of your target audience and generates real results.",
            "Text_3": "We know the best practices and budget scenarios, support materials and forms of communication necessary for you to achieve your goals.",
            "ButtonText": "Contact us"
        },
        Point3: {
            "Headline": "Marketing & BI",
            "Text_1": "We advise our clients in observing the behavior of consumers and other relevant stakeholders, with a view to improving strategies or developing new products, services or processes.",
            "Text_2": "Data Analytics and Social Media are at the heart of our work. We have the necessary knowledge to leverage sales, financial results and the satisfaction of those who relate to your business.",
            "ButtonText": "Contact us"
        },
        Point4: {
            "Headline": "Technology",
            "Text_1": "Do we have our own platforms, conceived, designed, developed and operated by us? Yes, we have! But what you need to know is that our history has been paved with bespoke, customized projects.",
            "Text_2": "We are a SOFTWARE FACTORY and we manage projects as a service, we support our customers (Help Desk), we integrate systems, operate and monitor our customers' programs and areas.",
            "Text_3": "COMMARKET Knowledge + COMMARKET Technology = Result",
            "ButtonText": "Contact us"
        },
    },
    Footer: {
        "ButtonText": "Join us",
    }
};