import { styled, Theme } from "@mui/material";

interface MainProps {
    bgColor: string | "transparent";
    borderColor: string | "transparent";
    borderHoverColor: string | "transparent";
    textColor: string;
    size: "small" | "medium"
    theme: Theme,
}

export const Main = styled('button')<MainProps>(({ bgColor, borderColor, textColor, borderHoverColor, size, theme }: MainProps) => `
    display: flex;
    align-items: center;
    position: relative;
    box-sizing: border-box;

    color: ${textColor};
    background: ${bgColor};
    background-clip: padding-box; /* !importanté */
    border: solid .125rem transparent; /* !importanté */
    border-radius: 6.25rem;
    text-align: center;
    cursor: pointer;

    &:before {
        content: '';
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        z-index: -1;
        margin: -0.125rem; /* !importanté */
        border-radius: inherit; /* !importanté */
        background: ${borderColor};
    }

    &:hover {
        &:before {
            background: ${borderHoverColor};
        }
    }

    &:disabled {
        opacity: 0.8;
    }

    & > span,
    & > a {
        display: block;
        text-align: center;
        font-family: ${theme.typography.fontFamily};
        width: 100%;
        font-size: 1rem;
        color: ${textColor};
        text-decoration: none;
        padding: ${size === "small" ? '16px 35px' : "0 0" || size === "medium" ? '24px 58px' : "0"};
        cursor: pointer;
        
        ${[theme.breakpoints.down("sm")]} {
            padding: ${size === "small" ? '13px 35px' : "0 0" || size === "medium" ? '12px 30px' : "0"};
            font-size: ${size === "small" ? ".75rem" : "1rem" || size === "medium" ? ".75rem" : "1rem"};
        }

    }

`);