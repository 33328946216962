import React from 'react';
import { ReactSVG } from 'react-svg';
import { useForm, SubmitHandler } from "react-hook-form";
import { CircularProgress, Container, FormControl, Grid, OutlinedInput, TextField, Typography, useTheme } from '@mui/material';
import $ from "jquery";
import "jquery-mask-plugin";
import axios from "axios";
import { ToastContainer  } from 'react-toastify';
import { notify } from '@utils';

import {
    Main,
    WrapperButton,
    ContactItem,
    Icon,
    Section,
    Form,
    HintFormStatus,
} from './styles';

import { Location, Mail, Phone } from '@icons';
import { Button } from '@components/design-system';

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import { i18n } from '@hooks';
const { t } = i18n;

export const formSchema = Yup.object().shape({
    name: Yup.string()
        .required(t('Contact.Messages.Name') as string),
    email: Yup.string()
        .required(t('Contact.Messages.EMail') as string),
    company: Yup.string()
        .required(t('Contact.Messages.Company') as string),
    phone: Yup.string()
        .required(t('Contact.Messages.Phone') as string),
    message: Yup.string()
        .required(t('Contact.Messages.Message') as string),

});

export interface FormInputs {
    name: string;
    email: string;
    company: string;
    phone: string;
    message: string;
}

const Contact: React.FC = () => {
    const theme = useTheme();

    const { register, handleSubmit, reset, formState: { errors } } = useForm<FormInputs>({
        resolver: yupResolver(formSchema),
        mode: "onBlur",
    });

    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const onSubmit: SubmitHandler<FormInputs> = async (data) => {

        const { name, phone, email, company, message } = data;

        const messageData = {
            tokenContactUs: "FACO-L-5ddc9c5c-6c1e-4adc-9b92-dd94975e03cd",
            name: name,
            company: company,
            email: email,
            ddi: "",
            ddd: "",
            phone: phone,
            subject: `Fale Conosco - Site Commarket`,
            text: message
        };

        setIsLoading(prev => prev = true);

        if (data) {
            axios.post('https://d1-socio.commarket.com.br/tool/api/contactUs/send', messageData,
                { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' } })
                .then((result) => {

                    if (result.data.code === 0) {
                        notify({
                            type: "success",
                            message: "Sua mensagem foi enviada! aguarde, em breve entraremos em contato.",
                            onClose: () => reset({
                                name: "",
                                email: "",
                                company: "",
                                phone: "",
                                message: ""
                            })
                        });
                        setIsLoading(prev => prev = false);
                    } else {
                        notify({
                            type: "error",
                            message: "Ocorreu um erro ao enviar seu contato.",
                        });
                        setIsLoading(prev => prev = false);
                    }
                })
                .catch(e => {
                    notify({
                        type: "error",
                        message: "Ocorreu um erro ao enviar seu contato.",
                    });
                    setIsLoading(prev => prev = false);
                });
        }

    }

    React.useEffect(() => {
        $(".phone_with_ddd").mask("(00) 00000-0000");
    }, []);


    return (
        <Main>
            <Container maxWidth='lg'>
                <Section className='point1'>
                    <Typography
                        variant="body1"
                        component="div"
                        align='center'
                        gutterBottom
                        sx={{
                            fontSize: '40px',
                            fontWeight: theme.typography.fontWeightMedium,
                            color: theme.palette.common.white,
                            marginBottom: theme.typography.pxToRem(40),
                            [theme.breakpoints.down("md")]: {
                                fontSize: '32px',
                            },
                            [theme.breakpoints.down("sm")]: {
                                marginBottom: theme.typography.pxToRem(20),
                                fontSize: '18px',
                            },
                        }}
                    >
                        {t('Contact.Headline') as string}
                    </Typography>

                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth variant="outlined">
                                    <OutlinedInput
                                        id="outlined-nome"
                                        placeholder={t('Contact.Nome') as string}
                                        aria-describedby="outlined-nome"
                                        fullWidth
                                        {...register("name", { required: true })}
                                        inputProps={{
                                            'aria-label': 'Nome',
                                        }}

                                    />
                                </FormControl>
                                <HintFormStatus>
                                    {errors.name && errors.name.message}
                                </HintFormStatus>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth variant="outlined">
                                    <OutlinedInput
                                        id="outlined-email"
                                        placeholder={t('Contact.EMail') as string}
                                        aria-describedby="outlined-email"
                                        fullWidth
                                        {...register("email", { required: true })}
                                        inputProps={{
                                            'aria-label': 'Email',
                                        }}
                                    />
                                </FormControl>
                                <HintFormStatus>
                                    {errors.email && errors.email.message}
                                </HintFormStatus>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth variant="outlined">
                                    <OutlinedInput
                                        id="outlined-companhia"
                                        placeholder={t('Contact.Compania') as string}
                                        aria-describedby="outlined-companhia"
                                        fullWidth
                                        {...register("company", { required: true })}
                                        inputProps={{
                                            'aria-label': 'Companhia',
                                        }}
                                    />
                                </FormControl>
                                <HintFormStatus>
                                    {errors.company && errors.company.message}
                                </HintFormStatus>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth variant="outlined">
                                    <OutlinedInput
                                        id="outlined-telefone"
                                        placeholder={t('Contact.Telefone') as string}
                                        aria-describedby="outlined-telefone"
                                        fullWidth
                                        {...register("phone", { required: true })}
                                        inputProps={{
                                            className: 'phone_with_ddd',
                                            'aria-label': 'Telefone',
                                        }}
                                    />
                                </FormControl>
                                <HintFormStatus>
                                    {errors.phone && errors.phone.message}
                                </HintFormStatus>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <FormControl fullWidth variant="outlined">
                                    <TextField
                                        id="outlined-mensagem"
                                        placeholder={t('Contact.Mensagem') as string}
                                        aria-describedby="outlined-mensagem"
                                        fullWidth
                                        multiline
                                        rows={8}
                                        {...register("message", { required: true })}
                                        inputProps={{
                                            'aria-label': 'Mensagem',
                                        }}
                                    />
                                </FormControl>
                                <HintFormStatus>
                                    {errors.message && errors.message.message}
                                </HintFormStatus>
                            </Grid>
                        </Grid>
                        <WrapperButton>
                            <Button
                                backgroundColor='#A74DFF'
                                color='white'
                                buttonBorderColor='#A74DFF'
                                buttonBorderHoverColor='#A74DFF'
                                buttonSize='small'
                                type='submit'
                                disabled={isLoading}
                            >
                                {isLoading ? (<CircularProgress size={20} sx={{ color: 'white' }} />) : t('Contact.ButtonText') as string}

                            </Button>
                        </WrapperButton>
                    </Form>

                </Section>

                <Section className='point2'>

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>

                            <ContactItem>

                                <Icon>
                                    <ReactSVG src={Location} wrapper="span" className='svg local' />
                                </Icon>
                                <Typography
                                    variant="body1"
                                    component="div"
                                    align='left'

                                    sx={{
                                        fontSize: '18px',
                                        fontWeight: theme.typography.fontWeightRegular,
                                        color: theme.palette.common.white,
                                        paddingRight: theme.typography.pxToRem(30),
                                        [theme.breakpoints.down("md")]: {
                                            fontSize: '16px',
                                        },
                                        [theme.breakpoints.down("sm")]: {
                                            fontSize: '12px',
                                        },
                                    }}
                                >
                                    Rua Marechal Barbacena, 1064 CEP 03333-000 São Paulo/SP
                                </Typography>
                            </ContactItem>
                        </Grid>
                        <Grid item xs={12} md={4}>

                            <ContactItem>
                                <Icon>
                                    <ReactSVG src={Phone} wrapper="span" className='svg phone' />
                                </Icon>
                                <Typography
                                    variant="body1"
                                    component="div"
                                    align='left'

                                    sx={{
                                        fontSize: '18px',
                                        fontWeight: theme.typography.fontWeightRegular,
                                        color: theme.palette.common.white,
                                        [theme.breakpoints.down("md")]: {
                                            fontSize: '16px',
                                        },
                                        [theme.breakpoints.down("sm")]: {
                                            fontSize: '12px',
                                        },
                                    }}
                                >
                                    +55 (11) 2671-0400
                                </Typography>
                            </ContactItem>
                        </Grid>
                        <Grid item xs={12} md={4}>

                            <ContactItem>
                                <Icon>
                                    <ReactSVG src={Mail} wrapper="span" className='svg mail' />
                                </Icon>
                                <Typography
                                    variant="body1"
                                    component="div"
                                    align='left'

                                    sx={{
                                        fontSize: '18px',
                                        fontWeight: theme.typography.fontWeightRegular,
                                        color: theme.palette.common.white,
                                        [theme.breakpoints.down("md")]: {
                                            fontSize: '16px',
                                        },
                                        [theme.breakpoints.down("sm")]: {
                                            fontSize: '12px',
                                        },
                                    }}
                                >
                                    commarket@commarket.com.br
                                </Typography>
                            </ContactItem>
                        </Grid>
                    </Grid>
                </Section>

            </Container>
            <ToastContainer />
        </Main>
    )
}

export { Contact };